import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";
import { desktopFontsSize, tabletFontsSize } from "../../../constants/Fonts";
import { colourMapper } from "../../globals/Utils";
import arrowIcon from "../../../assets/icons/arrow-down.svg";

export const useStyles = createUseStyles({
  container: (props: { theme: string; compColour: string; size: string }) => ({
    fontSize: ["lg", "xl", "xxl"].includes(props.theme)
      ? desktopFontsSize.xsm
      : tabletFontsSize.sm,
    width: props.size === "Large" ? "100%" : "50%",
    background: colour.white1,
    borderRadius: 5,
    borderLeft: `5px solid ${colourMapper(props.compColour)}`,
    boxSizing: "border-box",
    marginTop: 4,
    position: "relative",
  }),
  expandedContainer: {
    boxShadow: "0 0 0px 1.5px" + colour.blue7,
  },
  content: {
    color: colour.black,
    fontSize: 12,
    padding: "9px 7px 9px",
    boxSizing: "border-box",
    marginLeft: 5,
    textTransform: "uppercase",
    cursor: "pointer",
    position: "relative",
    fontFamily: "'AvenirLTStd-Medium', sans-serif",
    height: 38,
    lineHeight: "20px",
  },
  activeTitle: {
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
  },
  inactiveTitle: {
    fontFamily: "'AvenirLTStd-Book', sans-serif",
    verticalAlign: "middle",
  },
  arrow: {
    width: 24,
    height: 24,
    display: "inline-block",
    position: "absolute",
    right: 12,
    top: 7,
    background: `url(${arrowIcon}) no-repeat center center`,
    transition: "ease-in .2s",
    "&.active": {
      transform: "rotate(90deg)",
    },
  },
  show: {
    padding: "0 14px 12px",
    fontFamily: "'AvenirLTStd-Book', sans-serif",
    fontSize: 13,
    lineHeight: "18px",
    "& h4": {
      fontFamily: "'AvenirLTStd-heavy', sans-serif",
      margin: "10px 0",
      textTransform: "uppercase",
    },
  },
  hide: {
    display: "none",
  },
  detailedView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    padding: "0px 16px 15px 10px",
    "& > div": {
      flexBasis: 1,
      flexGrow: 1,
      marginLeft: 40,
    },
    "& > div:first-child": {
      display: "flex",
      flexGrow: 0.3,
      flexDirection: "column",
      margin: "12px 0",
      "& > p": {
        fontFamily: "'AvenirLTStd-Heavy', sans-serif",
        textTransform: "uppercase",
        fontSize: 12,
        margin: "3px 0",
      },
    },
  },
  grayDetailedView: {
    display: "block",
    padding: "4px 10px 2px",
    "& p": {
      fontFamily: "'AvenirLTStd-Heavy', sans-serif",
      textTransform: "uppercase",
      fontSize: 12,
      margin: "3px 0",
    },
  },
  summaryView: {
    "& > div:first-child": {
      display: "flex",
      flexGrow: 0.3,
      flexDirection: "row",
      "& > p": {
        textTransform: "uppercase",
        fontSize: 12,
        margin: "3px 0",
        paddingRight: 5,
      },
    },
  },
  glowBorder: {
    border: "2px solid" + colour.blue,
  },
});
