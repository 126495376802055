import React from "react";
import { ReactComponent as NounProfile } from "../../../assets/icons/noun-profile.svg";
import { useStyles } from "./styles";

type AvatarProps = {
  isIcon: boolean;
  name?: string;
  size?: string;
  isDark?: boolean;
};

const Avatar = ({
  isIcon, name = "",
  size = "Medium",
  isDark = false
}: AvatarProps) => {
  const classes = useStyles({ isDark: isDark });

  var nameSplit = String(name).toUpperCase().split(" ");
  var initials;

  if (nameSplit.length === 1) {
    initials = nameSplit[0] ? nameSplit[0].charAt(0) : "?";
  } else {
    initials = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
  }

  const circleStyle = () => {
    switch (true) {
      case size === "Small":
        return classes.smCircle;
      case size === "Medium":
        return classes.mdCircle;
      case size === "Large":
        return classes.lgCircle;
    }
  };

  return (
    <div data-testid="avatar" className={`${classes.circle} ${circleStyle()}`}>
      {isIcon ?
      <div data-testid="avatar-icon" className={classes.userIcon}>
        <NounProfile data-ref="userIcon" />
      </div>
      :
      <p data-testid="avatar-text" className={classes.text}>{initials}</p> }
    </div>
  );
};

export default Avatar;
