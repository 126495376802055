import { useStyles } from "./styles";
interface Props {
  name: string;
  relevantGenes: [];
  quadrantIntro: string;
}

const ClinicalConsiderationsIntro = (props: Props) => {
  const { name, relevantGenes, quadrantIntro } = props;
  const classes = useStyles();
  return (
    <div>
      <div className={classes.title}>
        <span>{name}</span> - Important Genes (
        {relevantGenes && relevantGenes.join(", ")})
      </div>
      <p className={classes.description}>{quadrantIntro}</p>
    </div>
  );
};

export default ClinicalConsiderationsIntro;
