import { PsychotropicReportDisclaimer } from "./PsychotropicReportDisclaimer";
import { FullMedsReportDisclaimer } from "./FullMedsReportDisclaimer";
import { isPsychotropicReport } from "../../utils/reportTypeUtils";
import { useSelector } from "react-redux";
import { reduxState } from "../../components/globals/interfaces/commonInterfaces";

const Disclaimer = () => {
  const reportType = useSelector(
    (state: reduxState) => state.appData.pgxReportData.reportType
  );
  const pathologyProviderName = useSelector(
    (state: reduxState) =>
      state.appData.pgxReportData.reportInformation?.pathologyProvider?.name
  );

  return isPsychotropicReport(reportType) ? (
    <PsychotropicReportDisclaimer
      pathologyProviderName={pathologyProviderName}
    />
  ) : (
    <FullMedsReportDisclaimer pathologyProviderName={pathologyProviderName} />
  );
};

export default Disclaimer;
