import { useRef, useState } from "react";
import { useStyles } from "./styles";
import {config} from "../../constants/Constants";
import {convertDateFormatWithMonthShortName, useOutsideActionPerformer} from "../globals/Utils";
import Patient from "../globals/interfaces/patientInterface";


interface Props {
  patient: Patient;
  reportInformation: {labRef: string;}
}

const HelpButton = (props: Props) => {
  const { patient, reportInformation } = props;
  const classes = useStyles();
  const [showTooltip, setShowTooltip] = useState(false);

  const helpTooltipRef = useRef(null);
  useOutsideActionPerformer(helpTooltipRef, () => setShowTooltip(false), showTooltip);

  const emailBodyContent = () => `${patient.fullName} %0D%0ADate of Birth: ${convertDateFormatWithMonthShortName(patient.dob)}`
  return (
    <div className={classes.helpButtonWpr} data-testid="helpbuttonwpr">
      <button
        className={classes.helpButton}
        onClick={() => setShowTooltip(!showTooltip)}
      >
        Help
      </button>
      {showTooltip && (
        <div ref={helpTooltipRef} className={classes.helpTooltip}>
          <span className={classes.triangle}></span>
          <h2 className={classes.title}>Speak to our specialists</h2>
          <p className={classes.description}>
            If you have clinical questions about this report, please contact our
            team at{" "}
            <a
              href={`mailto:${config.helpEmailId}?subject=${
                reportInformation.labRef
              } - &body=${emailBodyContent()}`}
              className={classes.email}
            >
              {config.helpEmailId}
            </a>
          </p>
        </div>
      )}
    </div>
  );
};

export default HelpButton;
