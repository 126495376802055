import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenuItem } from "../../actions";
import { reduxState } from "../../components/globals/interfaces/commonInterfaces";
import ReferencesList from "../../components/_atoms/ReferencesList";
import Separator from "../../components/_atoms/Separator";
import Title from "../../components/_atoms/Title";
import { useStyles } from "./styles";

const ReferencesPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setActiveMenuItem({
        index: 2,
        identifier: "details",
      })
    );
  }, [dispatch]);

  const references = useSelector(
    (state: reduxState) => state.appData.pgxReportData.references
  );

  return (
    <>
      <Separator />
      <div className={classes.referencesContainer}>
        <div className={classes.referencesContent}>
          <Title>References</Title>
          <ReferencesList references={references} />
        </div>
      </div>
    </>
  );
};

export default ReferencesPage;
