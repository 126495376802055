export const HelpText =  {
  major: "Major change to metabolism significantly affecting drug response is predicted. Consider dosage or alternative medication as per recommendations in the table below.",
  minor: "Minor or uncertain change to metabolism possibly affecting drug response is predicted. Consider potential clinical issues below.",
  usual: "No altered effect on metabolism due to genotype is predicted. Consider usual dosing and monitoring.",
  noPrescribing: "These medications do not have significant gene-drug interactions identified and standard prescribing considerations apply."
};

export const config = {
  ShortenTextCharLength : 40,
  helpEmailId: "clinical@mydna.life",
  showHistoryLog: false,
  Default_Auth_Landing_Screen: "/MedicationCategories",
  Default_Landing_Page_AmendedReport_Screen: "/Notifications",
  Default_UnAuth_Landing_Screen: "/login",
  Default_SignOut_Landing_Screen: "/end",
  GxG_Auth_Landing_Screen: "/MedicationCategories",
  enableMenuAnimation: false
}

export const messages = {
  pleaseCheckYourEmail: "Please check your email"
}

export const localStorageKey = {
    reportId: "reportId"
}

export const reportType = {
  fullMedication: "Full Medications - USA - HTML",
  pgxMentalHealth: "Medication - Mental Health - USA"
}

export const PathologyProviderConstants = {
  GeneByGene: "Gene By Gene",
  Kaiser: "Kaiser",
  Igentify: "Igentify"
}