import { useStyles } from "./styles";

const Spinner = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.loader}>
        <div className="dot dot1">
          <i></i>
        </div>
        <div className="dot dot2">
          <i></i>
        </div>
        <div className="dot dot3">
          <i></i>
        </div>
        <div className="dot dot4">
          <i></i>
        </div>
        <div className="dot dot5">
          <i></i>
        </div>
        <div className="dot dot6">
          <i></i>
        </div>
        <div className="dot dot7">
          <i></i>
        </div>
        <div className="dot dot8">
          <i></i>
        </div>
        <div className="dot dot9">
          <i></i>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
