import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenuItem } from "../../actions";
import { reduxState } from "../../components/globals/interfaces/commonInterfaces";
import Notification from "../../components/Notification";
import ReferencesList from "../../components/_atoms/ReferencesList";
import Separator from "../../components/_atoms/Separator";
import Title from "../../components/_atoms/Title";
import { useStyles } from "./styles";

const NotificationPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setActiveMenuItem({
        index: 0,
        identifier: "whatsNew",
      })
    );
  }, [dispatch]);

  const amendedComment = useSelector(
    (state: reduxState) => state.appData.pgxReportData.reportInformation.amendedComment
  );


  const getNotifications = () => {
    // Add new notifications in the future to the commentsList
    var commentsList = [];
    var amendedCommentObj = { title: "Amended Report", text: amendedComment }
    if(amendedComment) {
      commentsList.push(amendedCommentObj)
    }
    if (commentsList.length > 0) {
      return commentsList.map(c => {
        return <Notification title={c.title} text={c.text} />
      })
    }
    else {
      return <Notification isEmpty text="none" />
    }
  }

  return (
    <>
      <Separator />
      <div className={classes.notificationsContainer}>
        <div className={classes.notificationsContent}>
          <Title><div className={classes.header}>Notifications</div></Title>
          {getNotifications()}
          <hr />
        </div>
      </div>
    </>
  );
};

export default NotificationPage;
