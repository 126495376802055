import { useStyles } from "./styles";

interface PopUpMenuProps {
  buttonText: string;
  onClick: () => void;
  children: JSX.Element;
  styles?: Object;
}

const PopUpMenu = ({ buttonText, onClick, children, styles }: PopUpMenuProps) => {
  const classes = useStyles();
  return (
    <div data-testid="popup-menu" className={classes.popUpMenu} style={styles}>
        <div className={classes.popUpMenuContent}>
          <p className={classes.popUpTitle}>
            {children}
          </p>
        </div>
        <div className={classes.button} onClick={onClick}>
            <p className={classes.buttonText}>{buttonText}</p>
        </div>
    </div>
  );
}

export default PopUpMenu;
