import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
  welcome: {
    fontSize: 16
  },
  description: {
    width: 300
  },
  error: {
    composes: '$button',
    color: colour.red
  }
});
