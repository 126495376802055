import { useStyles } from "./styles";
import { ReactComponent as ListIcon } from "../../../assets/icons/list-icon.svg";
import { ReactComponent as GridIcon } from "../../../assets/icons/grid-icon.svg";
import { ReactComponent as ArrowSmallIcon } from "../../../assets/icons/arrow-small-icon.svg";
import React, { useEffect, useRef, useState } from "react";
import { reduxState } from "../../globals/interfaces/commonInterfaces";
import { useSelector } from "react-redux";
import { useOutsideActionPerformer } from "../../globals/Utils";

type FilterProps = {
  onSummaryClick: () => void;
  onDetailedClick: () => void;
};

const Filter = ({onSummaryClick, onDetailedClick}:FilterProps) => {
  const classes = useStyles();
  const isFilterInDetailedView = useSelector(
    (state: reduxState) => state.appData.isFilterInDetailedView
  );
  const [showDetails, setShowDetails] = useState(isFilterInDetailedView);
  const [showMenu, setShowMenu] = useState(false);
  const handleClick = ()=>{
    setShowMenu(!showMenu)
  };

  const onSelectSummary = ()=>{
    setShowDetails(false);
    setShowMenu(false);
    onSummaryClick();
  }
  const onSelectDetails = ()=>{
    setShowDetails(true);
    setShowMenu(false);
    onDetailedClick();
  }
  
  useEffect(() => {
    setShowDetails(isFilterInDetailedView);
  }, [isFilterInDetailedView]);

  const filterBoxRef = useRef(null);
  useOutsideActionPerformer(filterBoxRef, ()=>setShowMenu(false), showMenu);
  
  return (
    <div ref={filterBoxRef}>
      <div
        className={`${classes.filterBox} ${
          showMenu ? classes.filterBoxActive : classes.filterBoxHover
        }`}
        data-testid="filter"
        onClick={handleClick}
      >
        <p className={classes.filterBoxText}>
          View
          <span className={classes.filterBoxTextValue}>
            {`${showDetails ? "Detailed" : "Summary"}`}
          </span>
          <ArrowSmallIcon
            className={`${showMenu && classes.filterBoxArrowOpen}`}
          />
        </p>
      </div>
      {showMenu && (
        <div data-testid="filter-menu" className={classes.filterMenu}>
          <div
            data-testid="filter-menu-summary"
            className={`${classes.filterMenuItem} ${
              showDetails ? "" : classes.filterMenuItemActive
            }`}
            onClick={() => onSelectSummary()}
          >
            <p className={classes.filterTitle}>
              <GridIcon />
              <span className={classes.filterTitleText}>Summary view</span>
            </p>
          </div>
          <div
            data-testid="filter-menu-detailed"
            className={`${classes.filterMenuItem} ${
              showDetails && classes.filterMenuItemActive
            }`}
            onClick={() => onSelectDetails()}
          >
            <p className={classes.filterTitle}>
              <ListIcon />
              <span className={classes.filterTitleText}>Detailed view</span>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
