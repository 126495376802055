import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
  medicationsDiv: {
    width: "50%",
    marginTop: 20,
  },
  noDataFoundDiv: {
    fontFamily: "'AvenirLTStd-Book', sans-serif",
    fontWeight: 700,
    height: 300,
    padding: 40,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colour.white1,
    fontSize: "16px",
    lineHeight: "32px",
    letterSpacing: "-0.25px",
  },
  additionalInformation: {
    fontFamily: "'AvenirLTStd-Book', sans-serif",
    fontSize: 14,
    border: "2px solid" + colour.blue,
    borderRadius: 12,
    padding: 14,
  },
  geneTagDiv: {
    margin: "2px 0",
  },
  tableDiv: {
    marginTop: 20,
  },
  medicationTitle: {
    fontFamily: "'AvenirLTStd-Book', sans-serif",
    fontSize: 12,
    textTransform: "uppercase",
  },
});