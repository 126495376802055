import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsFilterInDetailedView } from "../../../actions";
import { reduxState } from "../../../components/globals/interfaces/commonInterfaces";
import { Genotype } from "../../../components/globals/interfaces/PractitionerData";
import GeneTag from "../../../components/_atoms/GeneTag";
import TextBlock from "../../../components/_atoms/TextBlock";
import Filter from "../../../components/_molecules/Filter";
import {TableHeader, Heading} from "../../../components/_molecules/TableHeader";
import { TableRow, Cell } from '../../../components/_molecules/TableRow';
import { colour } from '../../../constants/Colours';
import { useStyles } from '../styles';

interface AdditionalGenesPanelProps {
    emergingGenotypes: Genotype[];
    references: any;
}

const AdditionalGenesPanel = ({ emergingGenotypes, references } : AdditionalGenesPanelProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const selectedSearchItem = useSelector(
      (state: reduxState) => state.appData.selectedSearchItem
    );
    const isFilterInDetailedView = useSelector(
      (state: reduxState) => state.appData.isFilterInDetailedView
    );
    const [isDetailedView, setIsDetailedView] = useState(
      isFilterInDetailedView
    );

    const toggleDetailedView = (value: boolean) => {
      setIsFilterInDetailedView(value);
      dispatch(setIsFilterInDetailedView(value));
    };

    const isSelectedItem = (geneName: string) => {
      return geneName === selectedSearchItem.name;
    };

    useEffect(() => {
      setIsDetailedView(isFilterInDetailedView);
    }, [isFilterInDetailedView]);

    const tableHeadings: Heading[] = [
        {
            value: 'Gene',
            width: '25%'
        },
        {
            value: 'Genotype',
            width: '25%'
        },
        {
            value: 'Comments',
            width: '50%'
        }
    ]

    var tableRows: JSX.Element[] = emergingGenotypes.map((genotype, index) => {
        
        var cells: Cell[] = [
          {
            width: "25%",
            content: (
              <>
                <GeneTag
                  isAdditionalGene
                  title={genotype.geneName}
                />
              </>
            ),
          },
          {
            width: "25%",
            content: <>{genotype.result}</>,
          },
          {
            width: "50%",
            content: (
              <>
                <TextBlock
                  description={genotype.interpretation}
                  backgroundColor={colour.white1}
                  references={references}
                  isExpanded={isDetailedView || isSelectedItem(genotype.geneName)}
                />
              </>
            ),
          },
        ];

        return (
          <TableRow
            key={`test-result-emerging-genotype-${index}`}
            isHighLighted={isSelectedItem(genotype.geneName)}
            cells={cells}
          />
        );
    });

    if (tableRows.length == 0) {
        var noDataCell: Cell[] = [
            {
                width: "100%",
                content: (<span>No data available</span>)
            }
        ]
        tableRows.push(<TableRow key={`test-result-emerging-genotype-no-data`} cells={noDataCell} />)
    }

    return (
      <>
        <div className={classes.filterDiv}>
          <Filter
            onSummaryClick={() => toggleDetailedView(false)}
            onDetailedClick={() => toggleDetailedView(true)}
          />
        </div>
        <div className={classes.componentWrapper}>
          <TableHeader headings={tableHeadings}>
            <>{tableRows}</>
          </TableHeader>
        </div>
      </>
    );
}

export default AdditionalGenesPanel;
