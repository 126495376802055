import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";
import { desktopFontsSize, tabletFontsSize } from "../../../constants/Fonts";

export const useStyles = createUseStyles({
  navigationContainer: {
    height: "auto",
    display: "flex",
    position: "relative",
    margin: "0.5rem 0"
  },
  backgroundOverlay: {
    position: "absolute",
    height: 50,
    width: 100,
    zIndex: "-1",
    background: "blue",
    transition: ".3 ease left, width, opacity",
    opacity: 0,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    boxSizing: "border-box",
    width: "100%",
    position: "relative",
    marginTop: 0,
    listStyle: "none",
    "& li": {
      marginTop: 4,
    },
  },
  listItem: {
    fontSize: (screenClass:any) =>
      ["lg", "xl", "xxl"].includes(screenClass)
        ? desktopFontsSize.smd
        : tabletFontsSize.md,
    listStyle: "none",
    cursor: "pointer",
    zIndex: 2,
    position: "relative",
    color: colour.white,
    textDecoration: "none",
    padding: "8px 10px 10px 10px",
    display: "block",
    fontFamily: "'AvenirLTStd-Roman', sans-serif",
  },
  listItemActive: {
    fontSize: (screenClass) =>
      ["lg", "xl", "xxl"].includes(screenClass)
        ? desktopFontsSize.sm
        : tabletFontsSize.md,
    listStyle: "none",
    cursor: "pointer",
    zIndex: 2,
    position: "relative",
    color: colour.white,
    textDecoration: "none",
    padding: "8px 10px 10px 10px",
    display: "block",
    fontWeight: "normal",
    fontFamily: "'AvenirLTStd-Black', sans-serif",
    background: colour.blue2,
    borderRadius: 5,
  },
  overlay: {
    position: "absolute",
    zIndex: 0,
    background: colour.blue2,
    transition: ".4s ease top",
    width: "100%",
    borderRadius: 5,
    padding: "10px 0px",
    height: "34px !important"
  },
});
