import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";

export const useStyles = createUseStyles({
  popUpMenu: {
    position: "absolute",
    top: 41,
    zIndex: 200,
    width: 245,
    minHeight: 121,
    background: colour.white,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    userSelect: "none",
  },
  popUpMenuContent: {
    width: "100%",
    margin: 2,
    padding: "0 16px",
  },
  popUpTitle: {
    margin: "8px 0",
  },
  popUpTitleText: {
    fontFamily: "'AvenirLTStd-Roman', sans-serif",
    fontSize: 14,
    paddingLeft: 4,
  },
  button: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 32px",
    width: "200px",
    height: "33px",
    background: "#30B5E8",
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover": {
      background: "#2693BC",
    },
    "&:active": {
      background: "#195E78",
    },
  },
  buttonText: {
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    color: colour.white,
    lineHeight: 0,
    userSelect: "none",
  },
});
