import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";

export const useStyles = createUseStyles({
  texBlock: {
    margin: 0,
    padding: 0,
    fontFamily: "'AvenirLTStd-Book', sans-serif",
    fontSize: 14,
  },
  briefText: {
    position: "relative",
  },
  boldText: {
    fontFamily: "AvenirLTStd-Heavy, sans-serif",
  },
  fadedGradient: (props: { backgroundColor: string }) => ({
    position: "absolute",
    background: `linear-gradient(270deg, ${props.backgroundColor} 50%, rgba(255,255,255,0) 100%)`,
    width: 100,
    height: 20,
    marginLeft: -60,
    textAlign: "right",
  }),
  toggleText: {
    fontFamily: "AvenirLTStd-Roman, sans-serif",
    fontSize: 14,
    color: colour.blue,
    cursor: "pointer",
    userSelect: "none",
  },
  description: {
    marginRight: 5,
  },
});
