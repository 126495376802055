import { useStyles } from "./styles";
import { useScreenClass } from "react-grid-system";
import HelpTooltip from "../Tooltip/HelpTooltip";
import { getHelpText } from "../../globals/Utils";
import { ReactComponent as WarningIcon } from "../../../assets/icons/warning.svg";

interface Props {
  title: string;
  colour?: string;
  size?: string;
  showTooltip?: boolean;
  tooltipPosition?: string;
  isReportBanner?: boolean;
  hasWarningSign?: boolean;
}
const Banner = ({
  title,
  colour = "White",
  size = "Large",
  showTooltip = false,
  tooltipPosition = "Right",
  isReportBanner = false,
  hasWarningSign = false,
}: Props) => {
  const theme = useScreenClass();
  const styleProps = { theme: theme, colour: colour, size: size };
  const classes = useStyles(styleProps);
  return (
    <div
      className={!isReportBanner ? classes.container : classes.reportContainer}
      data-testid="banner"
    >
      <div
        className={!isReportBanner ? classes.content : classes.reportContent}
        data-testid="title"
      >
        {title}
      </div>
      {!isReportBanner && showTooltip && (
        <HelpTooltip
          tooltipText={getHelpText(title)}
          openToolTip={false}
          tooltipPosition={tooltipPosition}
        />
      )}
      {isReportBanner && !hasWarningSign && (
        <div className={classes.reportDotWrapper}>
          <div data-testid="coloured-dot" className={classes.reportDot} />
        </div>
      )}
      {isReportBanner && hasWarningSign && (
        <div data-testid="warning-sign" className={classes.warningIcon}>
          <WarningIcon data-ref="warningIcon" />
        </div>
      )}
    </div>
  );
};

export default Banner;
