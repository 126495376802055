import React, { useState } from "react";
import { useStyles } from "./styles";
import Tooltip from "./Tooltip"

interface Props {
  tooltipText: string;
  openToolTip: boolean;
  tooltipPosition: string;
}

const HelpTooltip = (props: Props) => {
  const { tooltipText, openToolTip, tooltipPosition } = props;
  const [showToolTip, setShowToolTip] = useState(openToolTip);

  const openTooltip = () => {
    setShowToolTip(showToolTip ? false : true);
  };

  const hideTooltip = () => {
    setShowToolTip(false);
  };

  const classes = useStyles();
  return (
    <div className={classes.tooltipWpr} data-testid="tooltipWpr">
      <i className={classes.icon} onClick={() => openTooltip()}></i>
      {showToolTip && (
        <Tooltip tooltipText={tooltipText} hideTooltip={hideTooltip} tooltipPosition={tooltipPosition} />
      )}
    </div>
  );
};

export default HelpTooltip;
