import { useStyles } from "./styles";
import { useScreenClass } from "react-grid-system";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AutoSuggest from "./AutoSuggest";
import { setActiveDrug, setSelectedSearchItem } from "../../../actions";

type SearchBarProps = {
  placeholder: string,
};

const SearchBar = ({ placeholder }: SearchBarProps) => {
  const dispatch = useDispatch();
  const searchableData = useSelector((state: any) => state.appData.searchableData);
  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (
      searchableData &&
      searchableData.data &&
      searchableData.data.length > 0
    ) {
      setSuggestions(searchableData.data);
    }
  }, [searchableData]);

  const screenClass: any = useScreenClass();
  const classes = useStyles(screenClass);
  const clearValue: any = (a: any, b: any, c:any) => {
    setInputValue("");
    dispatch(setSelectedSearchItem({}));
    dispatch(setActiveDrug(null));
  };

  return (
    <div className={classes.container}>
      <AutoSuggest
        suggestions={suggestions}
        placeholder={placeholder}
        value={inputValue}
        setInputValue={setInputValue}
      />
      {inputValue.length > 0 && <button className={classes.clearButton} onClick={clearValue}></button>}
    </div>
  );
};

export default SearchBar;
