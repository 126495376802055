import { colour } from "../../constants/Colours";
export const ReferenceTooltipStyles = {
  top: -1,
  color: colour.white,
  cursor: "pointer",
  padding: "2.5px 0 0",
  zIndex: 0,
  position: "relative",
  fontSize: 10,
  marginLeft: 0,
  textAlign: "center",
  display: "inline-block",
  background: colour.blue,
  borderRadius: "50%",
  width: 14,
  height: 13,
  lineHeight: "10px",
  "&:after": {
    left: 2,
    width: 0,
    bottom: -8,
    height: 0,
    content: "' '",
    zIndex: -1,
    position: "absolute",
    border: "5px solid transparent",
    borderTopColor: colour.blue,
  },
};
