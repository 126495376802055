import {applyMiddleware, createStore} from 'redux'
import rootReducer from './rootReducer'
import thunk from 'redux-thunk'
import logger from 'redux-logger'

const middleWares = [
    thunk,
];

if (process.env.NODE_ENV !== `production`) {
    middleWares.push(logger);
}

const store = createStore(rootReducer, applyMiddleware(...middleWares));
export default store;