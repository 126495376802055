import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";

export const useStyles = createUseStyles({
  reportTag: {
    width: "fit-content",
    minHeight: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: colour.grey1,
    color: colour.white,
    padding: "0px 8px",
    borderRadius: "2px",
  },
  title: {
    margin: 0,
    paddingTop: 2,
    fontFamily: "AvenirLTStd-Roman, sans-serif",
    textAlign: "center",
    fontSize: 12,
    textTransform: "uppercase",
    letterSpacing: "0.08em",
  },
});
