import { Interaction } from "../../../components/globals/interfaces/PractitionerData";
import GeneTag from "../../../components/_atoms/GeneTag";
import TextBlock from "../../../components/_atoms/TextBlock";
import {
  Heading, TableHeader,
} from "../../../components/_molecules/TableHeader";
import { TableRow, Cell } from "../../../components/_molecules/TableRow";
import { colour } from "../../../constants/Colours";
import { useStyles } from "../styles";

interface PotentialDrugInteractionsPanelProps {
  description: string;
  interactions: Interaction[];
}

const PotentialDrugInteractionsPanel = ({
  description,
  interactions,
}: PotentialDrugInteractionsPanelProps) => {
  const classes = useStyles();

  const noDataFoundText =
    `The effect of drug-drug interactions can be additive to the effect of genotype on drug metabolism. Inhibitors can decrease and inducers can increase metabolism, leading to changes in drug concentration and clinical effects.
    There are no medications of interest identified that significantly inhibit or induce the enzymes tested for by myDNA.`;

  const tableHeadings: Heading[] = [
    {
      value: "Medication",
      width: "25%",
    },
    {
      value: "Inhibitor - Moderate",
      width: "25%",
    },
    {
      value: "Inhibitor - Strong",
      width: "25%",
    },
    {
      value: "Inducer",
      width: "25%",
    },
  ];

  const tableRows: JSX.Element[] = interactions.map((interaction, index) => {
    let cells: Cell[] = [
      {
        width: "25%",
        content: <span className={classes.medicationTitle}>{interaction.medication}</span>,
      },
      {
        width: "25%",
        content: (
          <>
            {interaction.inhibitorModerate.map((inhibitorGene, index) => (
              <div className={classes.geneTagDiv} key={`${inhibitorGene}-${index}`}>
                <GeneTag title={inhibitorGene} />
              </div>
            ))}
          </>
        ),
      },
      {
        width: "25%",
        content: (
          <>
            {interaction.inhibitorStrong.map((inhibitorGene, index) => (
              <div className={classes.geneTagDiv} key={`${inhibitorGene}-${index}`}>
                <GeneTag title={inhibitorGene} />
              </div>
            ))}
          </>
        ),
      },
      {
        width: "25%",
        content: (
          <>
            {interaction.inducer.map((inducerGene, index) => (
              <div className={classes.geneTagDiv} key={`${inducerGene}-${index}`}>
                <GeneTag title={inducerGene} />
              </div>
            ))}
          </>
        ),
      },
    ];

    return (
      <TableRow
        key={`potential-drug-interaction-${index}`}
        isHighLighted={false}
        cells={cells}
      />
    );
  });

  return (
    <>
      {tableRows.length > 0 ? (
        <>
          <TextBlock description={description} backgroundColor={colour.white} />
          <div className={classes.tableDiv}>
            <TableHeader headings={tableHeadings}>
              <div>{tableRows}</div>
            </TableHeader>
          </div>
        </>
      ) : (
        <div className={classes.medicationsDiv}>
          <div className={classes.noDataFoundDiv}>
            {noDataFoundText}
          </div>
        </div>
      )}
    </>
  );
};

export default PotentialDrugInteractionsPanel;
