import { setReportData } from "../../actions/index";
import { FetchGetReport, removeInvalidCategories, fixMissingGrayQuadrant, formatAndDispatchSearchData, sortGrayQuadrantDescendingly } from "../../components/globals/Utils";
import { localStorageKey } from "../../constants/Constants";
import {config} from "../../constants/Constants";

function getReportData(Id:string, dispatch:Function, setErrorType:Function, setShowError:Function, setLoading:Function, routerUrl:string, navigate:any){
  return FetchGetReport(Id)
    .then((response: any) => {
      const data = response.data.getReport.reportPgxData;
      removeInvalidCategories(data);
      fixMissingGrayQuadrant(data);
      sortGrayQuadrantDescendingly(data);
      if (validateReportData(data, setErrorType, setLoading, setShowError)) {
        dispatch(setReportData(data));
        formatAndDispatchSearchData(data, dispatch);
        setLoading(false);
        if (data.reportInformation.amended) {
          navigate(config.Default_Landing_Page_AmendedReport_Screen)
        }
        else {
          navigate(routerUrl);
        }
      }
    })
    .catch((error) => {
      const errors = error.errors ?? error;
      console.error(error);
      if (errors.length > 0) {
        let netWorkError = errors.find(
          (eachError: { message: string }) =>
            eachError.message.toUpperCase().includes("NETWORK")
        );
        if (netWorkError && netWorkError.message) {
          setErrorType("NETWORK");
        } else {
          let message = "";
          errors.forEach(
            (eachError: { message: string }, i: number) => {
              message = `${
                message +
                (i === 0 && " ") +
                eachError.message +
                (i !== errors.length && ", ")
              }`;
            }
          );
          setErrorType(message);
        }
      } else {
        setErrorType("UNKNOWN");
      }
      setShowError(true);
      setLoading(false);
    })
}


function getReportId(search: string){
  
  let Id = new URLSearchParams(search).get("reportId") || new URLSearchParams(search).get("reportid");;
  if (Id) {
    localStorage.setItem(localStorageKey.reportId, Id);
  } else {
    Id = localStorage.getItem(localStorageKey.reportId) || "";
  }

  return Id;
}

const validateReportData = (data: any, setErrorType: Function, setLoading: Function, setShowError: Function) => {
  
  var errorType: string | null = null;

  if(data.error && data.error.errorMessage) errorType = data.error.errorMessage.includes("Reference Number") ? "REPORT ID" : "UNKNOWN";
  if (data.reportCategories?.length == 0) errorType = "INVALID DATA";

  if (errorType) {
    setErrorType(errorType);
    setLoading(false);
    setShowError(true);
    return false;
  }

  return true;
}

export {getReportData, getReportId};