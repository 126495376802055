import { useStyles } from "./styles";
import { useScreenClass } from "react-grid-system";

interface Props {
  title?: string;
  text: string;
  isEmpty?: boolean;
  message?: string;
}
const Notification = (props: Props) => {
  const { title, text, isEmpty,message } = props;
  const theme = useScreenClass();
  const styleProps = { theme: theme };
  const classes = useStyles(styleProps);
  return (
    <div className={classes.container} data-testid="notification-container">
      {!isEmpty && <div className={classes.content} data-testid="notification"><h2>{title}</h2><p dangerouslySetInnerHTML={{ __html: text }}></p></div>}
      {isEmpty && <div className={classes.emptyContent}><h2>{message ? message : "No Notifications to display"}</h2></div>}
    </div>
  );
};

export default Notification;
