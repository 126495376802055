import { useStyles } from "./styles";
import { useEffect, useRef } from "react";

interface TabListItemProps {
  index: number;
  tabName: string;
  activeTab: number;
  callback: ()=>void;
  constraint: boolean;
}

const TabListItem = ({ index, tabName, activeTab, callback, constraint }: TabListItemProps) => {
  const classes = useStyles();
  const tabListItemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeTab === tabListItemRef.current?.tabIndex && constraint) {
      tabListItemRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
      setTimeout(() => {
        callback();
      }, 300);
    }
  }, [activeTab, callback, constraint]);

  return (
    <div ref={tabListItemRef} className={classes.tab} tabIndex={index}>
      {tabName}
    </div>
  );
};

export default TabListItem;
