import React from "react";
import { useStyles } from "./styles";

type ReportTagProps = {
  title: string;
};

const ReportTag = ({title }: ReportTagProps) => {
  const classes = useStyles();

  return (
    <div className={classes.reportTag}>
      <p className={classes.title}>{title}</p>
    </div>
  );
};

export default ReportTag;
