import React, { useState } from "react";
import MyDnaTextField from "../../components/Elements/MyDnaTextField";
import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { forgotPassword } from "./ForgotPasswordServices";
import { useStyles } from "../SignIn/styles";
import myDnaLogo from "../../assets/logos/myDNA-white-bg-logo.png";
import ImageSrc from "../../components/ImageSrc";
import ResetPassword from "./ResetPassword";
import Spinner from "../../components/Spinner/Spinner";

const ForgotPassword = () => {
  const classes = useStyles();
  const [showResetPwd, setShowResetPwd] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [emailAddress, setEmailAddress] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();
  return (
    <>
      <div className={classes.MainContainer}>
        <div className={classes.container}>
          <div className={classes.layout}>
            <div className={classes.loginContainer}>
              <div className={classes.alignCenter}>
                <ImageSrc
                  imageSrc={myDnaLogo}
                  imageAlt="myDnaLogo"
                  styles={{
                    width: 180,
                    height: 50,
                    marginLeft: 5,
                    marginBottom: 25,
                  }}
                />
              </div>
              {!showResetPwd && (
                <form
                  id={"forgot_password"}
                  onSubmit={(event) => {
                    event.preventDefault();
                    forgotPassword(
                      emailAddress,
                      setErrorMessage,
                      dispatch,
                      navigate,
                      setSuccessMsg,
                      setShowResetPwd,
                      setLoading
                    );
                  }}
                >
                  <React.Fragment>
                    <MyDnaTextField
                      id="email"
                      name="email"
                      onChange={(e: any) => setEmailAddress(e.target.value)}
                      type="text"
                      label="Email"
                      required={true}
                      value={emailAddress}
                      placeholder="Please enter your email"
                    />

                    {errorMessage && (
                      <div>
                        <p
                          className={classes.errorMessage}
                          dangerouslySetInnerHTML={{ __html: errorMessage }}
                        ></p>
                      </div>
                    )}
                    <div
                      className={classes.alignCenter}
                      style={{ marginTop: 18 }}
                    >
                      <button className={classes.button}>
                        Send verification email
                      </button>
                    </div>
                    <div className={classes.alignCenter}>
                      {successMsg && (
                        <p className={classes.success}>
                          Request has submitted successfully
                        </p>
                      )}
                    </div>

                    <div
                      className={classes.alignCenter}
                      style={{ marginTop: 18 }}
                    >
                      <Link to="/" className={classes.link}>
                        Back to login page
                      </Link>
                    </div>
                  </React.Fragment>
                </form>
              )}
              {
                showResetPwd && <ResetPassword emailAddress={emailAddress} setShowResetPwd={setShowResetPwd} setLoading={setLoading} />
              }
              
            </div>
          </div>
        </div>
      </div>
      
    {loading && <Spinner />}
    </>
  );
};

export default ForgotPassword;
