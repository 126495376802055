import { useStyles } from "./styles";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download-icon.svg";
import { useEffect, useRef, useState } from "react";
import { useOutsideActionPerformer } from "../../globals/Utils";
import PopUpMenu from "../../_atoms/PopUpMenu";
import { useScreenClass } from "react-grid-system";

type DownloadProps = {
  onClick: ()=>void;
}

const Download = ({ onClick }: DownloadProps) => {
  const classes = useStyles();
  const screenClass = useScreenClass();
  const [showMenu, setShowMenu] = useState(false);
  const [showIcon, setShowIcon] = useState(false);
  const handleClick = () => {
    setShowMenu(!showMenu);
  };
  
  const onDownload = () => {
    setShowMenu(false);
    onClick();
  };

  useEffect(() => {
    setShowIcon(screenClass !== "md");
  }, [screenClass])
  

  const downloadContainerRef = useRef(null);
  useOutsideActionPerformer(downloadContainerRef, () => setShowMenu(false), showMenu);

  return (
    <div ref={downloadContainerRef} className={classes.downloadContainer}>
      <button
        className={`${classes.downloadBox} ${
          showMenu ? classes.downloadBoxActive : classes.downloadBoxHover
        }`}
        data-testid="download"
        onClick={handleClick}
      >
        {showIcon &&<p className={classes.downloadBoxText}><DownloadIcon /></p>}
        <p className={`${classes.downloadBoxText} ${showMenu && classes.downloadBoxActiveText}`}>Download</p>
      </button>
      {showMenu && (
        <div data-testid="download-menu" className={classes.downloadMenu}>
          <PopUpMenu
            buttonText={"Download"}
            onClick={() => onDownload()}
          >
            <>
              <DownloadIcon />
              <span className={classes.downloadTitleBoldText}>
                Download Report
              </span>
              <span className={classes.downloadTitleText}>(PDF)</span>
            </>
          </PopUpMenu>
        </div>
      )}
    </div>
  );
};

export default Download;
