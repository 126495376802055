/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getReport = /* GraphQL */ `
  query GetReport($request: UserAuthorizationRequest) {
    getReport(request: $request) {
      referenceNumber
      reports {
        reportType
      }
      errorData {
        errorMessage
        status
      }
      isAuthorized
      reportPgxData {
        referenceNumber
        reportTitle
        reportType
        patient {
          dob
          salutation
          firstName
          middleName
          lastName
          fullName
          address {
            addressLine1
            addressLine2
            suburbCity
            stateProvince
            postalCode
            country
          }
        }
        clinician {
          clinicName
          salutation
          firstName
          middleName
          lastName
          fullName
          address {
            addressLine1
            addressLine2
            suburbCity
            stateProvince
            postalCode
            country
          }
        }
        reportInformation {
          locale
          amended
          amendedComment
          collected
          reportedDate
          reportBranding {
            title
            url
            base64ImageEncode
            imageType
          }
          requested
          labRef
          sampleType
          interpretationBy
          signature
          disclaimer
          pathologyProvider {
            name
            logo
            longName
            medicalDirectorShort
            medicalDirectorLong
            methodologyStatement
            panelStatement
            testByStatement
          }
        }
        contactInfo
        categories {
          name
          quadrantIntro
          relevantGenes
          quadrants {
            color
            header
            drugs {
              name
              color
              subText
              category
              subCategory
              recommendation
              considerAlternativeMedication
              prescribingConsideration
              interpretation
              phenotypes {
                gene
                shortComment
              }
            }
          }
        }
        reportCategories {
          reportCategoryTitle
          categories {
            name
            quadrantIntro
            relevantGenes
            quadrants {
              color
              header
              drugs {
                name
                color
                subText
                category
                subCategory
                recommendation
                considerAlternativeMedication
                prescribingConsideration
                interpretation
                phenotypes {
                  gene
                  shortComment
                }
              }
            }
          }
        }
        orderedGenotypes {
          result
          geneName
          rsNumber
          phenotype
          interpretation
        }
        emergingGenotypes {
          result
          geneName
          rsNumber
          phenotype
          interpretation
        }
        references {
          key
          number
          text
        }
        medicationsOfInterest {
          name
          color
          subText
          category
          subCategory
          recommendation
          considerAlternativeMedication
          prescribingConsideration
          interpretation
          phenotypes {
            gene
            shortComment
          }
        }
        inhibitorInducers {
          inducer
          inhibitorModerate
          inhibitorStrong
          medication
        }
        additionalComments
        error {
          errorMessage
          status
        }
      }
      clinician {
        clinicName
        salutation
        firstName
        middleName
        lastName
        fullName
        address {
          addressLine1
          addressLine2
          suburbCity
          stateProvince
          postalCode
          country
        }
      }
      reportType
    }
  }
`;
export const getDocumentUrl = /* GraphQL */ `
  query GetDocumentUrl($referenceNumber: String!, $reportType: String!) {
    getDocumentUrl(referenceNumber: $referenceNumber, reportType: $reportType) {
      preSignedUrl
    }
  }
`;
