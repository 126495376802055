import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
  testDetails: {
    margin: "20px",
    maxWidth: "720px",
  },
  testDetailsContent:{
    marginTop: 20,
  },
  testDetailsRows: {
    color: colour.darkBlue,
    fontSize: 12,
  },
  testDetailsKey: {
    fontFamily: "'AvenirLTStd-Roman', sans-serif",
  },
  testDetailsValue: {
    fontFamily: "'AvenirLTStd-Book', sans-serif",
  },
});
