import { useDispatch, useSelector } from "react-redux";
import { reduxState } from "../../components/globals/interfaces/commonInterfaces";
import { useStyles } from "./styles";
import {TableHeader} from "../../components/_molecules/TableHeader";
import {TableRow, Cell } from "../../components/_molecules/TableRow";
import Title from "../../components/_atoms/Title";
import Separator from "../../components/_atoms/Separator";
import { useEffect } from "react";
import { setActiveMenuItem } from "../../actions";

const TestDetails = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(
      setActiveMenuItem({
        index: 0,
        identifier: "details",
      })
    );
  }, [dispatch]);

  const data = useSelector(
    (state: reduxState) => state.appData.pgxReportData
  );

  const testDetailsData = {
    "REQUESTING CLINICIAN": data.clinician.fullName,
    "SPECIMEN TYPE": data.reportInformation.sampleType,
    "LABORATORY REFERENCE": data.reportInformation.labRef,
    "DATE REQUESTED": data.reportInformation.requested,
    "DATE COLLECTED": data.reportInformation.collected,
    "DATE REPORTED": data.reportInformation.reportedDate,
    "TESTING LABORATORY": data.reportInformation.pathologyProvider.name,
    "LAB DIRECTOR":
      data.reportInformation.pathologyProvider.medicalDirectorShort,
    "INTERPRETATION BY": data.reportInformation.interpretationBy,
    "ELECTRONIC SIGNATURE": data.reportInformation.signature,
  };

  const testDetailsRows = Object.entries(testDetailsData).map((entry) => [
    {
      content: <span className={classes.testDetailsKey}>{entry[0]}:</span>,
      width: "200px",
    },
    {
      content: <span className={classes.testDetailsValue}>{entry[1]}</span>,
      width: "",
    },
  ]);

  return (
    <>
      <Separator />
      <div className={classes.testDetails}>
        <Title>Test Details</Title>
        <div className={classes.testDetailsContent}>
          <TableHeader headings={[]}>
            <div className={classes.testDetailsRows}>
              {testDetailsRows.map((row: Cell[]) => (
                <TableRow cells={row} />
              ))}
            </div>
          </TableHeader>
        </div>
      </div>
    </>
  );
};

export default TestDetails;
