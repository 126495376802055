import MainContainer from "./components/MainContainer";
import {
  useRoutes,
  useLocation,
  Navigate,
  useNavigate,
} from "react-router-dom";
import ClinicalConsiderations from "./pages/ClinicalConsiderations";
import About from "./pages/About";
import MoreInformation from "./pages/MoreInformation";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Amplify from "aws-amplify";
import awsConfig from "./aws-exports.js";
import appSyncConfig from "./aws-appsync-config.js";
import ReportError from "./components/ReportError/ReportError";
import SignIn from "./pages/SignIn/SignIn";
import ForgotPasswordEmail from "./pages/forgotPassword/ForgotPassword";
import Welcome from "./pages/Welcome/Welcome";
import GenebyGene from "./pages/GenebyGene/GenebyGene";
import SignOut from "./pages/SignOut/SignOut";
import MedicationCategories from "./pages/MedicationCategories";
import TestResults from "./pages/TestResults";
import TestDetails from "./pages/TestDetails";
import ReferencesPage from "./pages/ReferencesPage";
import Medications from "./pages/Medications";
import NotificationPage from "./pages/NotificationsPage";

function App() {
  const [errorType, setErrorType] = useState("");
  Amplify.configure(awsConfig);
  Amplify.configure(appSyncConfig);
  require("dotenv").config();

  const dispatch = useDispatch();
  const search = useLocation().search;
  const navigate = useNavigate();

  useEffect(() => {}, [search, dispatch, navigate]);

  const RoutesList = [
    {
      path: "/",
      element: <Navigate to="/login" />,
    },
    {
      path: "/welcome",
      element: <Welcome setErrorType={setErrorType} errorType={errorType} />,
    },
    {
      path: "/reports",
      element: <GenebyGene setErrorType={setErrorType} errorType={errorType} />,
    },
    {
      path: "/login",
      element: <SignIn setErrorType={setErrorType} errorType={errorType} />,
    },
    {
      path: "/reports/forgotPassword",
      element: <ForgotPasswordEmail />,
    },
    {
      path: "/reports/ClinicalConsiderations",
      element: (
        <MainContainer
          Page={ClinicalConsiderations}
          setErrorType={setErrorType}
        />
      ),
    },
    {
      path: "/MedicationsOfInterest",
      element: <MainContainer Page={Medications} setErrorType={setErrorType} />,
    },
    {
      path: "/MedicationCategories",
      element: (
        <MainContainer
          Page={MedicationCategories}
          setErrorType={setErrorType}
        />
      ),
    },
    {
      path: "/TestDetails",
      element: <MainContainer Page={TestDetails} setErrorType={setErrorType} />,
    },
    {
      path: "/TestResults",
      element: <MainContainer Page={TestResults} setErrorType={setErrorType} />,
    },
    {
      path: "/References",
      element: (
        <MainContainer Page={ReferencesPage} setErrorType={setErrorType} />
      ),
    },
    {
      path: "/Notifications",
      element: (
        <MainContainer Page={NotificationPage} setErrorType={setErrorType} />
      ),
    },
    {
      path: "/AboutThisReport",
      element: <MainContainer Page={About} setErrorType={setErrorType} />,
    },
    {
      path: "/reports/MoreInformation",
      element: (
        <MainContainer Page={MoreInformation} setErrorType={setErrorType} />
      ),
    },
    {
      path: "/reports/Error",
      element: <ReportError errorType={errorType} />,
    },
    {
      path: "/end",
      element: <SignOut />,
    },
    {
      path: "*",
      element: <ReportError errorType={errorType} />,
    },
  ];

  const routing = useRoutes(RoutesList);

  return <>{routing}</>;
}

export default App;
