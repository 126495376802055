import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useStyles } from "./styles";
import { FederatedSignInService } from "./GenebyGeneServices";
import SimpleContainer from "../../components/SimpleContainer";
import { getReportId } from "../../components/globals/ReportsServices";

interface Props {
  setErrorType: Function;
  errorType: string;
}

const GenebyGene = (props: Props) => {
  const classes = useStyles();
  const search = useLocation().search;
  const [, setDisabled] = useState(false);

  useEffect(() => {
    getReportId(search);
  });

  function signInUserWithNucleus(event: any) {
    event.preventDefault();
    setDisabled(true);
    FederatedSignInService(
      setDisabled,
    ).then(() => {
    })
    .catch((error:any) => {
    });
  }

  return (
      <SimpleContainer>
        <div className={classes.alignCenter}>
          <br />
          <button   
            className={classes.button}
            onClick={signInUserWithNucleus}
            >
            Proceed to report
          </button>
        </div>
      </SimpleContainer>
  );
};

export default GenebyGene;
