import { useStyles } from "../styles";

interface AdditionalInformationPanelProps {
  comments: [string];
}

const AdditionalInformationPanel = ({
  comments
}: AdditionalInformationPanelProps) => {
  const classes = useStyles();

  return (
    <div
      className={classes.additionalInformation}
    >
      {comments.map((comment)=>
      <p>{comment}</p>)}
    </div>
  );
};

export default AdditionalInformationPanel;
