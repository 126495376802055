import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";

export const useStyles = createUseStyles({
  referencesList: {
    paddingLeft: 20,
  },
  references: {
    paddingBottom: 10,
    fontFamily: "AvenirLTStd-Book, sans-serif",
    fontSize: 14,
    lineHeight: "20px",
    color: colour.black,
    "& a": {
      color: colour.black,
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
});
