import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { reduxState } from "../../components/globals/interfaces/commonInterfaces";
import { useStyles } from "./styles";
import TabBar from "../../components/_atoms/TabBar";
import TextBlock from "../../components/_atoms/TextBlock";
import Filter from "../../components/_molecules/Filter";
import { colour } from "../../constants/Colours";
import DrugAccordionList from "../../components/_molecules/DrugAccordionList";
import Banner from "../../components/_atoms/Banner";
import Reference from "../../components/globals/interfaces/reference";
import { setActiveMenuItem, setIsFilterInDetailedView } from "../../actions";

const MedicationCategory = () => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const isFilterInDetailedView = useSelector((state: reduxState) => state.appData.isFilterInDetailedView);
    const [isDetailedView, setIsDetailedView] = useState(isFilterInDetailedView);
    const toggleDetailedView = (value: boolean) => {
      setIsFilterInDetailedView(value);
      dispatch(setIsFilterInDetailedView(value));
    }

    useEffect(()=>{
      setIsDetailedView(isFilterInDetailedView);
    },[isFilterInDetailedView])
    
    const categoryIndex = useSelector((state: reduxState) => state.appData.activeMenuItem.index);
    const references = useSelector((state: reduxState) => state.appData.pgxReportData.references);
    const reportCategory = useSelector((state: reduxState) => state.appData.pgxReportData.reportCategories[categoryIndex]);
    
    useEffect(() => {
      dispatch(
        setActiveMenuItem({
          index: categoryIndex || 0,
          identifier: "medicationCategories",
        })
      );
    }, [dispatch, categoryIndex]);
  
    const categoryTitle = reportCategory?.reportCategoryTitle;
    const tabs = reportCategory?.categories.map((category:any) => {
      return {
        tagName: categoryTitle,
        name: category.name,
        content: (
          <div key={categoryTitle} className={classes.categoryContent}>
            <TextBlock
              description={category.quadrantIntro}
              backgroundColor={colour.white}
            />
            <div className={classes.quadrantFilter}>
              <Filter
                onSummaryClick={()=>toggleDetailedView(false)}
                onDetailedClick={()=>toggleDetailedView(true)}
              />
            </div>
            <div className={classes.quadrants}>
              {category.quadrants.map((quadrant: any) => (
                <div
                  key={`${quadrant.header}-${isDetailedView ? `Detailed` : `Summary`}`}
                  className={`${classes.quadrantItem} ${
                    isDetailedView
                      ? classes.quadrantItemExpanded
                      : classes.quadrantItemCollapsed
                  }`}
                >
                  <Banner
                    title={quadrant.header}
                    colour={quadrant.color}
                    showTooltip={true}
                  />
                  <DrugAccordionList
                    colour={quadrant.color}
                    drugs={quadrant.drugs}
                    references={references as [Reference]}
                    isDetailed={isDetailedView}
                  />
                </div>
              ))}
            </div>
          </div>
        ),
      };
    });
    return reportCategory ? <TabBar key={categoryTitle} tabs={tabs} /> : <></>;
};

export default MedicationCategory;
