import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";
import { desktopFontsSize, tabletFontsSize } from "../../constants/Fonts";
import { colourMapper } from "../globals/Utils";
import { ReferenceTooltipStyles } from "../globals/ReferenceTooltipStyles";
import arrowIcon from "../../assets/icons/arrow-icon.svg";

export const useStyles = createUseStyles({
  container: (props: { theme: string; compColour: string }) => ({
    fontSize: ["lg", "xl", "xxl"].includes(props.theme)
      ? desktopFontsSize.xsm
      : tabletFontsSize.sm,
    width: "100%",
    background: colour.grey4,
    borderRadius: 5,
    borderLeft: `5px solid ${colourMapper(props.compColour)}`,
    boxSizing: "border-box",
    marginTop: 2,
  }),
  content: {
    color: colour.black,
    fontSize: 12,
    padding: "9px 7px 9px",
    boxSizing: "border-box",
    marginLeft: 5,
    textTransform: "uppercase",
    cursor: "pointer",
    position: "relative",
    fontFamily: "'AvenirLTStd-Medium', sans-serif",
    height: 38,
    lineHeight: '20px'
  },
  activeTitle: {
    fontFamily: "'AvenirLTStd-Black', sans-serif",
    fontSize: 12,
  },
  inActiveTitle: {},
  arrow: {
    width: 24,
    height: 24,
    display: "inline-block",
    position: "absolute",
    right: 6,
    top: 7,
    background: `url(${arrowIcon}) no-repeat center center`,
    transition: "ease-in .2s",
    "&.active": {
      transform: "rotate(90deg)",
    },
  },
  show: {
    display: "block",
    padding: "0 14px 12px",
    marginBottom: 12,
    fontFamily: "'AvenirLTStd-Roman', sans-serif",
    marginTop: -8,
    fontSize: 13,
    lineHeight: "18px",
    "& h4": {
      fontFamily: "'AvenirLTStd-heavy', sans-serif",
      margin: "10px 0",
      textTransform: "uppercase"
    },
    "& sup": ReferenceTooltipStyles,
  },
  hide: {
    display: "none",
  },
  toolTipwpr: {
    "& sup" : ReferenceTooltipStyles,
    "& div sup" : {
      lineHeight: "7px"
    }
  },
  phenotypes: {
    marginBottom: 4
  }
});

