import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
    title: {
        fontSize: "33px",
        fontFamily: "'AvenirLTStd-Heavy', sans-serif",
        fontWeight: "normal",
        lineHeight: "27px",
        userSelect: "none",
        marginRight: "16px",
        textTransform: "capitalize"
    }
});
