import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";
import searchIcon from "../../../assets/icons/search-icon.svg";
import closeIcon from "../../../assets/icons/close-icon.svg";

export const useStyles = createUseStyles({
  container: {
    position: "relative",
    width: "100%",
    margin: "20px 20px 20px 0",
    "& input": {
      outline: "none",
      "&:focus": {
        outline: "none",
        borderColor: "#081F2D",
        "&::-webkit-input-placeholder": {
          opacity: 0,
        },
      },
    },
  },

  clearButton: {
    width: 24,
    height: 24,
    background: `url(${closeIcon}) no-repeat center center transparent`,
    backgroundSize: "90%",
    right: 6,
    top: 8,
    position: "absolute",
    border: "none",
    cursor: "pointer",
  },

  autocomplete: {
    position: "relative",
  },
  input: {
    paddingLeft: 45,
    paddingRight: 34,
    fontSize: 14,
    height: 41,
    width: "100%",
    backgroundColor: colour.white1,
    background: `url(${searchIcon}) no-repeat 16px 10px`,
    border: "1.5px solid transparent",
    borderRadius: 5,
    fontFamily: "'AvenirLTStd-Book', sans-serif",
  },
  autocompleteItems: {
    position: "absolute",
    background: colour.white,
    listStyle: "none",
    margin: 0,
    padding: 0,
    zIndex: 99,
    maxWidth: 260,
    border: "none",
    borderRadius: "0 0 5px 5px",
    top: "100%",
    left: 0,
    right: 0,
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    "& li": {
      padding: "13px 16px",
      fontFamily: "'AvenirLTStd-Book', sans-serif",
      fontSize: 12,
      cursor: "pointer",
      textTransform: "capitalize",
      borderRadius: 2,
      margin: 3,
      "&:hover": {
        backgroundColor: colour.white1,
      },
      "&:active": {
        backgroundColor: colour.grey1,
      },
    },
  },
  active: {
    backgroundColor: colour.white1,
    color: colour.black,
  },
});
