import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";

export const useStyles = createUseStyles({
  bubble: {
    position: "relative",
    "& sup": {
      position: "relative",
      display: "inline-block",
      zIndex: 0,
      top: 4,
      width: 22,
      marginLeft: 4,
      borderRadius: 6,
      cursor: "pointer",
      background: colour.grey1,
      color: colour.white,
      textAlign: "center",
      fontSize: 10,
      fontFamily: "'AvenirLTStd-Medium', sans-serif",
      lineHeight: "initial",
      "&:hover": {
        background: colour.grey5,
      },
    },
  },
});
