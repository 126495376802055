import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";
import infoIcon from "../../../assets/icons/info-icon.svg";
import infoDarkIcon from "../../../assets/icons/info-dark-icon.svg";

export const useStyles = createUseStyles({
  tooltipWpr: {
    position: "relative",
    zIndex: 1,
  },
  infoIcon: {
    width: 24,
    height: 24,
    display: "inline-block",
    marginTop: 4,
    marginLeft: 18,
    marginRight: 13,
    background: `url(${infoIcon}) no-repeat center center`,
    cursor: "pointer",
  },
  infoDarkIcon: {
    width: 15,
    height: 24,
    display: "inline-block",
    background: `url(${infoDarkIcon}) no-repeat center center`,
  },
  lightTooltip: {
    padding: "14px 20px 16px",
    background: colour.white,
    zIndex: 9999,
    boxShadow: "0 0 8px #ccc",
    borderRadius: 4,
    fontFamily: "AvenirLTStd-Book, sans-serif",
    fontSize: 10,
    color: colour.black,
    position: "relative",
    marginBottom: 24,
    "& a": {
      color: colour.fadedGrey,
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  darkTooltip: {
    padding: "14px 20px 16px",
    background: colour.black,
    zIndex: 9999,
    borderRadius: 4,
    fontFamily: "AvenirLTStd-Book, sans-serif",
    color: colour.white,
    fontSize: 10,
    position: "relative",
    marginBottom: 24,
    pointerEvents: "none"
  },
upArrow: {
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: "0 24px 24px 24px",
    borderColor: "transparent transparent #ffffff transparent",
    position: "absolute",
  },
  downArrow: {
    width: 0,
    height: 0,
    borderLeft: "24px solid transparent",
    borderRight: "24px solid transparent",
    borderTop: "36px solid #000000",
    position: "absolute"
  },
  rightAlignedTooltip: {
    position: "absolute",
    left: "auto",
    top: 46,
    right: -6,
    "& em": {
      right: 7,
    },
  },
  leftAlignedTooltip: {
    position: "absolute",
    left: -29,
    top: 46,
    zIndex: 9,
  },
  topAlignedTooltip: {
    position: "absolute",
  },
  overlay: {
    width: "calc( 100% - 22px )",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
  },
  content: {
    position: "relative",
    wordBreak: "normal",
    lineHeight: "16px",
    "& a": {
      wordBreak: "break-all", //This is to fix the over floating the lengthy links
    },
  },
  children: {
    padding: "2px 0",
    fontFamily: "AvenirLTStd-Heavy",
    fontSize: 12,
  },
  referenceToolTipTitle: {
    margin: "5px 0",
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    fontSize: 12,
  },
  toolTipDescription: {
    padding: "2px 0", 
    fontFamily: "AvenirLTStd-Book", 
    fontSize: 12
  },
  referenceContent: {
    fontFamily: "'AvenirLTStd-Book', sans-serif",
    fontSize: 14,
    color: colour.black,
  },
  tooltipDescriptionWrapper: {
    display: 'flex',
    flexDirection: 'column'
  }
});
