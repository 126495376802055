import { Genotype } from "../../../components/globals/interfaces/PractitionerData";
import Reference from "../../../components/globals/interfaces/reference";
import GeneTag from "../../../components/_atoms/GeneTag";
import ImageSrc from "../../../components/_atoms/ImageSrc";
import TextBlock from "../../../components/_atoms/TextBlock";
import {TableHeader, Heading} from "../../../components/_molecules/TableHeader";
import { TableRow, Cell } from '../../../components/_molecules/TableRow';
import { colour } from '../../../constants/Colours';
import EnzymeActivityDiagramUSA from '../../../assets/enzyme-activity-diagram-USA.png';
import { useStyles } from '../styles';
import { useDispatch, useSelector } from "react-redux";
import { reduxState } from "../../../components/globals/interfaces/commonInterfaces";
import { setIsFilterInDetailedView } from "../../../actions";

import Filter from "../../../components/_molecules/Filter";
import { useEffect, useState } from "react";

interface TestResultsProps {
    genotypes: Genotype[];
    references: Reference[];
}

const TestResultsPanel = ({ genotypes, references } : TestResultsProps) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const selectedSearchItem = useSelector((state: reduxState) => state.appData.selectedSearchItem);
    const isFilterInDetailedView = useSelector((state: reduxState) => state.appData.isFilterInDetailedView);
    const [isDetailedView, setIsDetailedView] = useState(isFilterInDetailedView);

    const toggleDetailedView = (value: boolean) => {
      setIsFilterInDetailedView(value);
      dispatch(setIsFilterInDetailedView(value));
    };

    const isSelectedItem = (geneName: string) => {
      return (geneName === selectedSearchItem.name)
    };

    useEffect(() => {
      setIsDetailedView(isFilterInDetailedView);
    }, [isFilterInDetailedView]);

    const tableHeadings: Heading[] = [
        {
            value: 'Gene',
            width: '25%'
        },
        {
            value: 'Genotype',
            width: '25%'
        },
        {
            value: 'Predicted Phenotype',
            width: '50%'
        }
    ]

    var tableRows: JSX.Element[] = genotypes.map((genotype, index) => {
        
        var cells: Cell[] = [
          {
            width: "25%",
            content: (
              <>
                <GeneTag title={genotype.geneName} />
              </>
            ),
          },
          {
            width: "25%",
            content: <>{genotype.result}</>,
          },
          {
            width: "50%",
            content: (
              <>
                <TextBlock
                  title={genotype.phenotype}
                  description={genotype.interpretation}
                  backgroundColor={colour.white1}
                  references={references}
                  isExpanded={isDetailedView || isSelectedItem(genotype.geneName)}
                />
              </>
            ),
          },
        ];

        return (
          <TableRow
            key={`test-result-genotype-${index}`}
            isHighLighted={isSelectedItem(genotype.geneName)}
            cells={cells}
          />
        );
    });

    return (
      <>
        <div className={classes.filterDiv}>
          <Filter
            onSummaryClick={() => toggleDetailedView(false)}
            onDetailedClick={() => toggleDetailedView(true)}
          />
        </div>
        <div className={classes.componentWrapper}>
          <TableHeader headings={tableHeadings}>
            <>{tableRows}</>
          </TableHeader>
        </div>
        <div className={classes.componentWrapper}>
          <div className={classes.componentWrapper}>
            <span className={classes.imageDescriptionFont}>
              The following diagram represents the range of enzyme activities
              predicted by this test.
            </span>
          </div>
          <div className={classes.imageWrapper}>
            <ImageSrc
              styles={{ width: "100%", height: "auto" }}
              imageSrc={EnzymeActivityDiagramUSA}
              imageAlt="Image showing metaboliser levels by enzyme activity"
            />
          </div>
        </div>
      </>
    );
}

export default TestResultsPanel;
