import { useStyles } from "./styles";
import myDnaLogo from "../../assets/logos/myDNA-white-bg-logo.png";
import gxgLogo from "../../assets/logos/gxg_logo-color.png";
import ImageSrc from "../../components/ImageSrc";

type Props = {
  children: JSX.Element,
};

const SimpleContainer = (props: Props) => {
  const classes = useStyles();

  return (
    <div>
    <div className={classes.MainContainer}>
      <div className={classes.container}>
        <div className={classes.layout}>
          <div className={classes.loginContainer}>
            <div className={classes.alignCenter}>
            <ImageSrc
                imageSrc={gxgLogo}
                imageAlt="gxgLogo"
                styles={{
                  height: 50,
                  marginLeft: 5,
                  marginRight: 15,
                  marginBottom: 25,
                }}
              />
              <ImageSrc
                imageSrc={myDnaLogo}
                imageAlt="myDnaLogo"
                styles={{
                  height: 50,
                  marginLeft: 5,
                  marginBottom: 25,
                }}
              />
            </div>
            
            {props.children}
           
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default SimpleContainer;
