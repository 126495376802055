import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
  MainContainer: {
    maxWidth: 495,
    marginTop: 80,
    marginBottom: 20,
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    fontFamily: "Helvetica"
  },
  container: {
    background: "white",
    height: "100%",
  },
  layout: {
    height: "auto",
    display: "flex",
    minHeight: "100%",
    borderRadius: 16,
    boxShadow: "0 0 8px " + colour.fadedGrey5,
  },
  loginContainer: {
    margin: "80px auto 80px 80px",
    fontSize: 14,
  },
  description: {
    textAlign: "center",
    marginBottom: 28
  },
  button: {
    background: colour.darkPink,
    padding: "12px 24px",
    minWidth: 137,
    textAlign: "center",
    border: "none",
    color: colour.white,
    textTransform: "uppercase",
    cursor: "pointer",
    borderRadius: 50
  },
  buttonNucleus: {
    composes: '$button',
    background: colour.white,
    color: colour.darkPink,
    border: "2px solid " + colour.darkPink,
  },
  gapBottom: {
    marginBottom: 38,
  },
  forgotPwdEmail: {
    color: colour.blue,
    fontSize: 12,
  },
  alignCenter: {
    textAlign: "center"
  },
  errorMessage: {
    color: colour.red
  },
  success: {
    color: colour.green2,
    fontWeight: "bold"
  },
  link: {
    color: colour.blue,
    textDecoration: "underline",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none"
    }
  }
});
