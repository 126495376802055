import { useEffect } from "react";
import { useStyles } from "./styles";
import { colour } from "../../constants/Colours";
import ImageSrc from "../ImageSrc";
import brokenDNAImage from "../../assets/dna-banner-image.png";

const ReportError = (props: { errorType: string }) => {
  const { errorType } = props;
  const classes = useStyles();
  useEffect(() => {
    document.body.style.backgroundColor = colour.fadedGrey3;
  }, []);

  const getErrorMessage = () => {
    // ::TODO:: Error messages needs to be decided
    switch (errorType) {
      case "NETWORK":
        return "Return to the myDNA Portal and check your Network connection.";
      case "REPORT ID":
        return "Please access the myDNA Portal with a valid Report ID.";
      case "No current user":
        return "Please login and continue.";
      case "UNKNOWN":
        return "Something went wrong. Please try later";
      default:
        return "Something went wrong. Please try later";
    }
  };

  const getErrorTitle = () => {
    // ::TODO:: Error messages needs to be decided
    switch (errorType) {
      case "REPORT ID":
        return "Your Report ID is invalid";
      default:
        return "We're having trouble fetching your data";
    }
  };

  return (
    <div className={classes.wpr}>
      <ImageSrc
        imageSrc={brokenDNAImage}
        imageAlt="broken DNA Image"
        styles={{
          width: "100%",
          height: "auto",
          borderRadius: "20px 20px 0 0",
        }}
      />
      <div className={classes.contentSec}>
        <h1 className={classes.header}>{getErrorTitle()}</h1>
        <div className={classes.description}>
          <p> {getErrorMessage()}</p>
        </div>
      </div>
    </div>
  );
};

export default ReportError;
