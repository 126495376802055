import { useStyles } from "./styles";

type MenuTitleProps = {
  children: JSX.Element;
};

const MenuTitle = ({ children }: MenuTitleProps) => {

    const classes = useStyles();

    return (
        <div className={classes.menuTitle}>
            {children}
        </div>
    );
};

export default MenuTitle;
