import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";

export const useStyles = createUseStyles({
  pageTitle: {
    fontSize: 16,
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    lineHeight: "24px",
    display: "inline-block",
    borderBottom: "2px solid " + colour.blue,
    paddingBottom: 10,
    paddingRight: 12,
    margin: "12px 0 24px 0",
  },
  tabBarContainer: {
    position: "relative",
    height: "90vh",
    overflow: "hidden",
    fontFamily: "'AvenirLTStd-Roman', sans-serif",
    fontSize: 13,
    lineHeight: "24px",
  },
  heading: {
    fontSize: 21,
    lineHeight: "27px",
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    fontWeight: "normal",
    marginBottom: 20,
    marginTop: 0,
  },
  description: {
    fontSize: 13,
    lineHeight: "16px",
    fontFamily: "'AvenirLTStd-Roman', sans-serif",
    marginBottom: 16,
  },
  quadrantWpr: {
    marginBottom: 24,
    marginTop: 16,
  },
  listItem: {
    margin: "0 0 20px",
    padding: "0 0 0 18px",
    lineHeight: "24px",
  },
  bottomGap: {
    marginBottom: 36,
  },
  bottomGapXs: {
    marginBottom: 12,
  },
  panelHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "20px",
  },
  panelContent: {
    margin: "20px",
  },
  tabBarDiv: {
    minHeight: "54px",
    height: "6vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    position: "relative",
  },
  tabPanelDiv: {
    maxHeight: "84vh",
    overflow: "overlay",
    scrollBehavior: "smooth",
  },
  title: {
    fontSize: 33,
    lineHeight: "27px",
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    fontWeight: "normal",
    margin: 0,
    marginRight: 16,
    userSelect: "none",
  },
  tabsDivWrapper: {
    position: "relative",
    minHeight: 33,
    margin: "10px 0",
  },
  tabsDiv: {
    height: 33,
    margin: "0 20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "start",
    alignItems: "center",
    overflow: "overlay",
    scrollBehavior: "smooth",
    transition: "width 3s",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  tabsList: {
    padding: 0,
    margin: 0,
    width: "max-content",
    display: "flex",
    listStyle: "none",
    borderRadius: "100px",
    "& li": {
      padding: "4px 30px",
      marginRight: 50,
      color: colour.blue5,
      position: "relative",
      cursor: "pointer",
      fontFamily: "'AvenirLTStd-Roman', sans-serif",
      wordWrap: "normal",
      marginTop: 1,
      "&:hover": {
        background: colour.white1,
        borderRadius: "100px",
      },
      "&.react-tabs__tab--selected": {
        fontFamily: "'AvenirLTStd-Roman', sans-serif",
        color: colour.white,
        background: colour.blue5,
        borderRadius: "100px",
      },
    },
    "& li:last-child": {
      marginRight: 0,
    },
  },
  tab: {
    width: "max-content",
    userSelect: "none",
  },
  arrowDiv: {
    minWidth: 40,
    transition: ".5 ease",
  },
  leftArrow: {
    position: "absolute",
    top: 0,
    left: 20,
    zIndex: 100,
    background:
      "linear-gradient(90deg, rgb(255 255 255) 60%, rgba(255, 255, 255, 0) 100%)",
    height: 35,
    width: 80,
    display: "flex",
    justifyContent: "start",
  },
  rightArrow: {
    position: "absolute",
    top: 0,
    right: 20,
    zIndex: 100,
    background:
      "linear-gradient(270deg, rgb(255 255 255) 60%, rgba(255, 255, 255, 0) 100%)",
    height: 35,
    width: 80,
    display: "flex",
    justifyContent: "end",
  },
  floatingArrow: {
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: colour.white1,
    borderRadius: "100%",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgba(178, 178, 178, 0.45)",
      "& svg > path": { opacity: 1 },
    },
  },
});
