import { useScreenClass } from "react-grid-system";
import { colour } from "../../../constants/Colours";
import { useStyles } from "./styles";

export type Heading = {
  value: string;
  width: string;
}

export const TableHeader = (props: {
  headings: Heading[];
  children: JSX.Element;
}) => {
  const { headings, children } = props;
  const theme = useScreenClass();
  const styleProps = {
    theme: theme,
    colour: colour,
    position: { left: "0px", top: "0px" },
  };
  const classes = useStyles(styleProps);

  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      width="100%"
      className={classes.table}
      data-testid="table"
    >
      <thead>
        <tr>
          {headings.map((heading) => (
            <th style={{ width: heading.width }}>{heading.value}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
  );
};