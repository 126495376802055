import { useRef } from "react";
import { useStyles } from "./styles";
import PropTypes from "prop-types";
import { useScreenClass } from "react-grid-system";
import MenuItem from "../../_atoms/MenuItem";
import { useDispatch } from "react-redux";
import { setActiveMenuItem } from "../../../actions";
import { MenuItem as MenuItemInterface } from "../../globals/interfaces/commonInterfaces";

type MenuProps = {
  identifier: string;
  items: any;
  containerStyles: any;
  selectedMenuItem: MenuItemInterface;
  icon?: string
};

const Menu = ({ items, containerStyles, identifier, selectedMenuItem, icon }: MenuProps) => {
  const dispatch = useDispatch();
  const screenClass:any = useScreenClass();
  const SelectedRef:any = useRef();
  const classes = useStyles(screenClass);

  const renderList = () => {
    return items.map((item: any, index: number) => {

      const updateActiveItemOnClick = () => {
        dispatch(setActiveMenuItem({
          index: index,
          identifier: identifier
        }));
        item.onClick();
      }

      var isActive = false;
      if (selectedMenuItem) isActive = index === selectedMenuItem.index && identifier === selectedMenuItem.identifier;

      return (
        <li key={index}>
          <MenuItem icon={icon} route={item.route} name={item.name} isActive={isActive} index={index} onClick={updateActiveItemOnClick} />
        </li>
      );
    });
  };
  return (
    <div
      className={classes.navigationContainer}
      style={containerStyles}
      data-testid="menuwpr"
    >
      <ul className={classes.listContainer} ref={SelectedRef}>
        {renderList()}
      </ul>
    </div>
  );
};

Menu.propTypes = {
  containerStyles: PropTypes.objectOf(PropTypes.any),
  items: PropTypes.arrayOf(PropTypes.any),
};

export default Menu;
