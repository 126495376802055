import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({

  alignCenter: {
    textAlign: "center"
  },
  button: {
    background: colour.blue,
    padding: "12px 24px",
    minWidth: 137,
    textAlign: "center",
    border: "none",
    color: colour.white,
    textTransform: "uppercase",
    cursor: "pointer",
    borderRadius: 50
  }
});
