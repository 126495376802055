import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  userControlContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  userButton: {
    background: "transparent",
    border: "none",
    cursor: "pointer",
    padding: 0,
    marginLeft: 16,
    display: "flex",
  },
  closeReport: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  closeReportGreeting: {
    marginBottom: 15,
    width: "100%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  closeReportLabel: {
    display: "flex",
    alignItems: "end",
    "& svg":{
      marginRight: 6
    }
  },
  reportTitleBoldText: {
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    fontSize: 14,
  },
  userControlPopUp: {
    display: "contents",
  },
});