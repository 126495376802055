import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";

export const useStyles = createUseStyles({
  downloadContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  downloadBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    boxSizing: "border-box",
    width: "100%",
    height: 41,
    backgroundColor: colour.white,
    border: "1.5px solid #081F2D",
    borderRadius: "4px",
    lineHeight: 0,
    fontFamily: "'AvenirLTStd-Medium', sans-serif",
    color: colour.black,
    fontSize: 14,
    cursor: "pointer",
    userSelect: "none",
  },
  downloadBoxHover: {
    "&:hover": {
      backgroundColor: colour.white1,
      fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    },
  },
  downloadBoxActive: {
    backgroundColor: colour.grey1,
  },
  downloadBoxText: {
    padding: "0 5px"
  },
  downloadBoxActiveText:{
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
  },
  downloadMenu:{
    display:"contents",
  },
  downloadTitleText: {
    fontFamily: "'AvenirLTStd-Roman', sans-serif",
    fontSize: 14,
    paddingLeft: 4,
  },
  downloadTitleBoldText: {
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    fontSize: 14,
    paddingLeft: 6,
  },
});
