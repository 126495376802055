import React, { useState, useLayoutEffect, useCallback } from "react";
import AccordionHeader from "../AccordionHeader";
import AccordionList from "../AccordionList";
import ExpandedView from "./ExpandedView";
import Reference from "../globals/interfaces/reference";

interface Props {
  title: string;
  infoMessage?: string;
  colour: string;
  drugs: [];
  tooltipPosition: string;
  references: Array<Reference>;
  wrapperRef: any;
}

const PrescribingConsideration = (props: Props) => {
  const { title, colour, drugs, tooltipPosition, references, wrapperRef } =
    props;

  const [modalIsOpen, setIsOpen] = useState(false);
  const [modelPosition, setModelPosition] = useState({left: "0px", top:"0px"})

  
  const setPosition = useCallback(() => {
    if(wrapperRef.current){
      const elmPosition = wrapperRef.current.getBoundingClientRect()
      setModelPosition({left: elmPosition.x, top:elmPosition.y })
    }
  }, [wrapperRef])

  function openModal() {
    setPosition();
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  }

  function closeModal() {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  }


  useLayoutEffect(() => {
    function updateSize() {
      setPosition();
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [setPosition]);

  const showExpandAllLink = () => {
    if(colour.toLowerCase() === "gray")
      return false;
      else if(drugs.length === 0)
        return false;
        else 
          return true
  }

  return (
    <>
    {modalIsOpen && 
      <ExpandedView
        drugs={drugs}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        title={title}
        colour={colour}
        position={modelPosition}
        references={references}
        wrapperRef={wrapperRef}
      />
    }

      <AccordionHeader
        title={title}
        colour={colour}
        openModal={openModal}
        tooltipPosition={tooltipPosition}
        showExpandAllLink={showExpandAllLink()}
      />
      <AccordionList colour={colour} drugs={drugs} references={references} />
    </>
  );
};

export default PrescribingConsideration;
