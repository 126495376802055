import { PathologyProvider } from "../../../components/globals/interfaces/PractitionerData";
import { useStyles } from "../styles";
import {
  GeneByGeneTestMethodologyAndLimitations,
  KaiserTestMethodologyAndLimitations,
  IgentifyTestMethodologyAndLimitations,
} from "../../../constants/TestMethodology/testMethodology";
import { PathologyProviderConstants } from "../../../constants/Constants";

interface AdditionalGenesPanelProps {
  pathologyProvider: PathologyProvider;
}

const AdditionalGenesPanel = ({
  pathologyProvider,
}: AdditionalGenesPanelProps) => {
  const classes = useStyles();

  const getMethodologyAndLimitations = () => {
    switch (pathologyProvider.name) {
      case PathologyProviderConstants.Igentify:
        return IgentifyTestMethodologyAndLimitations;
      case PathologyProviderConstants.Kaiser:
        return KaiserTestMethodologyAndLimitations;
      default:
        // Default to Gene By Gene
        return GeneByGeneTestMethodologyAndLimitations;
    }
  };

  return (
    <>
      <div className={classes.textBlockWrapper}>
        <div>{getMethodologyAndLimitations()}</div>
      </div>
      <div className={classes.textBlockWrapper}>
        <div className={classes.title}>Test Panel of Genes and Variants</div>
        <div>{pathologyProvider.panelStatement}</div>
      </div>
    </>
  );
};

export default AdditionalGenesPanel;
