import { useDispatch, useSelector } from "react-redux";
import { reduxState } from "../../components/globals/interfaces/commonInterfaces";
import TabBar, { TabItem } from "../../components/_atoms/TabBar";
import MedicationsOfInterestPanel from "./panels/MedicationsOfInterestPanel";
import PotentialDrugInteractionsPanel from "./panels/PotentialDrugInteractionsPanel";
import AdditionalInformationPanel from "./panels/AdditionalInformationPanel";
import { useEffect } from "react";
import { setActiveMenuItem } from "../../actions";

const Medications = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setActiveMenuItem({
        index: 0,
        identifier: "medications",
      })
    );
  }, [dispatch]);

  const pgxReportData = useSelector((state: reduxState) => state.appData.pgxReportData);
  const medications = pgxReportData?.medicationsOfInterest;
  const interactions = pgxReportData?.inhibitorInducers;
  const references = pgxReportData?.references;
  const additionalComments = pgxReportData?.additionalComments;

  const potentialDrugInteractionsText = `The effect of drug-drug interactions can be additive to the effect of genotype on drug metabolism. Inhibitors can decrease and inducers can increase metabolism, leading to changes in drug concentration and clinical effects.
    <br/><br/>Comments in the medications of interest and future medications sections only consider the effects of the patient’s genotype, not those due to interacting drugs. For the health professional’s consideration, the table below identifies which of the patient’s current drugs may inhibit or induce those enzymes tested by myDNA. The extent of the inhibition or induction depends on the dose and duration of the therapy. The overall effect on metabolism by a specific enzyme may be estimated by considering both the genetic finding and the potential interacting drug.`;

  const tabs: TabItem[] = [
    {
      name: "Medications of Interest",
      content: (
        <MedicationsOfInterestPanel
          drugs={medications as any}
          references={references}
        />
      ),
    },
    {
      name: "Potential Drug Interactions",
      content: (
        <PotentialDrugInteractionsPanel
          description={potentialDrugInteractionsText}
          interactions={interactions}
        />
      ),
    },
  ];

    if (additionalComments.length > 0) {
      tabs.push({
        name: "Additional Information",
        content: (
          <AdditionalInformationPanel comments={additionalComments} />
        ),
      });
    }

  return <TabBar tabs={tabs} />;
};

export default Medications;
