import { useStyles } from "../../pages/About/styles";

interface IFullMedsReportDisclaimerProps {
  pathologyProviderName: string;
}

export const FullMedsReportDisclaimer = (
  props: IFullMedsReportDisclaimerProps
) => {
  const classes = useStyles();

  return (
    <div className={classes.pageWrapper}>
      <div data-testid="fmrd-p1" className={classes.lineWrapper}>
        Response to medications is complex and may also be influenced by other
        genetic and non-genetic factors which are not tested for (e.g. patient
        adherence to prescription regimen, concurrent illness, drug-drug
        interactions). This report is just one clinical factor which is intended
        to be considered in addition to other clinical information as part of a
        comprehensive medical evaluation by the treating clinician. It is
        advised that medications should not be changed solely based on this
        report and it is the responsibility of the treating clinician to
        consider all information relating to the patient to determine the most
        appropriate course of treatment. Unless instructed by their doctor,
        patients are advised not to alter the dose or stop any medications. This
        report does not serve as medical advice and
        {` ${props.pathologyProviderName}`} is not liable for medical judgement
        with regards to diagnosis, prognosis or treatment.
      </div>
      <div data-testid="fmrd-p2" className={classes.lineWrapper}>
        Clinical monitoring should occur for all medications. It is not intended
        to imply that drugs listed in this report are approved for certain
        indications or that they have comparable efficacy or safety.
      </div>
      <div data-testid="fmrd-p3" className={classes.lineWrapper}>
        The test only determines response to the medications indicated in this
        report. Allergic reactions cannot be detected by this test. The test
        does not detect all known variants in the genes tested. If an individual
        carries a rare variant not covered by the test, the phenotype may be
        inaccurately reported.
      </div>
      <div data-testid="fmrd-p4" className={classes.lineWrapper}>
        Genetic counselling is recommended to properly review and explain these
        results to the tested individual as there may be implications for both
        the individual in addition to family members. This is not provided by
        {` ${props.pathologyProviderName}`} and responsibility to arrange this
        is with the ordering physician or patient.
      </div>
      <div data-testid="fmrd-p5" className={classes.lineWrapper}>
        The information provided in the report is believed to be accurate at the
        time of publishing and is based on the current evidence available in the
        literature at that time. However, as the scientific literature and
        prescribing guidelines are updated over time, interpretations and
        recommendations relating to the prescribing of medications indicated in
        this report may change.
      </div>
      <div data-testid="fmrd-p6" className={classes.lineWrapper}>
        The pharmacogenomic guidance in this report primarily applies to adult
        patients over the age of 18 years. Therefore, caution should be
        exercised if the guidance in this report is to be used for patients
        under the age of 18 years.
      </div>
    </div>
  );
};
