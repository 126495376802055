import { useDispatch, useSelector } from 'react-redux';
import { reduxState } from '../../components/globals/interfaces/commonInterfaces';
import TabBar, { TabItem } from '../../components/_atoms/TabBar';
import TestResultsPanel from './panels/testResultsPanel';
import AdditionalGenesPanel from './panels/additionalGenesPanel';
import TestMethodologyPanel from './panels/testMethodologyPanel';
import { useEffect } from 'react';
import { setActiveMenuItem } from '../../actions';

const TestResults = () => {
    const dispatch= useDispatch();
    useEffect(() => {
      dispatch(
        setActiveMenuItem({
          index: 0,
          identifier: "results",
        })
      );
    }, [dispatch]);

    const pgxReportData = useSelector((state: reduxState) => state.appData.pgxReportData);

    const tabs: TabItem[] = [{
        name: 'Test Results',
        content: (
            <>
                <TestResultsPanel genotypes={pgxReportData.orderedGenotypes} references={pgxReportData.references}/>
            </>
        ),
      }, {
        name: 'Additional Genes With Emerging Evidence',
        content: (
            <>
                <AdditionalGenesPanel emergingGenotypes={pgxReportData.emergingGenotypes} references={pgxReportData.references} />
            </>
        )
      }, {
        name: 'Test Methodology and Limitations',
        content: (
            <>
                <TestMethodologyPanel pathologyProvider={pgxReportData.reportInformation.pathologyProvider} />
            </>
        )
      }];
    
    return <TabBar tabs={tabs} />;
};

export default TestResults;
