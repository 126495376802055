import { useStyles } from "./styles";

interface TitleProps {
    children: JSX.Element | string;
}

const Title = ({children}: TitleProps) => {
    const classes = useStyles();

    return (
        <div className={classes.title} >
            {children}
        </div>
    )
};

export default Title;