import { useStyles } from "./styles";
import AccordionItem from "../AccordionItem";
import { useScreenClass } from "react-grid-system";
import Reference from "../../globals/interfaces/reference";
import { reduxState } from "../../globals/interfaces/commonInterfaces";
import { useSelector } from "react-redux";
import WarningIcon from "../../../assets/icons/warning.svg";
import GeneTag from "../../_atoms/GeneTag";
import { useEffect, useRef } from "react";
import { Drugs, Phenotypes } from "../../globals/interfaces/PractitionerData";
import ReferenceTooltip from "../../_atoms/Tooltip/ReferenceTooltip";

export interface Props {
  colour: string;
  drug: Drugs;
  references: Array<Reference>;
  size?: string;
  isDetailed: boolean;
}

const DrugAccordionItem = ({
  colour,
  drug,
  references,
  size = "Large",
  isDetailed,
}: Props) => {
  const hasWarningSign = drug.considerAlternativeMedication;
  const activeDrug = useSelector(
    (state: reduxState) => state.appData.activeDrug
  );

  const theme = useScreenClass();
  const styleProps = { theme: theme, compColour: colour, size: size };
  const classes = useStyles(styleProps);

  const drugAccordionRef = useRef<HTMLDivElement>(null);

  const expandDrugAccordion = (drugName: string) => {
    return (drugName === activeDrug)
  };

  useEffect(() => {
    if (drug.name === activeDrug) {
      setTimeout(() => {
        drugAccordionRef.current?.scrollIntoView({
          behavior: "smooth",
        });
      }, 300);
    }
  }, [activeDrug, drug.name, drugAccordionRef]);
  

  const DrugAccordionHeading = (drug: Drugs, hasWarningSign: boolean) => {
    return `<p>${drug.name}</p>
    <p>${drug.subCategory || ""}</p>
      ${
        hasWarningSign
          ? `<p>
          <img src=${WarningIcon} alt="warningIcon"
           style = "margin-bottom: 2px; vertical-align: middle";
          /></p>`
          : ``
      }`;
  };

  const DrugTextDescription = (
    textDescription: string,
    drug: Drugs
  ) => {
    const textDescriptionKey = textDescription as keyof Drugs;
    const isInterpretationSection = textDescription === "interpretation";

    var drugDescription = drug[textDescriptionKey] as string;

    return (
      <div>
        <p className={classes.subtitle}>{textDescription}</p>
        <div className={classes.MedicationContent}>
          {isInterpretationSection && (
            <div className={classes.interpretationContainer}>
              {drug.phenotypes &&
                drug.phenotypes.map((phenotype: Phenotypes, i: number) => (
                  <div key={i} className={classes.phenotypes}>
                    <GeneTag
                      title={phenotype.gene.split(" ")[0]}
                      description={phenotype.gene.split(" ")[1]}
                    />
                    <p className={classes.phenotypeDescription}>
                      {phenotype.shortComment}{" "}
                    </p>
                  </div>
                ))}
            </div>
          )}
          <ReferenceTooltip references={references} content={drugDescription} />
        </div>
      </div>
    );
  };

  const DrugAccordionContent = (drug: Drugs) => {
    return (
      <>
        {DrugTextDescription("interpretation", drug)}
        {DrugTextDescription("recommendation", drug)}
      </>
    );
  };

  return (
    <div ref={drugAccordionRef}>
      <AccordionItem
        size={size}
        colour={colour}
        heading={DrugAccordionHeading(drug, hasWarningSign)}
        isDetailed={isDetailed}
        isExpanded={expandDrugAccordion(drug.name)}
      >
        {DrugAccordionContent(drug)}
      </AccordionItem>
    </div>
  );
};

export default DrugAccordionItem;
