import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";

export const useStyles = createUseStyles({
  menuTitle: {
    fontSize: 12,
    fontFamily: 'AvenirLTStd-Heavy',
    textTransform: "uppercase",
    color: colour.blue6,
    padding: "0 5px"
  }
});
