import { useStyles } from "./styles";
import { useState, useRef, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import FloatingArrow from "./FloatingArrow";
import ReportTag from "../ReportTag";
import Separator from "../Separator";
import { reduxState } from "../../globals/interfaces/commonInterfaces";
import { useDispatch, useSelector } from "react-redux";
import { setActiveDrug, setIsFilterInDetailedView, setSelectedSearchItem } from "../../../actions";
import TabListItem from "./TabListItem";
import { useOutsideActionPerformer } from "../../globals/Utils";

export type TabItem = {
  name: string;
  content: JSX.Element;
  tagName?: string;
};

interface TabBarProps {
  tabs: TabItem[];
};

const TabBar = ({ tabs }: TabBarProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const selectedSearchItem = useSelector(
    (state: reduxState) => state.appData.selectedSearchItem
  );
  const activeDrug = useSelector(
    (state: reduxState) => state.appData.activeDrug
  );
  useEffect(() => {
    if (Object.keys(selectedSearchItem).length > 0) {
      setActiveTab(selectedSearchItem.activeTab);
    }
  }, [selectedSearchItem.uniqueId, selectedSearchItem]);

  const onTabClick = (index: number) => {
    setActiveTab(index);
    dispatch(setIsFilterInDetailedView(false));
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const tabsDivRef = useRef<HTMLDivElement>(null);
  const tabsPanelDivRef = useRef<HTMLDivElement>(null);

  const [isLeftOverflowed, setIsLeftOverflowed] = useState<boolean>(false);
  const [isRightOverflowed, setIsRightOverflowed] = useState<boolean>(false);

  const containerExtraPadding = 40;

  const checkOverflow = ()=>{
    const hasOverflowed = 
    (containerRef.current?.offsetWidth ? containerRef.current?.offsetWidth : 0) +
        (tabsDivRef.current?.scrollLeft ? tabsDivRef.current?.scrollLeft : 0) !==
      (tabsDivRef.current?.scrollWidth ? tabsDivRef.current?.scrollWidth : 0)
    + containerExtraPadding;
    setIsRightOverflowed(hasOverflowed);
    setIsLeftOverflowed(
      (tabsDivRef.current?.scrollLeft ? tabsDivRef.current?.scrollLeft : 0) > 1
    );
  }

  const scrollTo = async (direction: string) =>{
    await tabsDivRef.current?.scrollTo(tabsDivRef.current?.scrollLeft + (direction === "Right" ? 180 : -180), 0);
    setTimeout(() => {
      checkOverflow();
    }, 250);
  }

  useEffect(() => {
    checkOverflow();
    window.addEventListener("load", checkOverflow, false);
    window.addEventListener("resize", checkOverflow, false);
  }, [tabs.length]);

  const clearSearchItems = () => {
    dispatch(setSelectedSearchItem({}));
    dispatch(setActiveDrug(null));
  };

  useOutsideActionPerformer(tabsPanelDivRef, () => clearSearchItems(), true);

  return (
    <div
      data-testid="tab-bar"
      ref={containerRef}
      className={classes.tabBarContainer}
    >
      <Tabs selectedIndex={activeTab} onSelect={() => {}}>
        <div className={classes.tabBarDiv}>
          <div className={classes.tabsDivWrapper}>
            <div ref={tabsDivRef} className={classes.tabsDiv}>
              <div
                data-testid="floating-left-arrow"
                className={`${isLeftOverflowed && classes.arrowDiv}`}
              >
                {isLeftOverflowed && (
                  <div className={classes.leftArrow}>
                    <FloatingArrow
                      direction="Left"
                      onClick={() => scrollTo("Left")}
                    />
                  </div>
                )}
              </div>
              <TabList className={classes.tabsList} data-testid="tab-list">
                {tabs.map((tab, index) => (
                  <Tab
                    key={index}
                    data-testid={`tab-item-${index}`}
                    onClick={() => onTabClick(index)}
                  >
                    <TabListItem
                      index={index}
                      tabName={tab.name}
                      activeTab={activeTab}
                      callback={checkOverflow}
                      constraint={!!activeDrug}
                    />
                  </Tab>
                ))}
              </TabList>
              <div
                data-testid="floating-right-arrow"
                className={classes.arrowDiv}
              >
                {isRightOverflowed && (
                  <div className={classes.rightArrow}>
                    <FloatingArrow
                      direction="Right"
                      onClick={() => scrollTo("Right")}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <Separator />
        </div>
        <div ref={tabsPanelDivRef} className={classes.tabPanelDiv}>
          {tabs.map((tab, index) => (
            <TabPanel key={index} data-testid="tab-panel">
              <div className={classes.panelHeader}>
                <p className={classes.title}>{tab.name}</p>
                {tab.tagName && (
                  <div data-testid={`report-tag-${index}`}>
                    <ReportTag title={tab.tagName} />
                  </div>
                )}
              </div>
              <div
                data-testid={`tab-content-${index}`}
                className={classes.panelContent}
              >
                {tab.content}
              </div>
            </TabPanel>
          ))}
        </div>
      </Tabs>
    </div>
  );
};

export default TabBar;
