import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";

export const useStyles = createUseStyles({
  table: {
    width: "100%",
    verticalAlign: "top",
    textAlign: "left",
    "& td": {
      verticalAlign: "middle",
      padding: "5px 12px",
      lineHeight: "20px",
    },
    "& th": {
      padding: "9px 12px",
    },
    "& tr": {
      marginBottom: 4,
      display: "table",
      width: "100%",
      borderRadius: 4,
      boxSizing: "border-box",
    },

    "& thead": {
      "& tr": {
        background: colour.white1,
        "& th": {
          fontSize: 13,
          color: colour.black,
          fontFamily: "'AvenirLTStd-Medium', sans-serif",
          fontWeight: 400,
          lineHeight: "22px",
          textTransform: "uppercase",
        },
      },
    },

    "& tbody": {
      "& tr": {
        height: 40,
        background: colour.white1,
        "& td": {
          "& [data-id='reference-tooltip']": {
            marginTop: 10,
          },
        },
      },
    },
  },
  expandAllLink: {
    color: colour.grey5,
    fontSize: 12,
    fontFamily: "'AvenirLTStd-Roman', sans-serif",
    textDecoration: "underline",
    paddingRight: 12,
    cursor: "pointer",
    verticalAlign: "middle !important",
    "&:hover": {
      textDecoration: "none",
    },
  },
});
