import { Drugs } from "../../../components/globals/interfaces/PractitionerData";
import Reference from "../../../components/globals/interfaces/reference";
import Banner from "../../../components/_atoms/Banner";
import TextBlock from "../../../components/_atoms/TextBlock";
import DrugAccordionItem from "../../../components/_molecules/DrugAccordionList/DrugAccordionItem";
import { colour } from "../../../constants/Colours";
import { useStyles } from "../styles";

interface MedicationsOfInterestPanelProps {
  drugs: Drugs[];
  references: Reference[];
}

const MedicationsOfInterestPanel = ({
  drugs,
  references,
}: MedicationsOfInterestPanelProps) => {
  const classes = useStyles();

  const descriptionText = "Below is a list of medications of interest that were provided on the patient's request form.";
  const noDataFoundText = "No medications of interest were provided on the patient's request form."

  return (
    <>
      {drugs.length > 0 ? (
        <>
          <TextBlock
            shortenedCharacterLimit={120}
            backgroundColor={colour.white}
            description={descriptionText}
          />
          <div className={classes.medicationsDiv}>
            <Banner title={"Medication"} showTooltip={false} />
            {drugs.map((drug, i) => (
              <DrugAccordionItem
                key={`item-${i}`}
                colour={drug.color}
                drug={drug}
                references={references}
                isDetailed={false}
              />
            ))}
          </div>
        </>
      ) : (
        <div className={classes.medicationsDiv}>
          <div className={classes.noDataFoundDiv}>{noDataFoundText}</div>
        </div>
      )}
    </>
  );
};

export default MedicationsOfInterestPanel;
