import { useRef, useState } from "react";
import { useStyles } from "./styles";
import { ReactComponent as ReportIcon } from "../../../assets/icons/close-report-icon.svg";
import Avatar from "../../_atoms/Avatar";
import PopUpMenu from "../../_atoms/PopUpMenu";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { config } from "../../../constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import { reduxState } from "../../globals/interfaces/commonInterfaces";
import { reSetUserData, setReportData } from "../../../actions";
import { useOutsideActionPerformer } from "../../globals/Utils";

const UserControl = ()=> {

    const classes =  useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userDetails = useSelector((state: reduxState) => state.appData.user);
    const displayName  = userDetails.signInUserSession?.idToken?.payload?.name;
    const displayEmail  = userDetails.signInUserSession?.idToken?.payload?.email;
    const hasGreeting = displayName || displayEmail;

    const [isExpanded, setIsExpanded] = useState(false)
    const toggleUserDetails = () => setIsExpanded(!isExpanded);

    const signOut = () => {
      Auth.signOut()
        .then(() => {
          navigate(config.Default_SignOut_Landing_Screen);
          dispatch(reSetUserData(null));
          dispatch(setReportData({}));
        })
        .catch((error) => {
          navigate(config.Default_UnAuth_Landing_Screen);
        });
    };

    const userControlContainerRef = useRef(null);
    useOutsideActionPerformer(userControlContainerRef,
      () => setIsExpanded(false),isExpanded);

    return (
      <div
        ref={userControlContainerRef}
        className={classes.userControlContainer}
      >
        <button
          className={classes.userButton}
          onClick={() => toggleUserDetails()}
        >
          <Avatar
            isIcon={!displayName}
            name={!!displayName ? displayName : ""}
            isDark={isExpanded}
          />
        </button>
        {isExpanded && (
          <div className={classes.userControlPopUp}>
            <PopUpMenu
              buttonText={"Close"}
              onClick={signOut}
              styles={{ right: 0 }}
            >
              <span className={classes.closeReport}>
                {hasGreeting && (
                  <span className={classes.closeReportGreeting}>
                    <span className={classes.reportTitleBoldText}>
                      Hi, {displayName || displayEmail}
                    </span>
                  </span>
                )}
                <span className={classes.closeReportLabel}>
                  <ReportIcon />
                  <span className={classes.reportTitleBoldText}>
                    Close Report
                  </span>
                </span>
              </span>
            </PopUpMenu>
          </div>
        )}
      </div>
    );
}

export default UserControl;