import { useStyles } from "./styles";
import { useScreenClass } from "react-grid-system";
import { useState, useRef } from "react";
import Tooltip from "../HelpTooltip/Tooltip";
import Reference from "../globals/interfaces/reference";
import { sanitizedData } from "../globals/Utils";

interface Props {
  references: Reference[];
  content: string;
}

const ReferenceTooltip = (props: Props) => {
  const { references, content } = props;
  const theme = useScreenClass();
  const styleProps = { theme: theme, compColour: "" };
  const classes = useStyles(styleProps);
  const tooltipWpr = useRef<HTMLDivElement>(null);

  const [showToolTip, setShowToolTip] = useState(false);
  const [toolTipPosition, setToolTipPosition] = useState({
    left: "0px",
    top: "0px",
    right: "auto",
  });
  const [toolTipDesc, setToolTipDesc] = useState("");

  const openTooltip = () => {
    setShowToolTip(showToolTip ? false : true);
  };

  const hideTooltip = () => {
    setShowToolTip(false);
  };

  const clickHandler = (e: any): void => {
    let target = e.target as HTMLInputElement;
    if (target.tagName.toLowerCase() === "sup") {
      const referenceId = Number(target.innerText);
      const referenceItem = references.find((x) => x.number === referenceId);
      setToolTipDesc((referenceItem && referenceItem.text) || "");

      let offsetX = 0;
      if(tooltipWpr.current){
        offsetX = tooltipWpr.current.getBoundingClientRect().x;
      }
      setToolTipPosition({
        left: e.clientX - offsetX - 245 + "px",
        top: e.target.offsetTop + 20 + "px",
        right: "auto",
      });
      openTooltip();
    } else {
      hideTooltip();
    }
  };

  return (
    <div style={{position: "relative"}} className={classes.toolTipwpr} ref={tooltipWpr}>
      <div
        onClick={(e) => clickHandler(e)}
        dangerouslySetInnerHTML={sanitizedData(content)}

      ></div>
      {showToolTip && (
        <Tooltip
          tooltipText={toolTipDesc}
          hideTooltip={hideTooltip}
          tooltipPosition="right"
          positionStyle={toolTipPosition}
        />
      )}
    </div>
  );
};

export default ReferenceTooltip;
