import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
  label: {
    display: "block",
    marginBottom: 10,
    fontSize: 14
  },
  textField: {
    width: 320,
    padding: 12,
    marginBottom: 20,
    fontSize: 14,
    border:"1px solid " + colour.blue,
    borderRadius: 5,
    height: 46,
    "&:focus": {
      borderColor: colour.darkBlue
    },
    "&:disabled": {
      background: colour.fadedGrey4
    }
  },
});
