import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,  useLocation } from "react-router-dom";
import { useStyles } from "./styles";
import Spinner from "../../components/Spinner/Spinner";
import { getCurrentUser } from "../../components/globals/useAuth";
import { setUserData } from "../../actions/index";
import {config} from "../../constants/Constants";
import { reduxState } from "../../components/globals/interfaces/commonInterfaces";
import SimpleContainer from "../../components/SimpleContainer";
import { getReportData, getReportId } from "../../components/globals/ReportsServices";

interface Props {
  setErrorType: Function;
  errorType: string;
}

const Welcome = (props: Props) => {
  const { setErrorType, errorType } = props;
  const classes = useStyles();
  const search = useLocation().search;
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [, setShowError] = useState(false);

  const userDetails = useSelector((state: reduxState) => state.appData.user);
  const name = userDetails?.signInUserSession?.idToken?.payload?.name ?? userDetails?.signInUserSession?.idToken?.payload?.preferred_username ?? userDetails?.username


  useEffect(() => {
    getCurrentUser()
      .then((data) => {
        if (data) {
          setLoading(true);
          dispatch(setUserData(data));

          let Id = getReportId(search);
          getReportData(Id, dispatch, setErrorType, setShowError, setLoading, config.Default_Auth_Landing_Screen, navigate);
        } else {
          dispatch(setUserData(null));
        }
      })
      .catch(() => {
        dispatch(setUserData(null));
      });
  }, [dispatch, navigate, search, errorType, setErrorType]
  );

  return (
      <SimpleContainer>
        <div>
          <p className={classes.welcome}>Welcome {typeof name == "string" ? name : ""}</p>
          <p className={errorType ? classes.error : classes.description}>
            {errorType
              ? "An error occurred while loading the report."
              : "Loading Report"}
          </p>
          {loading && <Spinner />}
        </div>
      </SimpleContainer>
  );
};

export default Welcome;
