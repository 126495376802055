import Linkify from "linkify-react";

interface Props {
  text: string | Element;
}
const UrlStringProcessor = (props: Props) => {
  const { text } = props;
  const options = {
    target: {
      url: "_blank",
    },
  };

  return (
    <Linkify options={options}>
      {text}
    </Linkify>
  );
};

export default UrlStringProcessor;
