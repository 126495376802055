import React, { useEffect, useState } from "react";
import MyDnaTextField from "../../components/Elements/MyDnaTextField";
import { SignInService, getReportData } from "./SignInServices";
import { useDispatch } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import myDnaLogo from "../../assets/logos/myDNA-white-bg-logo.png";
import ImageSrc from "../../components/ImageSrc";
import { useStyles } from "./styles";
import Spinner from "../../components/Spinner/Spinner";
import { getCurrentUser } from "../../components/globals/useAuth";
import { setUserData } from "../../actions/index";
import {config} from "../../constants/Constants";
import Separator from "../../components/Separator";
import { getReportId } from "../../components/globals/ReportsServices";

interface Props {
  setErrorType: Function;
  errorType: string;
}

const SignIn = (props: Props) => {
  const { setErrorType, errorType } = props;
  const classes = useStyles();
  const search = useLocation().search;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(false);
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    getCurrentUser()
      .then((data) => {
        if (data) {
          setLoading(true);
          dispatch(setUserData(data));
          let Id = getReportId(search);
          getReportData(Id, dispatch, setErrorType, setShowError, setLoading, config.Default_Auth_Landing_Screen, navigate);
        } else {
          dispatch(setUserData(null));
        }
      })
      .catch(() => {
        dispatch(setUserData(null));
      });
  }, [dispatch, navigate, search, setErrorType]);

  function signInUser(event: any) {
    event.preventDefault();
    setDisabled(true);
    setShowError(false);
    setErrorMessage(null);
    SignInService(
      email,
      password,
      setErrorMessage,
      dispatch,
      setDisabled,
      search,
      setLoading,
      setShowError,
      setErrorType,
      config.Default_Auth_Landing_Screen,
      navigate)
      .then(() => {
        setLoading(false);
      })
      .catch((error:any) => {
        setLoading(false);
      });
  }

  function signInUserWithNucleus(event: any) {
    event.preventDefault();
    navigate(config.GxG_Auth_Landing_Screen)
  }

  const getErrorMessage = () => {
    // ::TODO:: Error messages needs to be decided
    switch (errorType) {
      case "NETWORK":
        return "Something went wrong with connecting the server. Please try later";
      case "REPORT ID":
        return "Please Access the portal with valid Report ID.";
      case "INVALID DATA":
        return "The report data is invalid. Please contact support.";
      case "UNKNOWN":
        return "Something went wrong. Please try later";
      default:
        return "Something went wrong. Please try later";
    }
  };

  return (
    <div>
      <div className={classes.MainContainer}>
        <div className={classes.container}>
          <div className={classes.layout}>
            <div className={classes.loginContainer}>
              <div className={classes.alignCenter}>
                <ImageSrc
                  imageSrc={myDnaLogo}
                  imageAlt="myDnaLogo"
                  styles={{
                    width: 180,
                    height: 50,
                    marginLeft: 5,
                    marginBottom: 25,
                  }}
                />
              </div>

              <p className={classes.description}>
                Login with your myDNA Account
              </p>
              <form onSubmit={signInUser} autoComplete="off">
                <React.Fragment>
                  <MyDnaTextField
                    id="email"
                    name="email"
                    onChange={(e: any) => setEmail(e.target.value)}
                    type="text"
                    label="Email"
                    required={true}
                    value={email}
                    placeholder="Enter your email"
                  />
                  <MyDnaTextField
                    id="password"
                    name="password"
                    onChange={(e: any) => setPassword(e.target.value)}
                    type="password"
                    label="Password"
                    required={true}
                    value={password}
                    placeholder="Enter your password"
                  />
                  <div className={classes.gapBottom}>
                    <Link
                      to="/reports/forgotPassword"
                      className={classes.forgotPwdEmail}
                    >
                      Forgot password?
                    </Link>
                  </div>
                  {errorMessage && (
                    <div>
                      <p
                        className={classes.errorMessage}
                        dangerouslySetInnerHTML={{ __html: errorMessage }}
                      ></p>
                    </div>
                  )}

                  {
                    <p className={classes.errorMessage}>
                      {showError && getErrorMessage()}
                    </p>
                  }
                  <div className={classes.alignCenter}>
                    <button
                      type="submit"
                      className={classes.button}
                      disabled={disabled}
                    >
                      Login
                    </button>
                  </div>
                </React.Fragment>
              </form>
              <div className={classes.gapBottom}></div>
              <Separator />
              <div>
                <div className={classes.alignCenter}>
                  <br />
                  <button 
                    className={classes.buttonNucleus}
                    onClick={signInUserWithNucleus}
                    >
                      Login with Gene by Gene
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {loading && <Spinner />}
    </div>
  );
};

export default SignIn;
