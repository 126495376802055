import { useStyles } from "./styles";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/arrow-right.svg";

interface FloatingArrowProps {
  direction: string;
  onClick: ()=> void;
}

const FloatingArrow = ({ direction, onClick }: FloatingArrowProps) => {
  const classes = useStyles();
  return (
    <div className={classes.floatingArrow} onClick={onClick}>
      {direction === "Left" ? <ArrowLeftIcon /> : <ArrowRightIcon />}
    </div>
  );
};

export default FloatingArrow;
