import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
  wpr: {},
  references: {
    fontSize: 14,
    lineHeight: "20px",
    color: colour.fadedGrey,
    "& a": {
      color: colour.fadedGrey,
      textDecoration: "underline",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  description: {
    color: colour.fadedGrey,
    fontSize: 14,
    fontFamily: "'AvenirLTStd-Roman', sans-serif",
    lineHeight: "20px",
    marginBottom: 20
  }
});
