import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";

export const useStyles = createUseStyles({
  solidPill: {
    width: "fit-content",
    minHeight: "28px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: colour.blue,
    color: colour.white,
    padding: "0px 12px",
    borderRadius: 36,
  },
  outlinePill: {
    width: "fit-content",
    minHeight: "28px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: "transparent",
    color: colour.blue,
    padding: "0px 12px",
    borderRadius: 36,
    border: "1px solid " + colour.blue,
  },
  title: {
    margin: 0,
    padding: 0,
    paddingTop: 2,
    fontFamily: "AvenirLTStd-Heavy, sans-serif",
    textAlign: "center",
    fontSize: 12,
    textTransform: "uppercase",
    lineHeight: "100%",
  },
  description: {
    margin: 0,
    paddingTop: 0,
    fontFamily: "AvenirLTStd-Medium, sans-serif",
    textAlign: "center",
    fontSize: 10,
    lineHeight: "100%",
  },
});
