import { useState, useRef, useEffect } from "react";
import { useStyles } from "./styles";
import { useScreenClass } from "react-grid-system";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  setActiveDrug,
  setIsFilterInDetailedView,
  setSelectedSearchItem,
  setActiveMenuItem,
} from "../../../actions/index";

type AutoSuggestProps = {
  suggestions: any;
  placeholder: any;
  value: any;
  setInputValue: any;
};

const AutoSuggest = ({
  suggestions,
  placeholder,
  value,
  setInputValue,
}: AutoSuggestProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [activeItem, setActiveItem] = useState(-1);
  const screenClass: any = useScreenClass();
  const classes = useStyles(screenClass);
  const suggestionOptions:any = useRef(null);

  const getSuggestions = (e: any) => {
    const inputVal = e.target.value;
    if (inputVal.length < 3) {
      setOptions([]);
      return false;
    }

    const newOptions = suggestions.filter((x: any) =>
      x.name.toLowerCase().includes(inputVal.toLowerCase())
    );
    const sortedOptions = newOptions.sort((a: any, b: any) =>
      a.name.localeCompare(b.name)
    );
    setOptions(sortedOptions);
    handleClickOutside();
  };

  useEffect(() => {
    if (value === "") {
      setOptions([]);
    }
  }, [value]);

  const actionTracker = (e: any) => {
    let suggestionItems = [];
    if (suggestionOptions.current) {
      suggestionItems = suggestionOptions.current.querySelectorAll("li");
    }
    
    switch(true){
      case (e.keyCode === 40):
        activeItem < (suggestionItems.length -1) && setActiveItem(activeItem + 1);
        break;
      case (e.keyCode === 38):
         activeItem >= 0 && setActiveItem(activeItem - 1);
        break;
      case (e.keyCode === 13):
        e.preventDefault();
        if (suggestionItems.length > 0) suggestionItems[activeItem]?.click();
        setTimeout(() => {
          setOptions([]);
          setActiveItem(-1);
        }, 10); //Delaying to set state to finish the set value event on click
      break;
      }
  };

  const selectedOption = async (option: any) => {
    dispatch(setIsFilterInDetailedView(false));
    dispatch(setActiveDrug(option.name));
    setInputValue(option.name);
    option.uniqueId = new Date();
    dispatch(setSelectedSearchItem(option));
    setOptions([]);

    switch (option.category) {
      case "medicationCategories":
        await dispatch(
          setActiveMenuItem({
            index: option.menuIndex,
            identifier: option.category,
          })
        );
        navigate("/MedicationCategories");
        break;
      case "results":
      case "details":
        await dispatch(
          setActiveMenuItem({
            index: option.menuIndex,
            identifier: option.category,
          })
        );
        navigate("/TestResults");
        break;
    }
  };

  const checkIfClickedOutside = (e:any) => {
    // If the menu is open and the clicked target is not within the menu,
    // then close the menu
    if (
      options.length > 0 &&
      suggestionOptions.current &&
      !suggestionOptions.current.contains(e.target)
    ) {
      setOptions([]);
    }
  };

  const handleClickOutside = () => {
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  };

  return (
    <div className={classes.autocomplete} data-testid="autocomplete">
      <input
        id="search"
        type="text"
        name="search"
        autoComplete="off"
        spellCheck="false"
        placeholder={placeholder}
        onKeyUp={(e) => getSuggestions(e)}
        onKeyDown={(e) => actionTracker(e)}
        value={value}
        onChange={(e) => setInputValue(e.target.value)}
        className={classes.input}
      />
      {options.length > 0 && (
        <ul
          className={classes.autocompleteItems}
          ref={suggestionOptions}
          data-testid="suggestions"
        >
          {options.map((option: any, i) => (
            <li
              key={i}
              onClick={() => selectedOption(option)}
              className={activeItem === i ? classes.active : ""}
            >
              {`
              ${option.name} 
              ${option.description && `${option.description}`} 
              ${option.reportCategory ? `(${option.reportCategory})` : ""}
              `}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutoSuggest;
