import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";
import arrowIcon from "../../assets/icons/arrow-icon.svg";

export const useStyles = createUseStyles({
  historyWpr: {},
  table: {
    textAlign: "left",
    background: colour.grey10,
    borderRadius: 5,
    padding: "5px 12px",
    marginBottom: 4,
    width: "100%",
    "& th": {
      textAlign: "left",
      fontFamily: '"AvenirLTStd-Black", sans-serif',
      color: colour.fadedGrey,
      fontSize: 13,
    },
    "& tbody": {
      "& th": {
        fontSize: 12,
        color: colour.black,
      },
      "& td": {
        color: colour.black,
        fontFamily: '"AvenirLTStd-Roman", sans-serif',
        fontSize: 14,
      },
    },
  },
  col1: {
    width: "20%",
  },
  col2: {
    width: "20%",
  },
  col3: {
    width: "50%",
  },
  col4: {
    width: "10%",
  },

  hide: {
    display: "none",
  },
  briefText: {
    position: "relative",
  },
  fadedGradient: {
    position: "absolute",
    background:
      "linear-gradient(270deg, " +
      colour.grey10 +
      " 0%, rgba(255,255,255,0) 100%)",
    width: 80,
    height: 30,
    marginLeft: -60,
    top: -5,
  },
  actions: {
    textAlign: "right",
  },
  arrow: {
    width: 24,
    height: 24,
    display: "inline-block",
    right: 6,
    marginRight: 10,
    top: 2,
    cursor: "pointer",
    background: `url(${arrowIcon}) no-repeat center center`,
    transition: "ease-in .2s",
    "&.active": {
      transform: "rotate(90deg)",
    },
  },
});
