import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
  MainContainer: {
    maxWidth: 495,
    marginTop: 80,
    marginBottom: 20,
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    fontFamily: "Helvetica"
  },
  container: {
    background: "white",
    height: "100%",
  },
  layout: {
    height: "auto",
    display: "flex",
    minHeight: "100%",
    borderRadius: 16,
    boxShadow: "0 0 8px " + colour.fadedGrey5,
  },
  loginContainer: {
    margin: "80px auto",
    fontSize: 14,
  },
  welcome: {
    fontSize: 16
  },
  description: {
    marginBottom: 28
  },
   gapBottom: {
    marginBottom: 38,
  },
   alignCenter: {
    textAlign: "center"
  },
  
});
