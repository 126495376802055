import { useStyles } from "./styles";
import { useScreenClass } from "react-grid-system";
import { useRef, useState } from "react";
import Tooltip from "./index";
import Reference from "../../globals/interfaces/reference";
import { sanitizedData } from "../../globals/Utils";
import Bubble from "../Bubble/index";
import UrlStringProcessor from "../../globals/UrlStringProcessor";
import GetClickTargetCoords from './getClickTargetCoords';

interface Props {
  references: Reference[];
  content: string;
}

const ReferenceTooltip = (props: Props) => {
  const { references, content } = props;
  const theme = useScreenClass();
  const styleProps: any = { theme: theme, compColour: "" };
  const classes = useStyles(styleProps);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [showToolTip, setShowToolTip] = useState(false);
  const [toolTipPosition, setToolTipPosition] = useState({
    left: "0px",
    top: "0px",
    right: "auto",
  });

  const [tooltipOrientation, setTooltipOrientation] = useState<string>("right");

  const [toolTipTitle, setToolTipTitle] = useState("");
  const [toolTipDesc, setToolTipDesc] = useState("");

  const openTooltip = () => {
    setShowToolTip(showToolTip ? false : true);
  };

  const hideTooltip = () => {
    setShowToolTip(false);
  };

  const clickHandler = async (e: any) => {
    let target = e.target as HTMLInputElement;
    if (target.tagName.toLowerCase() === "sup") {
      setToolTipTitle(target.innerText);
      const referenceId = Number(target.innerText);
      if (references) {
        const referenceItem = references.find((x) => x.number === referenceId);
        setToolTipDesc((referenceItem && referenceItem.text) || "");
      }
      else{
        setToolTipDesc("N/A");
      }

      var {x, y} = GetClickTargetCoords(e);

      var topOffset = 23; // Move tooltip down 23 pixels to accomodate for pointer
      var leftOffset;

      // If tooltip is on left side of screen, open on right, otherwise open on left
      if (window.screen.width / 2 > e.clientX) {
        //tooltip target is in left side
        leftOffset = 25; // 25 from side (distance to pointer)
        setTooltipOrientation("left");
      } else {
        //tooltip target is in right side
        leftOffset = 230; // 255 is tooltip width (280px) - 25 (distance to pointer)
        // Tooltip orientation is 'right' by default
      }
      
      setToolTipPosition({
        left: x - leftOffset + "px",
        top: y + topOffset + "px",
        right: "auto" 
      });
      await openTooltip();
      tooltipRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      hideTooltip();
    }
  };

  return (
    <Bubble>
      <>
        <div
          className={classes.referenceContent}
          onClick={(e) => clickHandler(e)}
          dangerouslySetInnerHTML={sanitizedData(content)}
        />
        {showToolTip && (
          <Tooltip
            hideTooltip={hideTooltip}
            tooltipPosition={tooltipOrientation}
            positionStyle={toolTipPosition}
          >
            <div className={classes.tooltipDescriptionWrapper} ref={tooltipRef}>
              <div className={classes.referenceToolTipTitle}>
                {toolTipTitle}
              </div>
              <div className={classes.toolTipDescription}>
                <UrlStringProcessor text={toolTipDesc} />
              </div>
            </div>
          </Tooltip>
        )}
      </>
    </Bubble>
  );
};

export default ReferenceTooltip;
