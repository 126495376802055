import { useStyles } from "../../pages/About/styles";
import Banner from '../_atoms/Banner';
import { ReactComponent as WarningIcon } from "../../assets/icons/warning.svg";
import Title from '../_atoms/Title';

const Overview = () => {
  const classes = useStyles();
  return (
    <div className={classes.pageWrapper}>
      <div className={classes.descriptionTextWrapper} >
        <div className={classes.lineWrapper}>
          This report provides clinically relevant information on what the patient’s genetic results predict about their response to a number of medications covered by this report.
        </div>
        <div className={classes.lineWrapper}>
          The information concerns drug metabolism and plasma concentrations (drug exposure), as well as the potential for altered clinical effects.
        </div>
        <div className={classes.lineWrapper}>
          Based on the available information found in the published literature, each medication has been assigned a category according to the likely clinical significance of each gene-drug interaction. 
        </div>
        <div className={classes.lineWrapper}>
          <b>The five categories are:</b>
        </div>
      </div>
      <div className={classes.categorySectionWrapper}>
        <div className={classes.categoryWrapper}>
          <Banner isReportBanner={true} showTooltip={false} tooltipPosition="Right" colour="Red" title="Major prescribing considerations" />
          <div className={classes.lineWrapper}>A potentially significant effect on drug response is predicted. There may be guidelines or a drug label recommending consideration be given to a change in the dose, the medication type, or further monitoring in order to minimize the risk of the potential clinical issue noted.</div>
          <div className={classes.lineWrapper}>Of note, “Major” prescribing considerations do not always preclude the use of a specific medication or necessitate a dosage change if the drug is currently effective and well tolerated, this will be dependent on the individual gene-drug interaction and the clinical circumstances.</div>
        </div>

        <div className={classes.categoryWrapper}>
          <Banner isReportBanner={true} showTooltip={false} tooltipPosition="Right" colour="Orange" title="Minor prescribing considerations" />
          <div className={classes.lineWrapper}>Altered drug response is possible, but either the clinical significance is thought to be minor or there is currently limited evidence available. Consider monitoring for any potential clinical effects annotated in this report. There are generally no specific recommendations to alter dosage or medication according to current guidelines.</div>
        </div>

        <div className={classes.categoryWrapper}>
          <Banner isReportBanner={true} showTooltip={false} tooltipPosition="Right" colour="Green" title="Usual prescribing considerations" />
          <div className={classes.lineWrapper}>Genetic results are not predicted to have a significant effect on drug response, based on the literature currently available, and there are no additional prescribing considerations. Other factors may still influence drug response and therefore usual monitoring for adverse effects and efficacy still applies.</div>
        </div>
        
        <div className={classes.categoryWrapper}>
          <Banner isReportBanner={true} showTooltip={false} tooltipPosition="Right" colour="Grey" title="No pharmacogenomic prescribing considerations" />
          <div className={classes.lineWrapper}>These medications do not have significant gene-drug interactions identified and standard prescribing considerations apply.</div>
        </div>

        <div className={classes.categoryWrapper}>
          <Banner isReportBanner={true} showTooltip={false} tooltipPosition="Right" colour="Red" hasWarningSign title="Consider alternative medication" />
          <div className={classes.lineWrapper}>
            <span>
              Medications which have a prescribing consideration to use an alternative medication will be annotated with this symbol{" "}
            </span>
            <WarningIcon data-ref="warningIcon" />
            <span>
              .
            </span>
          </div>
        </div>
      </div>
      <Title>Pharmacogenomic Guidelines</Title>
      <div className={classes.guidelinesWrapper}>
        <div className={classes.lineWrapper}>
          For many medications covered in this report, evidence-based guidelines
          and drug label information are available and where relevant are
          referenced in this report.{" "}
        </div>
        <div className={classes.lineWrapper}>Key practice guidelines include:</div>
        <ol className={classes.listItem}>
          <li>Clinical Pharmacogenetics Implementation Consortium (CPIC). </li>
          <li>
            The Royal Dutch Pharmacists Association - Pharmacogenetics Working
            Group (DPWG).
          </li>
          <li>
            The FDA Table of Pharmacogenetic Associations and drug label
            information.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Overview;
