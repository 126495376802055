import { Auth } from "aws-amplify";
import { validateEmail } from "../../components/globals/Utils";

export function forgotPassword(
  email: string,
  setErrorMessage: Function,
  dispatch: Function,
  navigate: any,
  setSuccessMessage: Function,
  setShowResetPwd: Function,
  setLoading: Function
) {
  setErrorMessage(null);
  setSuccessMessage(false);
  if (!validateEmail(email)) {
    setErrorMessage("Please check your email");
  } else {
    Auth.forgotPassword(email)
      .then((output) => {
        setErrorMessage(null);
        setSuccessMessage(true);
        setShowResetPwd(true);
      })
      .catch((error) => {
        if (error?.code === "UserNotFoundException") {
          setErrorMessage("User Not found");
        } else {
          setErrorMessage(error?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
}

export function resetPassword(
  emailAddress: string,
  code: string,
  password: string,
  setErrorMessage: Function,
  setSuccessMsg: Function,
  setLoading: Function
) {
  Auth.forgotPasswordSubmit(emailAddress, code, password)
    .then((data) => {
      setSuccessMsg(true);
    })
    .catch((err) => {
      console.log(err);
      setErrorMessage([
        err.message || "Something went wrong please try again later",
      ]);
    })
    .finally(() => {
      setLoading(false);
    });
}
