import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  categoryContent:{
    marginBottom: 40,
  },
  quadrantFilter: {
    margin: "20px 0",
    display: "inline-block",
  },
  quadrants: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
  },
  quadrantItem: {
    flexGrow: 1,
    paddingRight: 10,
    paddingBottom: 10,
  },
  quadrantItemCollapsed: {
    width: 500,
  },
  quadrantItemExpanded: {
    width: "100%",
  },
});