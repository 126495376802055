import React, { useState } from "react";
import { useStyles } from "./styles";
import { getShortenText } from "../globals/Utils";
import { config } from "../../constants/Constants";

interface Props {
  description: string;
  col1: string;
  col2: string;
}

const HistoryDescription = (props: Props) => {
  const { description, col1, col2 } = props;
  const classes = useStyles();
  const [expandView, setExpandView] = useState(false);

  return (
    <tr>
      <td>{col1}</td>
      <td>{col2}</td>
      <td>
        <div>
          {!expandView && (
            <span className={classes.briefText}>
              <span
                dangerouslySetInnerHTML={{
                  __html: getShortenText(
                    description,
                    config.ShortenTextCharLength
                  ),
                }}
              ></span>
              <span className={classes.fadedGradient}></span>
            </span>
          )}
          {expandView && description}
        </div>
      </td>
      <td className={classes.actions}>
        <span
          className={classes.arrow}
          style={{
            transform: expandView ? "rotate(180deg)" : "rotate(0deg)",
          }}
          onClick={() => setExpandView(!expandView)}
        ></span>
      </td>
    </tr>
  );
};

export default HistoryDescription;
