import { useStyles } from "./styles";
import { useScreenClass } from "react-grid-system";
import Reference from "../../globals/interfaces/reference";
import DrugAccordionItem from "./DrugAccordionItem";
import { Drugs } from "../../globals/interfaces/PractitionerData";

export interface Props {
  colour: string;
  drugs: Drugs[];
  references: Reference[];
  size?: string;
  hasWarningSign?: boolean;
  isDetailed: boolean;
}

const DrugAccordionList = ({
  colour,
  drugs,
  references,
  size = "Large",
  isDetailed,
}: Props) => {
  const theme = useScreenClass();
  const styleProps = { theme: theme, compColour: colour, size: size };
  const classes = useStyles(styleProps);

  return (
    <div data-testid="accordion-list">
      {drugs.length > 0 ? (
        drugs.map((drug, i) => (
          <DrugAccordionItem
            key={`item-${i}-${isDetailed}`}
            colour={colour}
            drug={drug}
            references={references}
            size={size}
            isDetailed={isDetailed}
          />
        ))
      ) : (
        <div className={classes.container}>
          <div className={classes.content} style={{ cursor: "default" }}>
            <p className={classes.label}>None</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default DrugAccordionList;
