import { useStyles } from "./styles";
import TextField from "../TextField";
import Menu from "../_molecules/Menu";
import { useScreenClass, Visible } from "react-grid-system";
import HelpButton from "../HelpButton";
import { colour } from "../../constants/Colours";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveDrug,
  setIsFilterInDetailedView,
  setSelectedSearchItem,
} from "../../actions/index";
import MenuTitle from "../_atoms/MenuTitle";
import { MedicationCategory } from "../globals/interfaces/PractitionerData";
import { ReportBranding } from "../globals/interfaces/reportBranding";
import EvaluateCobranding from "./evaluateCobranding";
import { reduxState } from "../globals/interfaces/commonInterfaces";
import { convertDateFormatWithMonthShortName } from "../globals/Utils";
import { isFullMedsReport } from "../../utils/reportTypeUtils";

interface LeftContentContainerPropsType {
  toggleMenu: any;
  menuBarStatus: any;
  patient: any;
  pgxReportData: any;
}

const LeftContentContainer = (props: LeftContentContainerPropsType) => {
  const { toggleMenu, menuBarStatus, patient, pgxReportData } = props;
  const screenClass = useScreenClass();
  const classes = useStyles();
  const dispatch = useDispatch();

  const setMenuItemPresetValues = () => {
    dispatch(setIsFilterInDetailedView(false));
    dispatch(setSelectedSearchItem({}));
    dispatch(setActiveDrug(null));
  };
  const selectedMenuItem = useSelector(
    (state: reduxState) => state.appData.activeMenuItem
  );

  var medicationMenuItems = [
    {
      name: "Medications of Interest",
      route: "/MedicationsOfInterest",
      onClick: () => setMenuItemPresetValues(),
    },
  ];

  var medicationCategoryMenuItems = pgxReportData.reportCategories.map(
    (category: MedicationCategory, index: number) => ({
      name: category.reportCategoryTitle,
      route: "/MedicationCategories",
      onClick: () => setMenuItemPresetValues(),
    })
  );

  var resultsMenuItems = [
    {
      name: "Test Results",
      route: "/TestResults",
      onClick: () => setMenuItemPresetValues(),
    },
  ];

  var detailsMenuItems = [
    {
      name: "Test Details",
      route: "/TestDetails",
      onClick: () => setMenuItemPresetValues(),
    },
    {
      name: "About this report",
      route: "/AboutThisReport",
      onClick: () => setMenuItemPresetValues(),
    },
    {
      name: "References",
      route: "/References",
      onClick: () => setMenuItemPresetValues(),
    },
  ];

  var whatsNewMenuItems = [
    {
      name: "Notifications",
      route: "/Notifications",
      onClick: () => setMenuItemPresetValues(),
    },
  ];

  const renderResponsiveSideBarStyle = () => {
    switch (screenClass) {
      case "xs":
      case "sm":
      case "md":
        return menuBarStatus ? classes.showSideBar : classes.hideSideBar;
      default:
        return "";
    }
  };

  const reportInformation = pgxReportData.reportInformation;
  const cobranding: ReportBranding =
    pgxReportData.reportInformation.reportBranding;

  const containsMedicationsOfInterest = () => {
    return (
      pgxReportData.medicationsOfInterest &&
      pgxReportData.medicationsOfInterest.length > 0
    );
  };

  const renderMedicationsSection = () => {
    if (
      !isFullMedsReport(pgxReportData.reportType) ||
      !containsMedicationsOfInterest()
    )
      return null;

    return (
      <div data-testid="medications">
        <MenuTitle>
          <span>Medications</span>
        </MenuTitle>
        <Menu
          identifier="medications"
          items={medicationMenuItems}
          selectedMenuItem={selectedMenuItem}
        />
      </div>
    );
  };

  return (
    <div
      data-testid="leftContentContainer"
      className={`${classes.sideBarStyle} ${renderResponsiveSideBarStyle()}`}
    >
      <div>
        <div className={classes.sidebarHeaderWrapper}>
          {EvaluateCobranding(cobranding)}
        </div>
        <Visible md sm xs>
          <button
            className={classes.closeMenu}
            onClick={() => toggleMenu(false)}
          ></button>
        </Visible>
        <div className={classes.sidebarInfoBox}>
          <TextField
            name={pgxReportData.reportTitle}
            nameTestId="report-title"
            nameStyle={{
              fontSize: 18,
              marginBottom: "0.5rem",
              marginTop: "1.2rem",
            }}
          />
          <TextField
            name={patient.fullName}
            nameStyle={{
              fontSize: 12,
              textTransform: "uppercase",
              color: colour.grey1,
              letterSpacing: "0.08em",
            }}
          />
          <TextField
            name={"D.O.B: " + convertDateFormatWithMonthShortName(patient.dob)}
            nameStyle={{
              fontSize: 12,
              textTransform: "uppercase",
              color: colour.grey1,
              letterSpacing: "0.08em",
            }}
          />
          <TextField
            name={"LAB REF: " + reportInformation.labRef}
            nameStyle={{
              fontSize: 12,
              textTransform: "uppercase",
              color: colour.grey1,
              letterSpacing: "0.08em",
              marginBottom: "1.2rem",
            }}
          />
        </div>

        {renderMedicationsSection()}

        <MenuTitle>
          <span>Medication Categories</span>
        </MenuTitle>
        <Menu
          identifier="medicationCategories"
          items={medicationCategoryMenuItems}
          selectedMenuItem={selectedMenuItem}
        />

        <MenuTitle>
          <span>Results</span>
        </MenuTitle>
        <Menu
          identifier="results"
          items={resultsMenuItems}
          selectedMenuItem={selectedMenuItem}
        />

        <MenuTitle>
          <span>Details</span>
        </MenuTitle>
        <Menu
          identifier="details"
          items={detailsMenuItems}
          selectedMenuItem={selectedMenuItem}
        />
        <MenuTitle>
          <span>What's New</span>
        </MenuTitle>
        <Menu
          identifier="whatsNew"
          items={whatsNewMenuItems}
          selectedMenuItem={selectedMenuItem}
          icon="bell"
        />
      </div>

      <HelpButton patient={patient} reportInformation={reportInformation} />
    </div>
  );
};

export default LeftContentContainer;
