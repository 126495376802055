import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  referencesContainer: {
    overflow: "overlay",
    scrollBehavior: "smooth",
    height: "100vh",
    paddingBottom: 140,
  },
  referencesContent: {
    margin: "20px",
    maxWidth: "720px",
  },
});
