import DOMPurify from "dompurify";
import { colour } from "../../constants/Colours";
import { HelpText } from "../../constants/Constants";
import { API, Auth } from 'aws-amplify';
import {getReport, getDocumentUrl } from '../../graphql/queries';
import { ReportData, Categories, Quadrants, Drugs, Genotype } from "./interfaces/PractitionerData";
import { updateSearchData } from "../../actions/index";
import {SearchDataObject} from "./interfaces/commonInterfaces";
import { useEffect } from "react";
const luxon = require("luxon");

export const colourMapper = (colorName: string) => {
  switch (colorName) {
    case "Red":
      return colour.red;
    case "Green":
      return colour.green;
    case "Blue":
      return colour.blue;
    case "Orange":
      return colour.orange;
    case "Gray":
      return colour.grey;
    case "White":
      return colour.white1;
    default:
      return colour.white1;
  }
};

export const getHelpText = (header: string) => {
  let helpText = "";
  if (header.includes("major")) helpText = HelpText.major;
  else if (header.includes("minor")) helpText = HelpText.minor;
  else if (header.includes("usual")) helpText = HelpText.usual;
  else if (header.includes("no pharmacogenomic"))
    helpText = HelpText.noPrescribing;
  return helpText;
};

export const getShortenText = (text: string, maxCharLength:number) => {
  return text.substring(0, maxCharLength);
};

export const convertDateFormatWithMonthShortName = (ISODate: Date) => {
  const dt = luxon.DateTime.fromISO(ISODate);
  return dt.toFormat('dd-MMM-yyyy')
};

export const getModelWidth = (screenSize:string, position: {left: string; top: string;}) => {
  return window.innerWidth - Number(position?.left) - 40;
}

export const getModelMargin = (screenSize:string, position: {left: string; top: string;}) => {
  switch (screenSize) {
    case "xxl":
      return "translate("+ position.left +"," + position.top + ")";
    case "xl":
      return "translate("+ position.left +"," + position.top + ")";
    case "lg":
      return "translate("+ position.left +"," + position.top + ")";
    case "md":
      return "translate(5%, 10%)";
    default:
      return "translate(0, 10%)";
  }
}

export const sanitizedData = (htmlText: string) => ({
  __html: DOMPurify.sanitize(htmlText),
});


export const FetchGetReport= async (referenceNumber: string)=>{
  const idToken = await getIdToken();
  return await API.graphql({ query: getReport, variables: {request: {referenceNumber: referenceNumber}}, authMode: "AMAZON_COGNITO_USER_POOLS", authToken: idToken},);
}

export const FetchDocumentUrl= async (referenceNumber: string, reportType: string)=>{
  return await API.graphql({ query: getDocumentUrl, variables: {referenceNumber: referenceNumber, reportType: reportType}, authMode: "AMAZON_COGNITO_USER_POOLS"});
}

export const getIdToken = async()=> {
  const curSesh = await Auth.currentSession();
  const token = curSesh.getIdToken();
  return token.getJwtToken();
}

  export const formatAndDispatchSearchData = (reportData: ReportData, dispatch:Function) => {
    let searchData:SearchDataObject[] = [];
      reportData.reportCategories &&
        reportData.reportCategories.forEach((reportCategory: any, index: number) => {
          reportCategory.categories.forEach(
            (category: Categories, i: number) => {
              category.quadrants.forEach((quadrant: Quadrants) => {
                quadrant.drugs.forEach((drug: Drugs) => {
                  const drugShortObj = {
                    name: drug.name,
                    activeTab: i,
                    menuIndex: index,
                    category: "medicationCategories",
                    reportCategory: reportCategory.reportCategoryTitle,
                    description: drug.subCategory,
                  };
                  searchData.push(drugShortObj);
                });
              });
            });
          });

      reportData.orderedGenotypes.forEach((orderedGenotype:Genotype) => {
        const orderedGenotypeShortObj = {
          name: orderedGenotype.geneName,
          category: "results",
          activeTab: 0,
          menuIndex: 0,
          description: orderedGenotype.rsNumber,
        };
        searchData.push(orderedGenotypeShortObj);
      });

      reportData.emergingGenotypes.forEach((emergingGenotype:Genotype) => {
        const emergingGenotypeShortObj = {
          name: emergingGenotype.geneName,
          category: "details",
          activeTab: 1,
          menuIndex: 0,
          description: emergingGenotype.rsNumber,
        };
        searchData.push(emergingGenotypeShortObj);
      });
      dispatch(updateSearchData(searchData));
    }

    export const validateEmail = (email:string) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
  }

  export const validatePassword = (password:string) => {
    const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{5,}$/;
    return re.test(password);
}

export const useOutsideActionPerformer = (
  ref: React.RefObject<any>,
  callback: Function,
  constraint: boolean
) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target) && constraint) {
        callback();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback, constraint]);
};

export const fixMissingGrayQuadrant = (data: any) => {
  return (data.reportCategories.map((reportCategory: any) => 
    reportCategory.categories.map((category: any) => 
      (category.quadrants.length < 4 && !category.quadrants.some((q: any) => q.color === "Gray")) &&
        category.quadrants.push({
          color: "Gray",
          header: "no pharmacogenomic prescribing considerations",
          drugs: [],
        })
      )
    ));
  }

  export const removeInvalidCategories = (data: any) => {
    data.reportCategories.map(
      (reportCategory: any) =>
        (reportCategory.categories = reportCategory.categories.filter(
          (category: any) =>
            category.quadrants.find((q: any) => q.color === "Red").drugs.length > 0 ||
            category.quadrants.find((q: any) => q.color === "Orange").drugs.length > 0 ||
            category.quadrants.find((q: any) => q.color === "Green").drugs.length > 0
        ))
    );
  };

  export const sortGrayQuadrantDescendingly = (data: any) => {
    return data.reportCategories.map((reportCategory: any) =>
      reportCategory.categories.map((category: any) =>
         category.quadrants
           .find((q: any) => q.color === "Gray")
           .drugs.sort((a: any, b: any) =>
             a.name > b.name ? -1 : a.name < b.name1 ? 1 : 0
           )
      )
    );
  };