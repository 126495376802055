import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  
  title: {
    fontSize: 21,
    lineHeight: '27px',
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    fontWeight: "normal",
    marginBottom: 16,
    margin: 0
  },
  
  tabsList: {
    display: "none",
    listStyle: 'none',
    margin: '12px 0',
    padding: 0,
    '& li': {
      padding: "4px 10px",
      marginRight: 50,
      fontSize: '1rem',
      lineHeight: '1.3rem',
      position: 'relative',
      cursor: 'pointer',
      fontFamily: "'AvenirLTStd-Roman', sans-serif",
      '&:after': {
        position: 'absolute',
        height: 2,
        width: '106%',
        display: 'inline-block',
        content: "''",
        left: 0,
        bottom: '-3px',
      },
      '&.react-tabs__tab--selected': {
        fontFamily: "'AvenirLTStd-Heavy', sans-serif",
        '&:after': {
          background: '#30B5E8'
        },
      }
    },
  },
});
