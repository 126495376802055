export const desktopFontsSize = {
    xs: "10px",
    xsm: "11px",
    xsmd: "12px",
    sm: "13px",
    smd: "14px",
    md: "15px",
    lg: "18px",
    xlg: "20px",
}

export const tabletFontsSize = {
    xs: "8px",
    xsm: "9px",
    sm: "10px",
    md: "13px",
    lg: "15px",
    xlg: "18px",
}

export const mobileFontsSize = {
    sm: "9px",
    md: "11px",
    lg: "13px",
    xlg: "15px",
}