import { useStyles } from "./styles";
import { useScreenClass } from "react-grid-system";
import { NavLink } from "react-router-dom";
import Tooltip from "../Tooltip";
import { useEffect, useState } from "react";
import GetClickTargetCoords from '../Tooltip/getClickTargetCoords';
import {ReactComponent as BellIcon}  from "../../../assets/icons/bell-icon.svg";

type MenuItemProps = {
  name: string;
  index: number;
  route: string;
  isActive: boolean;
  icon?: string;
  onClick?: (index: number, name: string) => void;
};

const NameTruncateLength = 35;

const MenuItem = ({ name, index, isActive, route, icon, onClick }: MenuItemProps) => {
  const screenClass: any = useScreenClass();
  const classes = useStyles(screenClass);
  const [shouldTooltipShow, setShouldTooltipShow] = useState<boolean>(false);
  const [isNameTruncated, setIsNameTruncated] = useState<boolean>(false);
  const [truncatedName, setTruncatedName] = useState<string>(name);

  const iconResolver = (icon?: string) => {
    switch (icon) {
      case "bell":
        return <BellIcon className={classes.icon} />
      default:
        return <></>
    }
  }

  const [tooltipPosition, setTooltipPosition] = useState({
    top: "0px",
    left: "0px",
    right: "auto"
  })

  useEffect(() => {
    if (name.length > NameTruncateLength) {
      setIsNameTruncated(true);
      setTruncatedName(name.substring(0, NameTruncateLength - 3) + "...");
    }
  }, [name])

  const invokeOnClick = (e: any) => {
    if (onClick) {
      onClick(index, name);
    }
  }

  const evaluateTooltipPosition = (event: any) => {
    var {x, y} = GetClickTargetCoords(event);

    var xOffset = 30;
    var yOffset = -65;

    setTooltipPosition({
      top: y + yOffset + "px",
      left: x + xOffset + "px",
      right: "auto"
    });
  }

  const handleMouseOver = (event: any) => {
    setShouldTooltipShow(true);
    evaluateTooltipPosition(event);
  };

  const handleMouseOut = () => {
    setShouldTooltipShow(false);
  };

  return (
    <>
      <div 
        onMouseOver={handleMouseOver} 
        onMouseOut={handleMouseOut}
      >
        <NavLink
          end
          to={route}
          className={isActive ? classes.listItemActive : classes.listItem}
          onClick={invokeOnClick}
          data-testid={`menu-item-${index}`}
        >
          {iconResolver(icon)}<span>{truncatedName}</span>
        </NavLink>
      </div>
      {(shouldTooltipShow && isNameTruncated) &&
      <Tooltip
        tooltipPosition="top"
        tooltipStyle="dark"
        positionStyle={tooltipPosition}
        hideTooltip={() => setShouldTooltipShow(false)}
        isCompact
      >
        <div className={classes.tooltipText}>
          {name}
        </div>
      </Tooltip>}
    </>
  );
};

export default MenuItem;
