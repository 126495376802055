import { useStyles } from "./styles";
import { Row, Col } from "react-grid-system";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useState } from "react";
import { config } from "../../constants/Constants";

import References from "../../components/References";
import HistoryLog from "../../components/HistoryLog";

const MoreInformation = (props: any) => {
  const { data } = props;
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(1);

  return (
    <Row>
      <Col sm={12}>
        <Tabs defaultIndex={activeTab} onSelect={() => {}}>
          <TabList className={classes.tabsList} data-testid="TabList">
            {config.showHistoryLog && (
              <Tab onClick={() => setActiveTab(0)}>History Log</Tab>
            )}
            <Tab onClick={() => setActiveTab(1)}>References</Tab>
          </TabList>

          {config.showHistoryLog && (
            <TabPanel className="tabpanel" data-testid="TabPanel">
              <HistoryLog />
            </TabPanel>
          )}
          <TabPanel className="tabpanel" data-testid="TabPanel">
            <h4 className={classes.title}>References</h4>
            <References references={data.references} />
          </TabPanel>
        </Tabs>
      </Col>
    </Row>
  );
};

export default MoreInformation;
