import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";
import { colourMapper } from "../globals/Utils";

export const useStyles = createUseStyles({
  container: (props: { theme: string; colour: string }) => ({
    minHeight: 35,
    width: "100%",
    background: colourMapper(props.colour),
    borderRadius: 5,
    display: "flex",
  }),
  content: (props: {theme:string;}) => ({
    color: "white",
    padding: "7px",
    boxSizing: "border-box",
    marginLeft: "5px",
    fontSize: ["xl", "xxl"].includes(props.theme)
    ? 14
    : 10,
    lineHeight: "20px",
    textTransform: "uppercase",
    fontFamily: "AvenirLTStd-Black, sans-serif",
  }),
  expandAll: {
    marginLeft: "auto",
    marginRight: 16,
    marginTop: 9,
    fontSize: 12,
    color: colour.white,
    textDecoration: "underline",
    cursor: "pointer",
    fontFamily: "AvenirLTStd-Roman, sans-serif",
  },
});
