import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
  wpr: {
    maxWidth: 495,
    background: colour.white,
    margin: "4rem auto",
    boxShadow: "0 0 8px " + colour.grey,
    borderRadius: 12,
    "& img": {
      maxHeight: 294,
      objectFit: "fill"
    }
  },
  contentSec: {
    padding: "0 18px 18px",
  },
  header: {
    fontSize: "1.375rem",
    fontWeight: "normal",
    fontFamily: "AvenirLTStd-Heavy",
    margin: "18px 0"
  },
  description: {
    fontSize: "1.125rem",
    fontFamily: "AvenirLTStd-Roman",
    lineHeight: "27px"
  }
});
