import { useStyles } from "./styles";
import { useScreenClass } from "react-grid-system";
import { useState, useRef, useEffect } from "react";
import { sanitizedData } from "../../globals/Utils";
interface Props {
  colour: string;
  heading: string;
  children: JSX.Element;
  isDetailed: boolean;
  isExpanded?: boolean;
  size: string;
}

const AccordionItem = ({
  colour,
  heading,
  children,
  isDetailed,
  isExpanded = false,
  size = "Small",
}: Props) => {
  const theme = useScreenClass();
  const styleProps = {
    theme: theme,
    compColour: colour,
    size: isDetailed ? "Large" : size,
  };
  const classes = useStyles(styleProps);
  const AccordionWprElement = useRef<HTMLDivElement>(null);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(isExpanded);
  const isExpandable = colour !== "Gray";

  useEffect(() => {
    setIsAccordionExpanded(isExpanded);
  }, [isExpanded]);

  return (
    <div
      className={`${classes.container} ${
        isExpanded && classes.expandedContainer
      }`}
      ref={AccordionWprElement}
      data-testid="accordion-item"
    >
      {!isDetailed && (
        <div
          data-testid="accordion-item-summary"
          className={classes.content}
          style={{ cursor: isExpandable ? "pointer" : "auto" }}
          onClick={() =>
            isExpandable && setIsAccordionExpanded(!isAccordionExpanded)
          }
        >
          <div
            className={`${
              isAccordionExpanded ? classes.activeTitle : classes.inactiveTitle
            } ${classes.summaryView}`}
          >
            <div
              data-testid="accordion-item-collapse"
              dangerouslySetInnerHTML={sanitizedData(heading)}
            />
          </div>
          {isExpandable && (
            <span
              className={classes.arrow}
              style={{
                transform: isAccordionExpanded
                  ? "rotate(180deg)"
                  : "rotate(0deg)",
              }}
            />
          )}
        </div>
      )}
      {isExpandable ? (
        <div
          data-testid="accordion-item-expanded"
          className={`
            ${isAccordionExpanded ? classes.show : classes.hide} 
            ${isDetailed && classes.detailedView}
          `}
        >
          {isDetailed && (
            <div
              data-testid="accordion-item-detailed"
              dangerouslySetInnerHTML={sanitizedData(heading)}
            />
          )}
          {children}
        </div>
      ) : (
        <div
          data-testid="accordion-item-expanded"
          className={`
            ${isDetailed && classes.grayDetailedView}
          `}
        >
          {isDetailed && (
            <div
              data-testid="accordion-item-detailed"
              dangerouslySetInnerHTML={sanitizedData(heading)}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
