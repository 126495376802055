import React from "react";
import { useStyles } from "./styles";
import Reference from "../../globals/interfaces/reference";
import UrlStringProcessor from "../../globals/UrlStringProcessor";

interface referencesProps {
  references: Array<Reference>;
}

const ReferencesList = ({ references }: referencesProps) => {
  const classes = useStyles();
  return (
    <div data-testid="references">
      <ol className={classes.referencesList}>
        {references.map((reference) => (
          <li key={reference.key} className={classes.references}>
            <UrlStringProcessor text={reference.text} />
          </li>
        ))}
      </ol>
    </div>
  );
};

export default ReferencesList;
