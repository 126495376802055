import { useStyles } from "./styles";
import { Row, Col } from "react-grid-system";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useState, useEffect, useRef } from "react";
import PrescribingConsideration from "../../components/PrescribingConsiderations";
import ClinicalConsiderationsIntro from "../../components/ClinicalConsiderationsIntro";
import Reference from "../../components/globals/interfaces/reference";
import { useSelector, useDispatch  } from "react-redux";
import {reduxState} from "../../components/globals/interfaces/commonInterfaces";
import {setSelectedSearchItem, setActiveDrug} from "../../actions";

const TablePanelData = (props: { category: any; references: Array<Reference> }) => {
  const classes = useStyles();
  const { category, references } = props;
  const tooltipPosition = (index:number) => index % 2 === 0 ? "left" : "right";
  const quadrantWrapperElement = useRef(null);
  return (
    <div  ref={quadrantWrapperElement}>
      <ClinicalConsiderationsIntro
        name={category.name}
        relevantGenes={category.relevantGenes}
        quadrantIntro={category.quadrantIntro}
      />
      <Row>
        {category.quadrants ? (
          category.quadrants.map((quadrant: any, i: number) => (
            <Col sm={12} lg={6} key={i} className={classes.quadrantWpr}>
              <PrescribingConsideration
                title={quadrant.header}
                colour={quadrant.color}
                drugs={quadrant.drugs}
                tooltipPosition={tooltipPosition(i)}
                references={references}
                wrapperRef={quadrantWrapperElement}
              />
            </Col>
          ))
        ) : (
          <span>none</span>
        )}
      </Row>
    </div>
  );
};

const ClinicalConsiderations = (props: any) => {
  const { categories, data } = props;
  const selectedSearchItem = useSelector((state:reduxState) => state.appData.selectedSearchItem);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(0);


  useEffect(()=>{
    if(Object.keys(selectedSearchItem).length > 0){
      setActiveTab(selectedSearchItem.activeTab);
      setTimeout(()=>{
        dispatch(setSelectedSearchItem({}))
        dispatch(setActiveDrug(null));
      }, 100) // resetting with time-delay to re-perform the same search
    }

  }, [selectedSearchItem.uniqueId, dispatch, selectedSearchItem])

  const onSelectTab = (e:any) => {
    
  }
  return (
    <Row>
      <Col sm={12} className={classes.staticPosition}>
        <Tabs selectedIndex={activeTab} onSelect={(e) => onSelectTab(e)}>
          <TabList className={classes.tabsList} data-testid="TabList">
            {categories.map((category: any, index: number) => (
              <Tab key={index} onClick={() => setActiveTab(index)}>
                {category.name}
              </Tab>
            ))}
          </TabList>
          {categories.map((category: any, index: number) => (
            <TabPanel key={index} className="tabpanel" data-testid="TabPanel">
              <TablePanelData category={category} references={data.references} />
            </TabPanel>
          ))}
        </Tabs>
      </Col>
    </Row>
  );
};

export default ClinicalConsiderations;
