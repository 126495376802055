import Auth from "@aws-amplify/auth";
import { CognitoUser } from "amazon-cognito-identity-js";
export interface UseAuthHookResponse {
  currentUser: CognitoUser | null;
}

const getCurrentUser = async (): Promise<CognitoUser | null> => {
  try {
    return await Auth.currentAuthenticatedUser();
  } catch {
    // currentAuthenticatedUser throws an Error if not signed in
    return null;
  }
};

export { getCurrentUser };