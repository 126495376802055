import { useState } from "react";
import { useStyles } from "./styles";
import Tooltip from "./index"
import GetClickTargetCoords from './getClickTargetCoords';

interface HelpTooltipProps {
  tooltipText: string;
  openToolTip: boolean;
  tooltipPosition: string;
}

const HelpTooltip = ({
  tooltipText,
  openToolTip,
  tooltipPosition,
}: HelpTooltipProps) => {
  const [showToolTip, setShowToolTip] = useState(openToolTip);
  const [toolTipPosition, setToolTipPosition] = useState({
    left: "0px",
    top: "0px",
    right: "auto",
  })

  const openTooltip = () => {
    setShowToolTip(!showToolTip);
  };

  const handleOnClick = (event: any) => {
    var {x, y} = GetClickTargetCoords(event);

    var xOffset = 230; // 255 - 12px (half width of icon) - 13
    var yOffset = 35; // Move tooltip down 35 pixels to accomodate for pointer 
    
    setToolTipPosition({
      left: x - xOffset + "px",
      top: y + yOffset + "px",
      right: "auto" 
    });

    openTooltip();
  }

  const hideTooltip = () => {
    setShowToolTip(false);
  };

  const classes = useStyles();

  return (
    <div className={classes.tooltipWpr} data-testid="tooltipWpr">
      <i className={classes.infoIcon} onClick={handleOnClick}></i>
      {showToolTip && (
        <Tooltip
          hideTooltip={hideTooltip}
          tooltipPosition={tooltipPosition}
          positionStyle={toolTipPosition}
        >
          <div className={classes.tooltipDescriptionWrapper}>
            <div>
              <i className={classes.infoDarkIcon} />
            </div>
            <div className={classes.toolTipDescription}>
              {tooltipText}
            </div>
          </div>
            
        </Tooltip>
      )}
    </div>
  );
};

export default HelpTooltip;
