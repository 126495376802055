import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
    MainContainer: {
        maxWidth: 1440,
        marginTop: 0,
        height: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    container: {
      background: "white",
      height: "100vh",
      position: "relative",
      overflow: "hidden",
      display: "flex",
      flexDirection: "column",
    },
    layout: {
        display: "flex",
      },
      left: {
        background: colour.darkBlue,
        minWidth: 230,
        height: "100vh",
        overflow: "hidden",
        boxSizing: "border-box"
      },
      right: {
        overflow: "hidden",
        background: colour.white,
        width: "100%",
        height: "100vh",
        maxWidth: "1100px",
        minWidth: "0px",
      },
      leftNoDisplay: {
        position: "absolute",
        left: "0px",
        width: 0,
        height: "100vh",
        overflow: "hidden",
        minWidth: 0,
        opacity: 0,
        transition: ".3s ease-in",
        padding: 0,
      },
      leftTabletView: {
        position: "absolute",
        width: "240px",
        transition: ".3s ease-out",
        background: colour.darkBlue,
        height: "100%",
        padding: 15,
        boxSizing: "border-box",
      },
      rightTabletView: {
        background: colour.white,
        width: "100%",
        height: "auto", 
        position: "absolute",
        zIndex: -1 
      },
    })