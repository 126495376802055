import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";
import { desktopFontsSize, tabletFontsSize } from "../../../constants/Fonts";
import { colourMapper } from "../../globals/Utils";

export const useStyles = createUseStyles({
  container: (props: { theme: string; compColour: string; size: string }) => ({
    fontSize: ["lg", "xl", "xxl"].includes(props.theme)
      ? desktopFontsSize.xsm
      : tabletFontsSize.sm,
    width: props.size === "Large" ? "100%" : "50%",
    background: colour.white1,
    borderRadius: 5,
    borderLeft: `5px solid ${colourMapper(props.compColour)}`,
    boxSizing: "border-box",
    marginTop: 4,
    position: "relative",
  }),
  content: {
    color: colour.black,
    fontSize: 12,
    padding: "9px 7px 9px",
    boxSizing: "border-box",
    marginLeft: 5,
    textTransform: "uppercase",
    cursor: "pointer",
    position: "relative",
    fontFamily: "'AvenirLTStd-Book', sans-serif",
    height: 38,
    lineHeight: "18px",
  },
  label: {
    margin: "3px 0",
  },
  subtitle: {
    margin: "16px 0 8px",
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    fontSize: 12,
    textTransform: "uppercase",
  },
  MedicationContent: {
    fontFamily: "'AvenirLTStd-Book', sans-serif",
    fontSize: 12,
    lineHeight: "18px",
    backgroundColor: colour.white,
    borderRadius: 12,
    padding: "12px 14px",
  },
  interpretationContainer: {
    marginBottom: 12,
  },
  phenotypes: {
    marginBottom: 6,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  phenotypeDescription: {
    margin: "1px 0 0 8px",
  },
  warningIcon: {
    marginLeft: 8,
    marginBottom: 2,
    verticalAlign: "middle",
  },
  toolTipTitle: {
    margin: "5px 0",
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    fontSize: 12,
  },
  tooltipContent: {
    padding: "2px 0", 
    fontFamily: "AvenirLTStd-Book", 
    fontSize: 12
    },
  tooltipContentWrapper: {
    display: 'flex',
    flexDirection: 'column'
    }
});

