import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  container: {
    background: "white",
    height: "100%",
    marginLeft: 30,
    marginTop: 20,
  },
  loader: {
    height: "60%",
    top: "30%",
    bottom: 0,
    margin: "auto",
    position: "absolute",
    left: "60%",
    right: 0,
  },
  quadrantWpr: {
    marginBottom: 24,
  },
  tabsList: {
    display: "flex",
    listStyle: 'none',
    margin: 0,
    padding: 0,
    '& li': {
      padding: "4px 8px",
      marginRight: 50,
      fontSize: '1rem',
      lineHeight: '1.3rem',
      position: 'relative',
      cursor: 'pointer',
      fontFamily: "'AvenirLTStd-Roman', sans-serif",
      textTransform: "capitalize",
      '&:after': {
        position: 'absolute',
        height: 2,
        width: '100%',
        display: 'inline-block',
        content: "''",
        left: 0,
        bottom: '-3px',
      },
      '&.react-tabs__tab--selected': {
        fontFamily: "'AvenirLTStd-Heavy', sans-serif",
        '&:after': {
          background: '#30B5E8'
        },
      }
    },
  },
  staticPosition: {
    position: "static !important"
  }
});
