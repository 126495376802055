import ReactLoading from 'react-loading';
import {colour} from '../../constants/Colours'

interface Props {
    color?: string;
    height?: number | string;
    isLoading?: boolean;
    width?: number | string;
  }
  

const Loader = (props:Props) => {
    const { color, height, width, isLoading } = props;
    return (
    <>{isLoading && <ReactLoading type="spinningBubbles" color={color ?? colour.blue } height={height ?? '20%'} width={width ?? '20%'} />}</>
    )
};
 
export default Loader;