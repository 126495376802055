import { Auth } from "aws-amplify";
import { reSetUserData, setUserData } from "../../actions/index";
import { getReportData, getReportId } from "../../components/globals/ReportsServices";
import { validateEmail } from "../../components/globals/Utils";

const messages = {
  pleaseCheckYourEmail: "Please check your email",
  userNotFound: "Incorrect username or password. Please try again.",
};

function SignInService(
  email: string,
  password: string,
  setErrorMessage: Function,
  dispatch: Function,
  setDisabled: Function,
  search: any,
  setLoading: Function,
  setShowError: Function,
  setErrorType: Function,
  routerUrl:string,
  navigate:any
) {
  return new Promise((fulfilled, rejected) => {
    if (!validateEmail(email)) {
      setErrorMessage(messages.pleaseCheckYourEmail);
      setDisabled(false);
      return rejected("no valid id");
    } else {
      setLoading(true);
      return Auth.signIn(email, password)
        .then(async (data) => {
          setErrorMessage(null);
          dispatch(setUserData(data));
          
          let Id = getReportId(search);

          if(Id){
            getReportData(Id, dispatch, setErrorType, setShowError, setLoading, routerUrl, navigate);
          }else {
            setShowError(true);
            setErrorType("REPORT ID");
            return rejected("No reference Number found");
          }        
          
        })
        .catch((error) => {
          if (error?.code === "UserNotFoundException") {
            setErrorMessage(messages.userNotFound);
          } else if (error?.code === "UserNotConfirmedException") {
            setErrorMessage(messages.userNotFound);
          } else {
            dispatch(reSetUserData(null));
            setErrorMessage(error?.message);
          }
          return rejected(error);
        })
        .finally(() => {
          setDisabled(false);
        });
    }
  });
}

export {SignInService, getReportData};
