export const colour = {
  blue: "#30b5e8",
  blue2: "#185b78",
  blue3: "#eef2f4", // SearchBar Background
  blue4: "#233742",
  blue5: "#345A6D",
  blue6: "#3E83A0",
  blue7: "#57cbfd",
  black: "#000000",
  darkBlue: "#081f2d",
  white: "#ffffff",
  white1: "#F2F1F2",
  grey: "#8d8e9b", // Text
  grey1: "#B2B2B2",
  fadedGrey: "#666666",
  fadedGrey2: "#676879",
  fadedGrey3: "#E6E9EF",
  fadedGrey4: "#f5f6f8",
  fadedGrey5: "#d6d6d6",
  spinnerBg: "rgba(0,0,0, .4)",
  grey3: "#a6a6a6", // Accordion Header
  grey4: "#f0f3f5", // Accordion background
  grey5: "#707070", // table header text
  grey6: "#ebebeb",
  grey7: "#f1f4f5",
  grey8: "#ededed",
  grey9: "#f3f3f3",
  grey10: "#E3E9EC",
  grey11: "#F2F1F2",
  red: "#b02318",
  orange: "#eea94e",
  darkPink: "#E81F76",
  green: "#a3c854",
  green2: "#48cb76",
};
