import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";
import infoIcon from "../../assets/icons/info-icon.svg";

export const useStyles = createUseStyles({
  tooltipWpr: {
    position: "relative",
    zIndex: 1
  },
  icon: {
    width: 24,
    height: 24,
    display: "inline-block",
    marginTop: 6,
    marginLeft: 18,
    marginRight: 8,
    background: `url(${infoIcon}) no-repeat center center`,
    cursor: "pointer"
  },
  tooltip: {
    padding: 11,
    background: colour.white,
    width: 280,
    zIndex: 9999,
    boxShadow: "0 0 8px #ccc",
    borderRadius: 6,
    fontSize: 11,
    color: colour.fadedGrey,
    position: "relative",
    marginBottom: 24,
    "& a": {
      color: colour.fadedGrey,
      textDecoration: "underline",
      "&:hover" : {
        textDecoration: "none",
      }
    }
  },
  arrow: {
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: "0 24px 24px 24px",
    borderColor: "transparent transparent #ffffff transparent",
    position: "absolute",
    top: -17,
  },
  rightAlignedTooltip: {
    position: "absolute",
    left: "auto",
    top: 48,
    right: -24,
    "& em" : {
      right: 11
    }
  },
  leftAlignedTooltip: {
    position: "absolute",
    left: -5,
    top: 48,
    zIndex: 9
  },
  overlay: {
    width: "calc( 100% - 22px )",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
  },
  content: {
    position: "relative",
    wordBreak: "normal",
    lineHeight: "16px",
    "& a": {
      wordBreak: "break-all" //This is to fix the over floating the lengthy links 
    }
  }
});
