// redux actions

export const SAMPLE_REDUCER = "SAMPLE_REDUCER";
export const SAMPLE_REDUCER_VARIANT = "SAMPLE_REDUCER_VARIANT";
export const SEARCH_DATA = "SEARCH_DATA";
export const ACTIVE_DRUG = "ACTIVE_DRUG";
export const SELECTED_SEARCH_ITEM = "SELECTED_SEARCH_ITEM";
export const PGX_REPORT_DATA = "PGX_REPORT_DATA";
export const SIGNED_IN = "SIGNED_IN";
export const SIGN_IN_FAILURE = "SIGN_IN_FAILURE";
export const SET_MEDICATIONS_CATEGORIES_DETAILED_VIEW = "SET_MEDICATIONS_CATEGORIES_DETAILED_VIEW";
export const SET_ACTIVE_MENU_ITEM = "SET_ACTIVE_MENU_ITEM";
