import { useStyles } from "./styles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { useScreenClass } from "react-grid-system";
import LeftContentContainer from "../LeftContentContainer";
import { useNavigate, useLocation } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";

import Loader from "../Loader";
import { useSelector } from "react-redux";

import { getCurrentUser } from "../globals/useAuth";
import { setReportData, setUserData } from "../../actions";

import { FetchGetReport, removeInvalidCategories, fixMissingGrayQuadrant, formatAndDispatchSearchData, sortGrayQuadrantDescendingly } from "../globals/Utils";
import { getReportId } from "../../components/globals/ReportsServices";
import Header from "../_organisms/Header";

const RightSection = ({ data, Page, toggleMenu, setLoading }) => {
  return (
    <>
      <Header
        toggleMenu={toggleMenu}
        setLoading={setLoading}
        patientName={data.patient.fullName}
        reportType={data.reportType}
        reportBranding={data.reportInformation.reportBranding}
      />
      <Page data={data} toggleMenu={toggleMenu} categories={data?.categories} />
    </>
  );
};

const MainContainer = ({ Page, setErrorType }) => {
  const pgxReportData = useSelector((state) => state.appData.pgxReportData);

  const classes = useStyles();

  const [menuBarStatus, setMenuBarStatus] = useState(true);
  const [loading, setLoading] = useState(false);
  const search = useLocation().search;
  const screenClass = useScreenClass();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [isLoading] = useState(false);
  const toggleMenu = (val) => {
    setMenuBarStatus(val);
  };
  useEffect(() => {
    setMenuBarStatus(false);
  }, []);

  const getReportData = (Id) => {
    Object.keys(pgxReportData).length === 0 ?
      FetchGetReport(Id)
        .then((response) => {
          const data = response.data.getReport.reportPgxData;
          removeInvalidCategories(data);
          fixMissingGrayQuadrant(data);
          sortGrayQuadrantDescendingly(data);
          if(data.error && data.error.errorMessage){
            setErrorType(data.error.errorMessage.includes("Reference Number") ? "REPORT ID" : "UNKNOWN");
            navigate("/reports/Error");
          }else {
            dispatch(setReportData(data));
            formatAndDispatchSearchData(data, dispatch);
          }
        })
        .catch((error) => {
          const errors = error.errors;
          if (errors && errors.length > 0) {
            let netWorkError = errors.find((eachError) =>
              eachError.message.toUpperCase().includes("NETWORK")
            );
            if (netWorkError && netWorkError.message) {
              setErrorType("NETWORK");
            } else {
              let message = "";
              errors.forEach((eachError, i) => {
                message = `${
                  message +
                  (i === 0 && " ") +
                  eachError.message +
                  (i !== errors.length && ", ")
                }`;
              });
              setErrorType(message);
            }
          } else if (error.message) {
            setErrorType(error.message);
          } else {
            setErrorType("UNKNOWN");
          }
          navigate("/reports/Error");
        })
        .finally(() => {
          setLoading(false);
        }) : 
        setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getCurrentUser()
      .then((data) => {
        if(data){
          dispatch(setUserData(data));
          let Id = getReportId(search);
          getReportData(Id);
        }else {
          navigate("/reports/");
        }
      })
      .catch(() => {
        dispatch(setUserData(null));
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [dispatch, navigate, search, setErrorType, pgxReportData]);

  return (
    <div className={classes.MainContainer}>
      {Object.keys(pgxReportData).length > 0 && (
        <div className={classes.layout}>
          <div
            className={
              ["md", "sm", "xs"].includes(screenClass) && menuBarStatus
                ? classes.leftTabletView
                : ["lg", "xl", "xxl"].includes(screenClass)
                ? classes.left
                : classes.leftNoDisplay
            }
          >
            <LeftContentContainer
              toggleMenu={toggleMenu}
              menuBarStatus={menuBarStatus}
              patient={pgxReportData.patient}
              pgxReportData={pgxReportData}
              clinician={pgxReportData.clinician}
            />
          </div>
          <div
            className={
              ["md", "sm", "xs"].includes(screenClass) && menuBarStatus
                ? classes.rightTabletView
                : ["lg", "xl", "xxl"].includes(screenClass)
                ? classes.right
                : classes.rightTabletView
            }
          >
            <div className={classes.container}>
              <div className={classes.loader}>
                <Loader isLoading={isLoading} />
              </div>

              {!isLoading && (
                <RightSection
                  data={pgxReportData}
                  Page={Page}
                  toggleMenu={toggleMenu}
                  setLoading={setLoading}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {loading && <Spinner />}
    </div>
  );
};

export default MainContainer;
