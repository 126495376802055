import React from "react";
import { useStyles } from "./styles";
import HistoryDescription from "./HistoryDescription";


interface TheadProps {
  col1HeaderText: string;
  col2HeaderText: string;
  col3HeaderText: string;
}

const Thead = (props: TheadProps) => {
  const classes = useStyles();
  const { col1HeaderText, col2HeaderText, col3HeaderText } = props;
  return (
    <thead>
      <tr>
        <th className={classes.col1}>{col1HeaderText}</th>
        <th className={classes.col2}>{col2HeaderText}</th>
        <th className={classes.col3}>{col3HeaderText}</th>
        <th className={classes.col4}></th>
      </tr>
    </thead>
  );
};

const HistoryLog = () => {
  const classes = useStyles();
  return (
    <div className={classes.historyWpr} data-testid="historyLog">
      <h4>History</h4>
      <table className={classes.table}>
        <Thead
          col1HeaderText="GENE"
          col2HeaderText="DATE"
          col3HeaderText="ACTION"
        />
        <tbody>
          <HistoryDescription
            col1="CYP2D6"
            col2="2022-05-23"
            description="Changed the phenotype category of HIM to IM as per CPIC concensus"
          />
        </tbody>
      </table>
      <table className={classes.table}>
        <Thead
          col1HeaderText="MEDICATION"
          col2HeaderText="DATE"
          col3HeaderText="ACTION"
        />
        <tbody>
          <HistoryDescription
            col1="DOTHIEPIN (TCA)"
            col2="2022-02-05"
            description="Changed the phenotype category of HIM to IM as per CPIC concensus"
          />
        </tbody>
      </table>
      <table className={classes.table}>
        <Thead
          col1HeaderText="MEDICATION"
          col2HeaderText="DATE"
          col3HeaderText="ACTION"
        />
        <tbody>
          <HistoryDescription
            col1="DOTHIEPIN (TCA)"
            col2="2022-02-23"
            description="Changed the phenotype category of HIM to IM as per CPIC concensus"
          />
        </tbody>
      </table>
    </div>
  );
};

export default HistoryLog;
