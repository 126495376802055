import { useStyles } from "./styles";
import { useScreenClass } from "react-grid-system";
import HelpTooltip from "../HelpTooltip";
import { getHelpText } from "../globals/Utils";

interface Props {
  title: string;
  colour: string;
  openModal: Function;
  tooltipPosition: string;
  showExpandAllLink: boolean;
}
const AccordionHeader = (props: Props) => {
  const { title, colour, openModal, tooltipPosition, showExpandAllLink } =
    props;
  const theme = useScreenClass();
  const styleProps = { theme: theme, colour: colour };
  const classes = useStyles(styleProps);
  return (
    <div className={classes.container} data-testid="accordion-header">
      <div className={classes.content} data-testid="title">{title}</div>
      <HelpTooltip
        tooltipText={getHelpText(title)}
        openToolTip={false}
        tooltipPosition={tooltipPosition}
      />
      {showExpandAllLink && (
        <span className={classes.expandAll} onClick={() => openModal()} data-testid="expand-all-link">
          Expand All
        </span>
      )}
    </div>
  );
};

export default AccordionHeader;
