import React from "react";
import { useStyles } from "./styles";

type GeneTagProps = {
  isAdditionalGene?: boolean;
  title: string;
  description?: string;
};

const GeneTag = ({ isAdditionalGene = false, title, description }: GeneTagProps) => {
  const classes = useStyles();

  return (
    <div data-testid="gene" className={isAdditionalGene ? classes.outlinePill : classes.solidPill}>
      <span className={classes.title}>{title}</span>
      {description && (
        <span data-testid="gene-description" className={classes.description}>{description}</span>
      )}
    </div>
  );
};

export default GeneTag;
