import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";
import { desktopFontsSize, tabletFontsSize } from "../../constants/Fonts";

export const useStyles = createUseStyles({
    name : {
        color: colour.white,
        fontSize: (screenClass) => ['lg', 'xl', 'xxl'].includes(screenClass) ? desktopFontsSize.xsmd : tabletFontsSize.sm,
        fontWeight: "normal",
        marginRight: 3,
        marginBottom: 2,
        fontFamily: "'AvenirLTStd-Heavy', sans-serif"
    },
    value: {
        color: colour.white,
        fontSize: (screenClass) => ['lg', 'xl', 'xxl'].includes(screenClass) ? desktopFontsSize.xsmd : tabletFontsSize.sm,
        fontWeight: 100,
        fontFamily: "'AvenirLTStd-Book', sans-serif"
    },
    isHorizontal: {
        display: 'flex',
        flexDirection: 'row',
        padding: '0px 5px'
    },
    isVertical: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0px 5px'
    },
    capitalizeFirstLetter: {
        display: "inherit",
        "&:first-letter": {
            textTransform: "uppercase"
        }
    }
})