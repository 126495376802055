import PropTypes from 'prop-types'
import {useStyles} from './styles'

const Separator = ({styles}) => {
    const classes = useStyles();
    return (<hr style={styles} className={classes.separator} />)
}

Separator.propTypes = {
    styles: PropTypes.objectOf(PropTypes.any),
}

export default Separator