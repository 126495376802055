import {ACTIVE_DRUG, SAMPLE_REDUCER, SAMPLE_REDUCER_VARIANT, SEARCH_DATA, SELECTED_SEARCH_ITEM, PGX_REPORT_DATA, SIGNED_IN, SIGN_IN_FAILURE, SET_ACTIVE_MENU_ITEM, SET_MEDICATIONS_CATEGORIES_DETAILED_VIEW} from '../constants/index'

const INITIAL_STATE = {
  isFilterInDetailedView: false,
  activeMedicationsCategoryMenuIndex: 0,
  activeMenuItem: {},
  searchableData: [],
  pgxReportData: {},
  activeDrug: null,
  selectedSearchItem: {},
  user: {},
};

const reducer = (state = INITIAL_STATE, action: any) => {
    switch(action.type) {
        case SAMPLE_REDUCER: 
            return {
                ...state, sample: "sample_reducer"
            }
        case SAMPLE_REDUCER_VARIANT:
            return {
                ...state, sample: "sample_reducer_variant"
            }
        case PGX_REPORT_DATA: 
            return {
                ...state, pgxReportData: action.payload.data
            }
        case SEARCH_DATA: 
            return {
                ...state, searchableData: action.payload
            }
        case ACTIVE_DRUG: 
            return {
                ...state, activeDrug: action.payload
            }
        case SELECTED_SEARCH_ITEM: 
            return {
                ...state, selectedSearchItem: action.payload
            }
        case SIGNED_IN:
            return {
                ...state, user: action.payload
            }
        case SIGN_IN_FAILURE:
            return {
                ...state, user: action.payload
            }
        case SET_ACTIVE_MENU_ITEM:
            return {
                ...state, activeMenuItem: { identifier: action.payload.identifier, index: action.payload.index }
            }
        case SET_MEDICATIONS_CATEGORIES_DETAILED_VIEW:
            return {
                ...state, isFilterInDetailedView: action.payload
            }

        default: return state
    }
}

export default reducer;