import { useStyles } from "./styles";
import UrlStringProcessor from "../globals/UrlStringProcessor";

interface Props {
  tooltipText: Element | string;
  hideTooltip: Function;
  tooltipPosition: string;
  positionStyle?: {
      top: string;
      left?: string;
      right?: string;
  }
}
const Tooltip = (props: Props) => {
  const { tooltipText, hideTooltip, tooltipPosition, positionStyle } = props;
  const classes = useStyles();
  return (
    <div
      className={
        tooltipPosition === "right"
          ? classes.rightAlignedTooltip
          : classes.leftAlignedTooltip
      }
      style={positionStyle}
      data-id="reference-tooltip"
    >
      <div className={classes.tooltip}>
        <span className={classes.overlay} onClick={() => hideTooltip()}></span>
        <em className={classes.arrow} aria-hidden={true}></em>
        <div className={classes.content} data-testid="tooltipContent">
          <UrlStringProcessor text={tooltipText} />
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
