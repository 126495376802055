import { useStyles } from "./styles";
import ReferenceTooltip from "../Tooltip/ReferenceTooltip";
import { useEffect, useState } from "react";
import { References } from "../../globals/interfaces/PractitionerData";

interface TextBlockProps {
  type?: string;
  title?: string;
  description: string;
  references?: References[];
  backgroundColor: string;
  isExpanded?: boolean;
  shortenedCharacterLimit?: number;
}


export const TextBlock = ({
  type,
  title,
  description,
  references,
  backgroundColor,
  isExpanded = false,
  shortenedCharacterLimit = 60,
}: TextBlockProps) => {
  const styleProps = { backgroundColor: backgroundColor };
  const classes = useStyles(styleProps);
  const [isTextBlockExpanded, setIsTextBlockExpanded] = useState(isExpanded);
  const toggleView = () => setIsTextBlockExpanded(!isTextBlockExpanded);

  useEffect(() => {
    setIsTextBlockExpanded(isExpanded);
  }, [isExpanded]);

  const lengthOfTitle = `${title}: `.length;
  const lengthOfDescription = description.length;
  const isTextExpandable =
    lengthOfTitle + lengthOfDescription > shortenedCharacterLimit;
  const displayShortenedCharacters = (text: string) =>
    text.substring(0, shortenedCharacterLimit);

  const displayShortenedDescription = (description: string) => {
    if (lengthOfTitle >= shortenedCharacterLimit) return "";
    return description.substring(0, shortenedCharacterLimit - lengthOfTitle);
  };

  return (
    <div className={classes.texBlock} data-testid="textBlock">
      {type !== "additionalGene" && title && (
        <span className={classes.boldText}>
          {isTextBlockExpanded
            ? `${title}: `
            : displayShortenedCharacters(`${title}: `)}
        </span>
      )}
      {!isTextBlockExpanded ? (
        <span data-testid="text-brief" className={classes.briefText}>
          <span
            dangerouslySetInnerHTML={{
              __html: displayShortenedDescription(description),
            }}
          />
          {isTextExpandable && (
            <span
              data-testid="text-more-button"
              className={`${classes.toggleText} ${classes.fadedGradient}`}
              onClick={toggleView}
            >
              More
            </span>
          )}
        </span>
      ) : (
        <div data-testid="text-expanded">
          {references ? (
            <ReferenceTooltip references={references} content={description} />
          ) : (
            <span
              className={classes.description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
          )}
          <span className={classes.toggleText} onClick={toggleView}>
            Less
          </span>
        </div>
      )}
    </div>
  );
};

export default TextBlock;
