import React from "react";
import { useStyles } from "./styles";
import Reference from "../globals/interfaces/reference";
import UrlStringProcessor from "../globals/UrlStringProcessor";

interface Props {
  references: Array<Reference>;
}

const References = (props: Props) => {
  const { references } = props;
  const classes = useStyles();
  return (
    <div className={classes.wpr} data-testid="historyLog">
      <p className={classes.description}>Below is the full list of references used in this report.</p>
      <ol>
        {references.map((reference, i) => (
          <li key={reference.key} className={classes.references}>
            <UrlStringProcessor text={reference.text} />
          </li>
        ))}
      </ol>
    </div>
  );
};

export default References;
