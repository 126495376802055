import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";

export const useStyles = createUseStyles({
  circle: (props: { isDark: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    background: props.isDark ? colour.grey1 : colour.white1,
    borderRadius: 100,
  }),
  smCircle: {
    minWidth: "24px",
    maxWidth: "24px",
    minHeight: "24px",
    maxHeight: "24px",
    fontSize: 10,
  },
  mdCircle: {
    minWidth: "40px",
    maxWidth: "40px",
    minHeight: "40px",
    maxHeight: "40px",
    fontSize: 16,
  },
  lgCircle: {
    minWidth: "48px",
    maxWidth: "48px",
    minHeight: "48px",
    maxHeight: "48px",
    fontSize: 18,
  },
  text: {
    margin: 0,
    paddingTop: 4,
    color: colour.black,
    fontFamily: "AvenirLTStd-Roman, sans-serif",
    textAlign: "center",
    textTransform: "uppercase",
  },
  userIcon: {
    padding: "0.5em",
  },
});
