import { useStyles } from "./styles";
import { useScreenClass, Visible } from "react-grid-system";
import { FetchDocumentUrl } from "../../globals/Utils";
import SearchBar from "../../_molecules/SearchBar";
import { useState } from "react";
import { saveAs } from "file-saver";
import { localStorageKey } from "../../../constants/Constants";
import Download from "../../_molecules/DownloadPdf";
import ImageSrc from "../../_atoms/ImageSrc";
import myDnaLogo from "../../../assets/logos/powered_by_mydna-logo.svg";
import UserControl from "../../_molecules/UserControl";
import { ReportBranding } from "../../globals/interfaces/reportBranding";

interface HeaderProps {
  labRef: string;
  patientName: string;
  setLoading: Function;
  toggleMenu: Function;
  reportType: string;
  reportBranding: ReportBranding;
}

const Header = ({
  labRef,
  patientName,
  setLoading,
  toggleMenu,
  reportType,
  reportBranding
}: HeaderProps) => {
  const theme = useScreenClass();
  const classes = useStyles({ theme: theme });

  const [showError, setShowError] = useState(false)

  const saveFile = (url:string, name:string) => {
    saveAs(
      url,
      name
    );
  };

  const downloadPdf = () => {

    setLoading(true);

    const Id = localStorage.getItem(localStorageKey.reportId) || "";

    FetchDocumentUrl(Id, reportType)
    .then((data:any)=>{
      const docUrl = data.data.getDocumentUrl.preSignedUrl;
      if(docUrl === "no document found") {
        setShowError(true);
      }else {
        saveFile(docUrl, `${labRef}_${patientName}.pdf`);
      }
    }).catch((error) => {
      setShowError(true);
    }).finally(()=>{
      setLoading(false);
    })
  };

  const evaluateShouldShowMyDnaLogo = (reportBranding: ReportBranding) => {
    if (reportBranding.base64ImageEncode && reportBranding.imageType) return true;
    if (reportBranding.title) return true;
    return false;
  }

  const shouldShowMyDnaLogo = evaluateShouldShowMyDnaLogo(reportBranding);

  return (
    <div data-testid="header" className={classes.header}>
      {showError && (
        <div className={classes.errorNotification}>
          Error while fetching the data! please try again later.
          <button
            className={classes.closeError}
            onClick={() => setShowError(false)}
          >
            X
          </button>
        </div>
      )}
      <div data-testid="header-container" className={classes.headerContainer}>
        <Visible md sm xs>
          <button
            className={classes.toggleMenuButton}
            onClick={() => {
              toggleMenu(true);
            }}
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
        </Visible>
        {shouldShowMyDnaLogo &&
        <div data-testid="logo" className={classes.imageDiv}>
          <ImageSrc imageSrc={myDnaLogo} imageAlt={myDnaLogo} styles={{ width: 80 }}/>
        </div>}
        <SearchBar placeholder="Search for Gene/Medication" />
        <div data-testid="download-control">
          <Download onClick={() => downloadPdf()} />
        </div>
        <span data-testid="user-control" className={classes.user}>
          <UserControl />
        </span>
      </div>
    </div>
  );
};

export default Header;
