import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
    separator: {
        background: colour.fadedGrey3,
        boxSizing: 'border-box',
        height: 1,
        border: "none"
    }
})