import React from "react";
import { useStyles } from "./styles";

type BubbleProps = {
  children: JSX.Element;
};

const Bubble = ({children }: BubbleProps) => {

  const classes = useStyles();
  
  return (
    <div className={classes.bubble}>
        {children}
    </div>
  );
};

export default Bubble;
