import React, { useState } from "react";
import MyDnaTextField from "../../components/Elements/MyDnaTextField";
import { Link } from "react-router-dom";
import { resetPassword } from "./ForgotPasswordServices";
import { useStyles } from "../SignIn/styles";

import { validateEmail, validatePassword } from "../../components/globals/Utils";

interface Props {
  emailAddress: string;
  setShowResetPwd: Function;
  setLoading: Function;
}
const ResetPassword = (props: Props) => {
  const { emailAddress, setShowResetPwd, setLoading } = props;
  const classes = useStyles();
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [emailId, setEmailId] = useState(emailAddress);
  const [verifyCode, setVerifyCode] = useState("");
  const [password, setPassword] = useState("");
  const [successMsg, setSuccessMsg] = useState(false);


  const submitForm = () => {
    setErrorMessages([]);
    setSuccessMsg(false);
    setLoading(true);
    let errorMsgs: string[] = [];
    if (!validateEmail(emailId)) {
      errorMsgs.push("Please check your email");
    }
    if (verifyCode.length < 3) {
      errorMsgs.push("Please check your verifyCode");
    }
    if (!validatePassword(password)) {
      errorMsgs.push("Please check your password. It should be Min 5 characters with at-least one letter and one number");
    }


    if (errorMsgs.length === 0) {
      resetPassword(
        emailId,
        verifyCode,
        password,
        setErrorMessages,
        setSuccessMsg,
        setLoading
      );
    } else {
      setErrorMessages(errorMsgs);
    }
  };

  return (
    <>
      <form
        id={"reset_password"}
        onSubmit={(event) => {
          event.preventDefault();
          submitForm();
        }}
      >
        <React.Fragment>
          <MyDnaTextField
            id="emailAddress"
            name="emailAddress"
            type="text"
            label="Email"
            required={true}
            onChange={(e: any) => setEmailId(e.target.value)}
            value={emailId}
            placeholder="Please enter your email"
          />

          <MyDnaTextField
            id="verifyCode"
            name="verifyCode"
            type="text"
            label="Verification Code"
            required={true}
            onChange={(e: any) => setVerifyCode(e.target.value)}
            value={verifyCode}
            placeholder="Please enter verification code"
          />

          <MyDnaTextField
            id="password"
            name="password"
            type="password"
            label="New Password"
            required={true}
            onChange={(e: any) => setPassword(e.target.value)}
            value={password}
            placeholder="Please enter your new password"
          />

          {/* <MyDnaTextField
                    id="confirmPassword"
                    name="confirmPassword"
                    type="confirmPassword"
                    label="Confi Password"
                    required={true}
                    onChange={(e: any) => setPassword(e.target.value)}
                    value={password}
                    placeholder="Please enter your new password"
                  /> */}

          {errorMessages.length > 0 &&
            errorMessages.map((errorMessage, i) => (
              <div key={i}>
                <p
                  className={classes.errorMessage}
                  dangerouslySetInnerHTML={{ __html: errorMessage }}
                ></p>
              </div>
            ))}

          <div className={classes.alignCenter} style={{ marginTop: 18 }}>
            <button className={classes.button}>Reset Password</button>
          </div>
          <div className={classes.alignCenter}>
            {successMsg && (
              <p className={classes.success}>
                Request has submitted successfully
              </p>
            )}
          </div>

          <div className={classes.alignCenter} style={{ marginTop: 18 }}>

          <span className={classes.link} onClick={() => setShowResetPwd(false)}>
              Go the previous screen
            </span> | {" "}
            <Link to="/" className={classes.link}>
              Login page
            </Link>
          </div>
        </React.Fragment>
      </form>
    </>
  );
};

export default ResetPassword;
