import { createUseStyles } from "react-jss";
import { colourMapper } from "../globals/Utils";
import { colour } from "../../constants/Colours";
import { desktopFontsSize, tabletFontsSize } from "../../constants/Fonts";
import {getModelWidth} from "../globals/Utils";


export const useStyles = createUseStyles({
  name: (props: { theme: string;  colour: string; }) => ({
    color: colour.white,
    fontSize:
      ["lg", "xl", "xxl"].includes(props.theme)
        ? desktopFontsSize.xsm
        : tabletFontsSize.sm,
    fontWeight: 600,
    textTransform: "uppercase",
  }),
  value: (props: { theme: string; }) => ({
    color: colour.white,
    fontSize: 
      ["lg", "xl", "xxl"].includes(props.theme)
        ? desktopFontsSize.xsm
        : tabletFontsSize.sm,
    fontWeight: 100,
    textTransform: "uppercase",
  }),
  isHorizontal: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 5px",
  },
  isVertical: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 5px",
  },
  ModalTitle: (props: { theme: string; colour: string;}) => ({
    background: colourMapper(props.colour),
    color: colour.white,
    display: "flex",
    padding: "6px 14px",
    fontSize: 14,
    borderRadius: "4px 4px 0 0",
    height: 38,
    "& h4": {
      margin: 0,
      textTransform: "uppercase",
      lineHeight: "24px",
      fontFamily: "AvenirLTStd-Black, sans-serif",
    },
    "& i": {
      marginTop: 1
    },
  }),
  quadrantTable: {
    width: "100%",
    textAlign: "left",
    "& tbody ": {
      display: "block",
      maxHeight: 282,
      overflow: "auto",
      verticalAlign: "top",
      fontSize: 14,
      lineHeight: "22px"
    },
    "& thead, & tbody tr": {
      display: "table",
      width: "100%",
      tableLayout: "fixed",
    },
    "& thead": {
      width: "calc( 100% - 1em )",
      background: colour.white,
      borderRadius: 4,
      marginBottom: 4,
      fontSize: 15,
      color: colour.grey5,
      padding: "22px 8px 4px",
      fontFamily: "AvenirLTStd-Black, sans-serif",
      textTransform: "uppercase",
      "&:after": {
        background: colour.white,
        content: '""',
        width: 30,
        height: 53,
        display: "inline-block",
        position: "absolute",
        right: 3,
        top: 40,
        zIndex: "-1",
        borderRadius: 5
      }
    },
    "& tbody tr": {
      marginBottom: 0,
      background: colour.white,
      padding: "12px 8px 4px",
      boxSizing: "border-box",
      borderBottom: "1px solid "+ colour.grey5,
      "& th": {
        fontFamily: "AvenirLTStd-Black, sans-serif",
      },
    },
  },
  firstChild : {
    borderRadius: "4px 4px 0 0",
    borderBottom: "1px solid "+ colour.grey5,
  },
  tableRow: {
    borderBottom: "1px solid "+ colour.grey5,
  },
  lastChild : {
    borderRadius: "0 0 4px 4px",
    borderBottom: "none"
  },
  closeLink: {
    marginLeft: "auto",
    fontSize: 14,
    textDecoration: "underline",
    color: colour.white,
    cursor: "pointer",
    marginTop: 2,
    fontFamily: "AvenirLTStd-Roman, sans-serif",
  },
  modelwpr: (props: {theme:string; colour: string; position: {left:string; top: string;}}) => ({
    maxWidth: getModelWidth(props.theme, props.position),
    outline: "none"
  }),
  description: {
    position: "relative",
    "& [data-id='reference-tooltip']": {
      marginTop: 10
    }
  }
});
