import PropTypes from 'prop-types'
import { useStyles } from './styles'
import { useScreenClass } from 'react-grid-system';

const TextField = ({ name, value, inline, containerStyle, nameStyle, valueStyle, 
    nameTestId, capitalizeFirstLetter }) => {

    const screenClass = useScreenClass();
    const classes = useStyles(screenClass);

    return (
        <div
            className={`${inline ? classes.isHorizontal : classes.isVertical}`}
            style={containerStyle}>
            <div data-testid={nameTestId} className={classes.name} style={nameStyle}>{name}</div>
            <div className={classes.value} style={valueStyle}>
                <span className={capitalizeFirstLetter && classes.capitalizeFirstLetter}>{value}</span>
            </div>
        </div>
    );
}

TextField.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    inline: PropTypes.bool,
    containerStyle: PropTypes.objectOf(PropTypes.any),
    nameStyle: PropTypes.objectOf(PropTypes.any),
    valueStyle: PropTypes.objectOf(PropTypes.any),
    nameTestId: PropTypes.string,
    capitalizeFirstLetter: PropTypes.bool
}

export default TextField