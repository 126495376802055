import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
    title: {
        fontSize: 17,
        marginTop: 20,
        marginBottom: 17,
        fontFamily: "'AvenirLTStd-Roman', sans-serif",
        '& span': {
            fontSize: 21,
            fontFamily: "'AvenirLTStd-Heavy', sans-serif",
            textTransform: "capitalize"
        }
    },
    description: {
        fontSize: 14,
        color: colour.fadedGrey,
        lineHeight: '20px',
        marginBottom: 38,
        fontFamily: "'AvenirLTStd-Roman', sans-serif",
    }

})