import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";
import { colourMapper } from "../../globals/Utils";

export const useStyles = createUseStyles({
  container: (props: { theme: string; colour: string; size: string }) => ({
    minHeight: 40,
    width: (props.size==="Large" ? "100%" : "50%"),
    background: colourMapper(props.colour),
    borderRadius: 5,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  content: (props: { theme: string; colour: string }) => ({
    color:
      colourMapper(props.colour) === colour.white1
        ? colour.black
        : colour.white,
    padding: "9px 7px 7px",
    boxSizing: "border-box",
    marginLeft: "10px",
    fontSize: ["xl", "xxl"].includes(props.theme) ? 13 : 12,
    lineHeight: "20px",
    letterSpacing: "0.75px",
    textTransform: "uppercase",
    fontFamily: "AvenirLTStd-Medium, sans-serif",
  }),
  reportContainer: (props: { theme: string; colour: string }) => ({
    minHeight: 30,
    width: "fit-content",
    background: colour.white1,
    borderRadius: 50,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }),
  reportContent: (props: { theme: string }) => ({
    color: colour.black,
    padding: "4px 8px",
    boxSizing: "border-box",
    marginLeft: "10px",
    fontSize: 14,
    lineHeight: "20px",
    letterSpacing: "0.75px",
    textTransform: "capitalize",
    fontFamily: "AvenirLTStd-Roman, sans-serif",
  }),
  reportDotWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  reportDot: (props: { colour: string }) => ({
    width: 12,
    height: 12,
    marginRight: 12,
    borderRadius: 50,
    backgroundColor: colourMapper(props.colour),
  }),
  warningIcon: {
    marginRight: 20,
    marginTop: 1,
  },
  expandAll: {
    marginLeft: "auto",
    marginRight: 16,
    marginTop: 9,
    fontSize: 12,
    color: colour.white,
    textDecoration: "underline",
    cursor: "pointer",
    fontFamily: "AvenirLTStd-Roman, sans-serif",
  },
});
