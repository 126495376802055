import { useStyles } from "./styles";
import { useScreenClass } from "react-grid-system";
import { useEffect, useState, useRef } from "react";
import Tooltip from "../HelpTooltip/Tooltip";
import Drug from "../globals/interfaces/drugInterface";
import Phenotype from "../globals/interfaces/phenotypeInterface";
import Reference from "../globals/interfaces/reference";
import { sanitizedData } from "../globals/Utils";
interface Props {
  colour: string;
  drug: Drug;
  isExpand: boolean;
  references: Array<Reference>;
}

const AccordionItem = (props: Props) => {
  const { colour, drug, isExpand, references } = props;
  const theme = useScreenClass();
  const styleProps:any = { theme: theme, compColour: colour };
  const classes = useStyles(styleProps);
  const AccordionWprElement = useRef<HTMLDivElement>(null);
  const [expandView, setExpandView] = useState(isExpand);
  const [showToolTip, setShowToolTip] = useState(false);
  const [toolTipPosition, setToolTipPosition] = useState({
    left: "0px",
    top: "0px",
    right: "auto",
  });
  const [toolTipDirection, setToolTipDirection] = useState(colour === "Orange" ? "right" : "left");
  const [toolTipDesc, setToolTipDesc] = useState("");
  
  const openTooltip = () => {
    setShowToolTip(showToolTip ? false : true);
  };

  const hideTooltip = () => {
    setShowToolTip(false);
  };

  useEffect(() => {
    if(isExpand) {
      setExpandView(isExpand);
      if(AccordionWprElement.current){
        AccordionWprElement.current.classList.remove("active-item");
        AccordionWprElement.current.scrollIntoView();
        setTimeout(()=>{
          AccordionWprElement.current && AccordionWprElement.current.classList.add("active-item")
        }, 100); //Adding time delay to append animation back
      }
    };
  }, [isExpand])

  const isExpandable = () => colour !== "Gray";

  const getLeftPos = (leftPos:number, e:any) => {
    if(theme === "md" || theme === "sm" || theme === "xs"){
      return getTooltipDirection(e, leftPos)
    }else {
      const wrapperPos = AccordionWprElement?.current?.getBoundingClientRect().x || 0;
      return (colour === "Orange" ? leftPos - wrapperPos - 230  + "px" : leftPos - wrapperPos - 18 + "px");
    }
  }

  const getTooltipDirection = (e: any, leftPos:number) => {
    if(window.screen.width/2 > e.clientX) {
      //tooltip target is in left side
      setToolTipDirection("left");
      return leftPos - 48 + "px";
    }else {
       //tooltip target is in right side
      setToolTipDirection("right");
      return leftPos - 245 + "px";
    }
  }

  const clickHandler = (e: any): void => {
    let target = e.target as HTMLInputElement;
    if (target.tagName.toLowerCase() === "sup") {
      const referenceId = Number(target.innerText);
      const referenceItem = references.find((x) => x.number === referenceId);
      setToolTipDesc((referenceItem && referenceItem.text) || "");
      const leftPos = getLeftPos(e.clientX, e);
      setToolTipPosition({
        left: leftPos,
        top: e.target.offsetTop + 30 + "px",
        right: "auto",
      });
      openTooltip();
    } else {
      hideTooltip();
    }
  };

  return (
    <div className={classes.container} ref={AccordionWprElement} data-testid="accordionElement">
      <div
        className={classes.content}
        style={{ cursor: isExpandable() ? "pointer" : "auto" }}
        onClick={() => isExpandable() && setExpandView(!expandView)}
      >
        <div
          className={expandView ? classes.activeTitle : classes.inActiveTitle}
        >
          {drug.name} {drug.subCategory || null}
        </div>
        {isExpandable() && (
          <span
            className={classes.arrow}
            style={{
              transform: expandView ? "rotate(0deg)" : "rotate(180deg)",
            }}
          ></span>
        )}
      </div>
      {isExpandable() && (
        <div className={expandView ? classes.show : classes.hide}>
          <div>
            <h4>Interpretation</h4>
            <div>
              <div>
              {drug.phenotypes &&
                drug.phenotypes.map((phenotype: Phenotype, i: number) => (
                  <div key={i} className={classes.phenotypes}>
                    <strong>
                      {phenotype.gene} - {phenotype.shortComment}{" "}
                    </strong>
                  </div>
                ))}
                </div>
              <div
                onClick={(e) => clickHandler(e)}
                dangerouslySetInnerHTML={sanitizedData(drug.interpretation || "")}
              ></div>
            </div>
            <h4>Recommendation</h4>
            <div
              onClick={(e) => clickHandler(e)}
              dangerouslySetInnerHTML={sanitizedData(drug.recommendation || "")}
            ></div>

            {showToolTip && (
              <Tooltip
                tooltipText={toolTipDesc}
                hideTooltip={hideTooltip}
                tooltipPosition={toolTipDirection}
                positionStyle={toolTipPosition}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
