import { CognitoUser } from "amazon-cognito-identity-js"
import { SearchDataObject } from "../components/globals/interfaces/commonInterfaces"
import { 
    SAMPLE_REDUCER, 
    SAMPLE_REDUCER_VARIANT, 
    SEARCH_DATA, 
    ACTIVE_DRUG, 
    SELECTED_SEARCH_ITEM, 
    PGX_REPORT_DATA, 
    SIGNED_IN, 
    SIGN_IN_FAILURE, 
    SET_ACTIVE_MENU_ITEM,
    SET_MEDICATIONS_CATEGORIES_DETAILED_VIEW,
} from "../constants"

export const sample = () => {
    return SAMPLE_REDUCER
}

export const sample_variant = () => {
    return SAMPLE_REDUCER_VARIANT
}

export const updateSearchData = (data: SearchDataObject[]) => (dispatch: (arg0: { type: string; payload: { data: SearchDataObject[] } }) => void) => {
    dispatch({
        type: SEARCH_DATA,
        payload: {
            data: data
        }
    });
};

export const setActiveDrug = (data: null) => (dispatch: (arg0: { type: string; payload: any }) => void) => {
    dispatch({
        type: ACTIVE_DRUG,
        payload: data
    });
};

export const setSelectedSearchItem = (data: {}) => (dispatch: (arg0: { type: string; payload: any }) => void) => {
    dispatch({
        type: SELECTED_SEARCH_ITEM,
        payload: data
    });
};

export const setReportData = (data: {}) => (dispatch: (arg0: { type: string; payload: { data: any } }) => void) => {
    dispatch({
        type: PGX_REPORT_DATA,
        payload: {
            data: data
        }
    })
};
export const setUserData = (data: CognitoUser | null) => (dispatch: (arg0: { type: string; payload: any }) => void) => {
    dispatch({
        type: SIGNED_IN,
        payload: data
    })
};

export const reSetUserData = (data: null) => (dispatch: (arg0: { type: string; payload: any }) => void) => {
    dispatch({
        type: SIGN_IN_FAILURE,
        payload: data
    })
};

export const setActiveMenuItem = (data: {index: number, identifier: string}) => (dispatch: (arg0: { type: string; payload: {index: number, identifier: string} }) => void) =>
{
    dispatch({
        type: SET_ACTIVE_MENU_ITEM,
        payload: data
    })
}

export const setIsFilterInDetailedView = (data: boolean) => (dispatch: (arg0: { type: string; payload: any }) => void) =>
{
    dispatch({
        type: SET_MEDICATIONS_CATEGORIES_DETAILED_VIEW,
        payload: data
    })
}
