import { useStyles } from "./styles";

interface TooltipProps {
  hideTooltip: Function;
  tooltipPosition: string;
  positionStyle?: {
    top: string;
    left?: string;
    right?: string;
  };
  tooltipStyle?: string;
  isCompact?: boolean;
  children: JSX.Element;
}

enum TooltipPosition {
  TOP = "top",
  RIGHT = "right",
  LEFT = "left"
};

enum TooltipStyle {
  LIGHT = "light",
  DARK = "dark"
}

const Tooltip = ({ hideTooltip, tooltipPosition, positionStyle, tooltipStyle, isCompact, children }: TooltipProps) => {
  const classes = useStyles();

  const evaluateAlignmentTooltipStyle = (position: string) => {
    switch (position.toLowerCase()) {
      case TooltipPosition.RIGHT: 
        return classes.rightAlignedTooltip;
      case TooltipPosition.TOP: 
        return classes.topAlignedTooltip;
      case TooltipPosition.LEFT:
      default:
        return classes.leftAlignedTooltip;
    }
  }

  const evaluateTooltipStyle = (style: string) => {
    switch (style.toLocaleLowerCase()) {
      case TooltipStyle.DARK:
        return classes.darkTooltip;
      case TooltipStyle.LIGHT:
      default:
        return classes.lightTooltip;
    }
  }

  const evaluateTooltipArrowStyle = (tooltipPosition: string) => {
    switch (tooltipPosition.toLowerCase()) {
      case TooltipPosition.TOP:
        return classes.downArrow;
      default:
        return classes.upArrow;
    }
  }

  const evaluateTooltipWidth = (isCompact: boolean) => {
    return isCompact ? 200 : 280;
  }

  const evaluateArrowXOffset = (tooltipPosition: string, isCompact: boolean) => {
    switch (tooltipPosition) {
      case TooltipPosition.TOP:
        return isCompact ? 80 : 120;
      case TooltipPosition.LEFT:
        return 220;
      case TooltipPosition.RIGHT:
      default:
        return 20;
    }
  }

  const evaluateArrowYOffset = (tooltipPosition: string) => {
    if (tooltipPosition.toLowerCase() != TooltipPosition.TOP) {
      return -17;
    };
    return 30;
  }

  var width = evaluateTooltipWidth(isCompact ?? false);

  var arrowYOffset = evaluateArrowYOffset(tooltipPosition);
  var arrowXOffset = evaluateArrowXOffset(tooltipPosition, isCompact ?? false);
  return (
    <div
      className={evaluateAlignmentTooltipStyle(tooltipPosition)}
      style={positionStyle}
      data-id="reference-tooltip"
    >
      <div className={evaluateTooltipStyle(tooltipStyle ?? TooltipStyle.LIGHT)} style={{ width: width}}>
        <span className={classes.overlay} onClick={() => hideTooltip()}></span>
        <em className={evaluateTooltipArrowStyle(tooltipPosition)} aria-hidden={true} style={{top: arrowYOffset, right: arrowXOffset}} />
        <div className={classes.content} data-testid="tooltipContent">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Tooltip;
