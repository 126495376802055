import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  componentWrapper: {
    padding: "0.5rem 0",
  },
  imageDescriptionFont: {
    fontSize: 16,
    paddingBottom: "0.5rem",
  },
  imageWrapper: {
    maxWidth: "60%",
  },
  title: {
    fontSize: 33,
    lineHeight: "27px",
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    fontWeight: "normal",
    textTransform: "capitalize",
    margin: 0,
    marginRight: 16,
    userSelect: "none",
    padding: "0.5rem 0",
  },
  textBlockWrapper: {
    paddingBottom: "3rem",
    paddingRight: "1rem",
    fontFamily: "AvenirLTStd-Book",
    fontSize: "14px",
    maxWidth: "48rem",
  },
  filterDiv: {
    margin: "10px 0",
    display: "inline-block",
  },
});
