import { useStyles } from "./styles";

interface Props {
  type?: string;
  id?: string;
  name?: string;
  onChange?: Function;
  label: string;
  required: boolean;
  value: string;
  placeholder: string;
  disabled?: boolean;
}

const MyDnaTextField = (props: Props) => {
  const classes = useStyles();
  const { type, id, name, onChange, label, required, value, placeholder, disabled } = props;
  return (
    <div>
      <label
      className={classes.label}
      htmlFor={id}
      >{label}</label>
      <input
        type={type}
        className={classes.textField}
        onChange={e => onChange && onChange(e)}
        required={required}
        value={value}
        id={id}
        name={name}
        placeholder={placeholder}
        autoComplete="false"
        disabled={disabled}
      />
    </div>
  );
};
export default MyDnaTextField;
