import { Auth } from "aws-amplify";

function FederatedSignInService(
  setDisabled: Function,
) {
  return new Promise((fulfilled, rejected) => {
      return Auth.federatedSignIn( { customProvider: 'NucleusAuth'} )
        .then(async (data) => {
          return fulfilled(data)
        })
        .catch((error) => {
          return rejected(error);
        })
        .finally(() => {
          setDisabled(false);
        });
    
  });
}

export {FederatedSignInService};
