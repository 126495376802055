import Overview from "../../components/Overview";
import Disclaimer from "../../components/Disclaimer";
import TabBar from "../../components/_atoms/TabBar";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setActiveMenuItem } from "../../actions";

const About = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setActiveMenuItem({
        index: 1,
        identifier: "details",
      })
    );
  }, [dispatch]);

  const tabs = [
    {
      name: "Report Overview",
      content: <Overview />,
    },
    {
      name: "Disclaimer",
      content: <Disclaimer />,
    },
  ];

  return <TabBar tabs={tabs} />;
};

export default About;
