import { useEffect, useState } from "react";
import { useStyles } from "./styles";

export type Cell = {
  content: JSX.Element;
  width: string;
};

interface TableRowProps {
  cells: Cell[];
  isHighLighted?: boolean;
}

export const TableRow = ({ cells, isHighLighted=false }: TableRowProps) => {
  const classes = useStyles();

  const [isRowHightLighted, setIsRowHightLighted] = useState(isHighLighted);

  useEffect(() => {
    setIsRowHightLighted(isHighLighted);
  }, [isHighLighted]);
  

  return (
    <tr
      data-testid="cell"
      className={`${isRowHightLighted && classes.hightLightedRow}`}
    >
      {cells.map((cell, index) => (
        <td key={index} width={cell.width}>
          {cell.content}
        </td>
      ))}
    </tr>
  );
};
