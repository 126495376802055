import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
  container: (props: { theme: string }) => ({
    minHeight: 35,
    width: "100%",
    borderRadius: 5,
    display: "flex",
    background: colour.grey11,
    margin: '20px 0px'
  }),
  content: (props: { theme: string }) => ({
    color: "black",
    padding: "10px",
    boxSizing: "border-box",
    marginLeft: "5px",
    textTransform: "uppercase",
    fontFamily: "AvenirLTStd-Black, sans-serif",
    "& h2": {
      fontSize: ["xl", "xxl"].includes(props.theme)
        ? 20
        : 15,
      textTransform: "none",
      margin: 0,
      padding: "10px 0px"
    },
    "& p": {
      fontSize: ["xl", "xxl"].includes(props.theme)
        ? 15
        : 12,
      textTransform: "none",
      margin: 0,
      padding: "10px 0px"

    },
  }),
    emptyContent: (props: { theme: string }) => ({
      color: "black",
      padding: "30px 10px",
      boxSizing: "border-box",
      marginLeft: "5px",
      textTransform: "uppercase",
      fontFamily: "AvenirLTStd-Black, sans-serif",
      "& h2": {
        fontSize: ["xl", "xxl"].includes(props.theme)
          ? 20
          : 15,
        textTransform: "none",
        margin: 0,
        padding: "10px 0px"
      }
    })
  });
