import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    zIndex: 999,
    alignItems: "center",
    justifyContent: "center",
    background:  colour.spinnerBg
  },
  "@keyframes helix": {
    "0%": { width: 5, height: 5, bottom: -5, zIndex: 10 },
    "25%": { width: 2, height: 2 },
    "50%": { width: 5, height: 5, bottom: "100%", zIndex: 20 },
    "75%": { width: 8, height: 8 },
    "100%": { width: 5, height: 5, bottom: -5 },
  },
  "@keyframes helix-reversed": {
    "0%": { width: 5, height: 5, bottom: "100%", zIndex: 20 },
    "25%": { width: 8, height: 8 },
    "50%": { width: 5, height: 5, bottom: -5, zIndex: 10 },
    "75%": { width: 2, height: 2 },
    "100%": { width: 5, height: 5, bottom: "100%" },
  },
  "@keyframes helix-bar": {
    "0%": { height: 15 },
    "25%": { height: 8 },
    "50%": { height: 15 },
    "75%": { height: 8 },
    "100%": { height: 15 },
  },
  loader: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    width: 120,
    height: 20,
    "& .dot": {
      position: "relative",
      flex: 1,
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      margin: "0 5px",
      height: 20,
      width: 2,
      "&:first-of-type": { marginLeft: 0 },
      "&:last-of-type": { marginRight: 0 },
      "&::before": {
        content: "' '",
        position: "absolute",
        bottom: -5,
        display: "block",
        width: 4,
        height: 4,
        background: "#fff",
        borderRadius: 4,
        boxShadow: "1px 1px 4px rgba(0,0,0,.15)",
        animation: "$helix 1.25s ease-in-out infinite",
        zIndex: 100
      },
      "&::after": {
        content: "' '",
        position: "absolute",
        display: "block",
        width: 4,
        height: 4,
        background: colour.white,
        borderRadius: 4,
        boxShadow: "1px 1px 4px rgba(0,0,0,.15)",
        bottom: "100%",
        animation: "$helix-reversed 1.25s ease-in-out infinite",
        zIndex: 100
      },
      "& i": {
        position: "absolute",
        zIndex: 25,
        alignSelf: "center",
        width: 2,
        height: 10,
        background: colour.white,
        animation: "$helix-bar 1.25s ease-in-out infinite",
      },
      "&.dot2::before, &.dot2::after, &.dot2 i": {
        animationDelay: "0.05s",
      },
      "&.dot3::before, &.dot3::after, &.dot3 i": { animationDelay: "0.10s" },

      "&.dot4::before, &.dot4::after, &.dot4 i": { animationDelay: "0.15s" },

      "&.dot5::before, &.dot5::after, &.dot5 i": { animationDelay: "0.20s" },

      "&.dot6::before, &.dot6::after, &.dot6 i": { animationDelay: "0.25s" },

      "&.dot7::before, &.dot7::after, &.dot7 i": { animationDelay: "0.30s" },

      "&.dot8::before, &.dot8::after, &.dot8 i": { animationDelay: "0.35s" },

      "&.dot9::before, &.dot9::after, &.dot9 i": { animationDelay: "0.40s" },
    },
  },
});
