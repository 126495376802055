import { useStyles } from "./styles";
import AccordionItem from "./AccordionItem";
import Drug from "../globals/interfaces/drugInterface";
import { useScreenClass } from "react-grid-system";
import Reference from "../globals/interfaces/reference";
import {reduxState} from "../globals/interfaces/commonInterfaces";
import { useSelector  } from "react-redux";
interface Props {
  colour: string;
  drugs: Array<Drug>;
  references: Array<Reference>;
}

const AccordionList = (props: Props) => {
  const { colour, drugs, references } = props;
  const activeDrug = useSelector((state:reduxState) => state.appData.activeDrug);
  const theme = useScreenClass();
  const styleProps = { theme: theme, compColour: colour };
  const classes = useStyles(styleProps);

  const expandDrugAccordion = (drugName:string) => {
    if(drugName === activeDrug){
      return true;
    } else {
      return false
    }
  }
  return (
    <div data-testid="accordion-list">
      {drugs.length > 0 ? (
        drugs.map((drug, i) => (
          <AccordionItem colour={colour} drug={drug} key={i} isExpand={expandDrugAccordion(drug.name)} references={references} />
        ))
      ) : (
        <div className={classes.container}>
          <div className={classes.content} style={{cursor: "default"}}>
            <strong>None</strong>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccordionList;
