import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
  pageTitle: {
    fontSize: 16,
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    lineHeight: "24px",
    display: "inline-block",
    borderBottom: "2px solid " + colour.blue,
    paddingBottom: 10,
    paddingRight: 12,
    margin: "12px 0 24px 0",
  },
  container: {
    marginTop: 20,
    fontFamily: "'AvenirLTStd-Roman', sans-serif",
    fontSize: 13,
    lineHeight: "24px",
  },
  heading: {
    fontSize: 21,
    lineHeight: "27px",
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    fontWeight: "normal",
    marginBottom: 20,
    marginTop: 0,
  },
  description: {
    fontSize: 13,
    lineHeight: "16px",
    fontFamily: "'AvenirLTStd-Roman', sans-serif",
    marginBottom: 16,
  },
  quadrantWpr: {
    marginBottom: 24,
    marginTop: 16,
  },
  listItem: {
    margin: "0 0 20px",
    padding: "0 0 0 18px",
    lineHeight: "24px",
    fontFamily: "'AvenirLTStd-Book', sans-serif",
  },
  bottomGap: {
    marginBottom: 36,
  },
  bottomGapXs: {
    marginBottom: 12,
  },
  title: {
    fontSize: 21,
    lineHeight: "27px",
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    fontWeight: "normal",
    marginBottom: 16,
  },
  tabsList: {
    display: "flex",
    listStyle: "none",
    margin: "12px 0",
    padding: 0,
    "& li": {
      padding: "4px 10px",
      marginRight: 50,
      fontSize: "1rem",
      lineHeight: "1.3rem",
      position: "relative",
      cursor: "pointer",
      fontFamily: "'AvenirLTStd-Roman', sans-serif",
      "&:after": {
        position: "absolute",
        height: 2,
        width: "106%",
        display: "inline-block",
        content: "''",
        left: 0,
        bottom: "-3px",
      },
      "&.react-tabs__tab--selected": {
        fontFamily: "'AvenirLTStd-Heavy', sans-serif",
        "&:after": {
          background: "#30B5E8",
        },
      },
    },
  },
  descriptionTextWrapper: {
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
  },
  lineWrapper: {
    fontFamily: "'AvenirLTStd-Book', sans-serif",
    padding: "0.3rem 0",
  },
  pageWrapper: {
    maxWidth: "44rem",
  },
  categorySectionWrapper: {
    paddingBottom: "2rem",
  },
  categoryWrapper: {
    padding: "0.5rem 0",
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
  },
  guidelinesWrapper: {
    padding: "1rem 0",
  },
});
