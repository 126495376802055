import { createUseStyles } from "react-jss";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
  notificationsContainer: {
    overflow: "overlay",
    scrollBehavior: "smooth",
    height: "100vh",
    paddingBottom: 140,
  },
  notificationsContent: {
    margin: "20px",
    "& hr": {
      height: 3,
        backgroundColor: colour.grey11,
        border: "none",
    }
  },
  header: {
    marginBottom: 40
  }
});
