import { useStyles } from "./styles";
import { ReportBranding } from "../globals/interfaces/reportBranding";
import ImageSrc from "../_atoms/ImageSrc";
import myDnaLogo from '../../assets/logos/myDNA-logo.png';

const EvaluateCobranding = (cobranding: ReportBranding) => {
    
    const classes = useStyles();

    const { base64ImageEncode, imageType, title} = cobranding;

    if (base64ImageEncode && imageType) {
        var imageSource = `data:${imageType};base64,${base64ImageEncode}`;
        return (
            <div className={classes.partnerLogoWrapper}>
                <ImageSrc imageSrc={imageSource} imageAlt={title}/>
            </div>
        );
    }

    if (title) {
        return <div className={classes.sidebarHeaderText}>{title}</div>
    }
    
    return (
        <div className={classes.myDnaLogoWrapper}>
            <ImageSrc imageSrc={myDnaLogo} imageAlt="myDnaLogo"/>
        </div>
    );
    
};

export default EvaluateCobranding;
