import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";
import logoutIcon from "../../../assets/icons/logout-btn.svg";

export const useStyles = createUseStyles({
  header: {
    height: "10vh",
    minHeight: "50px",
  },
  headerContainer: {
    height: "100%",
    padding: "0 20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  userName: {
    fontSize: 12,
    position: "relative",
    marginTop: 0,
    marginBottom: 16,
    whiteSpace: "nowrap",
  },
  logoutBtn: {
    background: `url(${logoutIcon}) no-repeat 12px center ` + colour.blue,
    border: "none",
    cursor: "pointer",
    borderRadius: 20,
    padding: "8px 12px 8px 30px",
    color: colour.white,
    fontSize: 12,
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
  },
  errorNotification: {
    background: colour.red,
    color: colour.white,
    position: "absolute",
    width: "100%",
    top: 0,
    left: 0,
    padding: "2px 10px",
    fontSize: 12,
  },
  closeError: {
    cursor: "pointer",
    float: "right",
    paddingRight: 4,
    transform: "scaleX(1.2)",
    background: "none",
    padding: 0,
    border: 0,
    color: colour.white,
  },
  user: {},

  userDetails: {
    position: "absolute",
    right: 9,
    top: 62,
    background: colour.grey7,
    padding: "16px 12px",
    fontSize: 10,
    borderRadius: 5,
    minWidth: 180,
    textAlign: "center",
    fontFamily: "'AvenirLTStd-Roman', sans-serif",
    zIndex: 1,
    "&:before": {
      right: 25,
      width: 0,
      top: -20,
      height: 0,
      content: "' '",
      position: "absolute",
      border: "20px solid transparent",
      borderBottomColor: colour.grey7,
      borderTopWidth: 0,
    },
  },
  toggleMenuButton: {
    background: "none",
    border: 0,
    padding: 10,
    marginRight: 25,
    width: 90,
    cursor: "pointer",
    "& span": {
      float: "left",
      width: "100%",
      height: 3,
      backgroundColor: "#333",
      margin: "4px 0",
      transition: "0.4s",
    },
  },
  imageDiv: {
    marginRight: 20,
  }
});
