import { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { useScreenClass } from "react-grid-system";
import Modal from "react-modal";
import HelpTooltip from "../HelpTooltip";
import { getHelpText } from "../globals/Utils";
import Drug from "../globals/interfaces/drugInterface";
import {ModelPosition} from "../globals/interfaces/commonInterfaces";
import ReferenceTooltip from "../../components/AccordionList/RefereneTooltip";
import Reference from "../globals/interfaces/reference";

const customStyles = {
  overlay: {
    zIndex: 1,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    zIndex: 9999,
    height: "auto",
    padding: 0,
    overflow: "hidden",
    border: "none",
    background: "#e5e5e5",
    transform: "translate(0,0)",
    maxWidth: "100%"
  },
};

interface Props {
  title: string;
  colour: string;
  drugs: Array<Drug>;
  closeModal: Function;
  modalIsOpen: boolean;
  position: ModelPosition;
  references: Reference[];
  wrapperRef: React.RefObject<HTMLInputElement>;
}

if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

const TableData = (props: { drugs: Array<Drug>; colour: string; position: ModelPosition; references: Reference[]; }) => {
  const { drugs, colour, position, references } = props;
  const theme = useScreenClass();
  const styleProps = { theme: theme, colour: colour, position: position };
  const classes = useStyles(styleProps);  

  return (
    <>
      {drugs.length > 0 ? (
        drugs.map((drug: Drug, i) => (
          <tr key={i} className={ i===0 ? classes.firstChild : (i === (drugs.length-1) ? classes.lastChild : classes.tableRow)}>
            <th  style={{width: "15%"}}>{drug.name}</th>
            <td style={{width: "30%"}}>
              {drug.phenotypes &&
                drug.phenotypes.map(
                  (
                    phenotype: { gene: string; shortComment: string },
                    index
                  ) => (
                    <div key={index}>
                      <strong>
                        {phenotype.gene} - {phenotype.shortComment}
                      </strong>
                    </div>
                  )
                )}
              <ReferenceTooltip references={references} content={drug.interpretation || ""} />
            </td>
            <td className={classes.description}>
              <ReferenceTooltip references={references} content={drug.recommendation || ""} />
            </td>
          </tr>
        ))
      ) : (
        <div>
          <div>
            <strong>None</strong>
          </div>
        </div>
      )}
    </>
  );
};

const Table = (props: { colour: string; drugs: Array<Drug>; position: ModelPosition; references: Reference[] }) => {
  const { colour, drugs, position, references } = props;
  const theme = useScreenClass();
  const styleProps = { theme: theme, colour: colour, position: position };
  const classes = useStyles(styleProps);

  const [tableHeight, setTableHeight] = useState("300px");

  useEffect(()=>{
    if(position){
      let topPosition = Number(position.top) > 50 ? Number(position.top) : 60 ;
      let pageHeight = (window.innerHeight - topPosition) - 130;
      setTableHeight(pageHeight+"px");
    }

  }, [position])
  return (
    <table className={classes.quadrantTable}>
      <thead>
        <tr>
          <th style={{width: "15%"}}>Medication</th>
          <th style={{width: "30%"}}>Interpretation</th>
          <th>Recommendation</th>
        </tr>
      </thead>
      <tbody style={{maxHeight: tableHeight}}>
        <TableData drugs={drugs} colour={colour} position={position} references={references} />
      </tbody>
    </table>
  );
};

const ExpandedView = (props: Props) => {
  const { title, colour, drugs, closeModal, modalIsOpen, position, references, wrapperRef } = props;
  const theme = useScreenClass();
  const styleProps = { theme: theme, colour: colour, position: position };
  const classes = useStyles(styleProps);
  const [modelStyles, setModelStyles] = useState(customStyles);
  

  useEffect(()=>{
    if(position){
      let newStyles = customStyles;
      if(wrapperRef.current){
        let topPosition = Number(position.top) > 50 ? Number(position.top) : 60 ;
        
        newStyles.content.transform = "translate(" + position.left + "px, "  + (topPosition - 12) + "px)";
        newStyles.content.maxWidth = wrapperRef.current.clientWidth + "px"; 
        setModelStyles(newStyles)
      }
    }

  }, [position, wrapperRef]);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        className={classes.modelwpr}
        style={modelStyles}
        contentLabel="Example Modal"
        ariaHideApp={process.env.NODE_ENV !== "test"}
      >
        <div className={classes.ModalTitle}>
          <h4>{title}</h4>
          <HelpTooltip tooltipText={getHelpText(title)} openToolTip={false} tooltipPosition="left" />
          <span onClick={() => closeModal()} className={classes.closeLink}>
            Close All
          </span>
        </div>
        <Table colour={colour} drugs={drugs} position={position} references={references} />
      </Modal>
    </>
  );
};

export default ExpandedView;
