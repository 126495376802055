import PropTypes from 'prop-types'
import {useStyles} from './styles'

const ImageSrc = ({styles, imageSrc, imageAlt }) => {
    const classes = useStyles();
    return (<img style={styles} src={imageSrc} alt={imageAlt} className={classes.image} data-testid="image" />)
}
ImageSrc.propTypes = {
    styles: PropTypes.objectOf(PropTypes.any),
    imageSrc: PropTypes.string,
    imageAlt: PropTypes.string
}

export default ImageSrc