import { createUseStyles } from "react-jss";
import leftArrowIcon from "../../assets/icons/left-arrow.svg";

export const useStyles = createUseStyles({
  hideSideBar: {
    visibility: "hidden",
  },
  showSideBar: {
    visibility: "visible",
  },
  sideBarStyle: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    overflow: "hidden",
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    padding: 15,
  },
  closeMenu: {
    position: "absolute",
    top: 50,
    right: 20,
    background: `url(${leftArrowIcon}) no-repeat center center`,
    border: "none",
    width: 15,
    height: 30,
    cursor: "pointer",
  },
  sidebarInfoBox: {
    marginBottom: "1rem",
  },
  sidebarHeaderWrapper: {
    margin: "1rem 5px 0 5px"
  },
  sidebarHeaderText: {
    fontSize: "23px",
    lineHeight: "28px",
    fontFamily: "AvenirLTStd-Heavy",
    color: "white",
  },
  myDnaLogoWrapper: {
    width: "100px",
    height: "20px",
  },
  partnerLogoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxHeight: "93px",
    maxWidth: "186px",
    position: 'relative',
    overflow: 'hidden'
  }
});
