import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./styles";
import SimpleContainer from "../../components/SimpleContainer";
import { localStorageKey } from "../../constants/Constants";

interface Props {
}

const SignOut = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  
  useEffect(() => {
    localStorage.removeItem(localStorageKey.reportId);
  }, [dispatch, navigate]);

  return (
    <SimpleContainer>
      <p className={classes.message}>
        You successfully closed the report.
      </p>
    </SimpleContainer>
  );
};

export default SignOut;

