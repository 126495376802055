import { createUseStyles } from "react-jss";
import { colour } from "../../../constants/Colours";

export const useStyles = createUseStyles({
  filterBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    width: 157,
    height: 34,
    border: "1.5px solid #081F2D",
    borderRadius: "4px",
    lineHeight: "215%",
    fontSize: 14,
    cursor: "pointer",
  },
  filterBoxHover: {
    "&:hover": {
      backgroundColor: colour.white1,
    },
  },
  filterBoxActive: {
    backgroundColor: colour.grey1,
  },
  filterBoxText: {
    margin: 0,
    fontFamily: "'AvenirLTStd-Book', sans-serif",
    userSelect: "none",
  },
  filterBoxTextValue: {
    paddingLeft: 10,
    fontFamily: "'AvenirLTStd-Heavy', sans-serif",
    marginRight: 8,
  },
  filterBoxArrowClose: {
    // marginLeft: 4,
  },
  filterBoxArrowOpen: {
    // marginLeft: 4,
    transform: "rotate(180deg)",
  },
  filterMenu: {
    zIndex: 999,
    position: "absolute",
    width: 250,
    background: colour.white,
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 4,
  },
  filterMenuItem: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 4,
    margin: 2,
    padding: "0 16px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: colour.white1,
    },
  },
  filterMenuItemActive: {
    backgroundColor: colour.grey1,
    '& span':{
      fontFamily: "'AvenirLTStd-Roman', sans-serif",
    }
  },
  filterTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "10px 0",
  },
  filterTitleText: {
    fontFamily: "'AvenirLTStd-Book', sans-serif",
    fontSize: 14,
    paddingLeft: 10,
    paddingTop: 2,
    lineHeight: 0,
  },
});
