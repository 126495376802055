import { createUseStyles } from "react-jss";
import questionIcon from "../../assets/icons/question-circle.svg";
import { colour } from "../../constants/Colours";

export const useStyles = createUseStyles({
  helpButtonWpr: {
    clear: "both",
    zIndex: 5,
    bottom: 38
  },
  helpButton: {
    display: "inline-block",
    background: `url(${questionIcon}) no-repeat 16px center ` + colour.blue,
    cursor: "pointer",
    padding: "8px 16px 8px 38px",
    borderRadius: 55,
    border: "none",
    color: colour.white,
    fontFamily: '"AvenirLTStd-Black", sans-serif',
    fontSize: 18,
  },
  helpTooltip: {
    background: colour.white,
    position: "absolute",
    marginLeft: 120,
    bottom: 0,
    borderRadius: 5,
    width: 360,
    padding: "10px 10px 10px 20px",
    boxShadow: "0 0 8px " + colour.grey5
  },
  title: {
    fontSize: 18,
    margin: 0,
    fontFamily: '"AvenirLTStd-Heavy", sans-serif',
    fontWeight: "normal"
  },
  email: {
    color: colour.blue,
    fontFamily: '"AvenirLTStd-Heavy", sans-serif',
  },
  description: {
    fontSize: 14,
    color: colour.fadedGrey,
    fontFamily: '"AvenirLTStd-Roman", sans-serif',
  },
  triangle: {
    width: 0,
    height: 0,
    borderStyle: "solid",
    borderWidth: "15px 18px 15px 0",
    borderColor: "transparent #ffffff transparent transparent",
    position: "absolute",
    left: -16,
    top: 38
  },
});
